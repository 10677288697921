import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { SecurityGuestCheckin } from './component/SecurityGuestCheckin';
import { SpecialRequestCheckin } from './security-special-request/SpecialRequestCheckin';
import { getEstateName } from './service/securityGuestValidationApi';

export const SecurityCheckin = ({ setShowSideBar }) => {
  const userId = useSelector((state) => state.auth.user);
  const [estateName, setEstateName] = useState('Our Estate');
  // const [loading, setLoading] = useState(false);

  useEffect(() => {
    setShowSideBar();
  }, [setShowSideBar]);

  useEffect(() => {
    getEstateName(setEstateName, userId);
  }, [userId]);

  return (
    <Tabs variant='unstyled'>
      <TabList display='flex' justifyContent='center'>
        <Tab {...selectedTabStyles}>Guest Check in</Tab>
        <Tab {...selectedTabStyles}>Special Request Checkin</Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <SecurityGuestCheckin estateName={estateName} />
        </TabPanel>
        <TabPanel>
          <SpecialRequestCheckin estateName={estateName} />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

const selectedTabStyles = {
  fontWeight: 600,
  fontSize: '16px',
  color: 'gray.700',
  mx: '2rem',
  _selected: { color: '#F09091', borderBottom: '3px solid #F09091 ' },
  _focus: { border: 'none' },
};
