import http, { AUTH_ROUTES } from '../../../../services/api';
import { errorNotifier } from '../../../../components/NotificationHandler';

//SECURITY GUEST VALIDATION
export const guestValidation = async (payload, setLoading, setConfirmationMsg, setSuccess) => {
  try {
    await http.post(AUTH_ROUTES.SECURITY_GUEST_VALIDATION, payload);

    setSuccess(true);
  } catch (e) {
    setConfirmationMsg(e.response?.data?.result?.message);
  } finally {
    setLoading(false);
  }
};
//;;;;;
//GET GUEST CODE THAT MATCHES THE INPUTED GUEST CODE
export const getGuestCode = async (setLoading, userId, guestCode, setInvitedGuest, setConfirm) => {
  setLoading(true);
  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.GET_SECURITY_GUEST_CODE(userId, guestCode));

    setInvitedGuest(data);
    setConfirm(true);
  } catch (e) {
    errorNotifier('Guest Code is Invalid');
  } finally {
    setLoading(false);
  }
};

export const getEstateName = async (setEstateName, userId) => {
  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.GET_ESTATE_INFO(userId));
    setEstateName(data?.estateName);
  } catch (e) {}
};
