import { Box, Flex } from '@chakra-ui/react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import FullPageLoader from '../../../components/full-page-loader';
import { getAllGuestList, getGuestList } from './service/guestListApi';
import { DATA_ROWS } from '../../../app/constants';
import { CustomTable } from '../../../components/customized table/CustomTable';
import { guestListTableColumn } from './component/guestListTableColumn';
// import { searchGuest } from "./service/searchGuests";

const GuestList = () => {
  const userId = useSelector((state) => state.auth.user);
  const [loading, setLoading] = useState(true);
  const [, setGuestList] = useState([]);
  const [allGuestList, setAllGuestList] = useState([]);

  useEffect(() => {
    getGuestList(setLoading, userId, 0, DATA_ROWS.LIMIT, setGuestList);
    getAllGuestList(setLoading, userId, setAllGuestList);
  }, [userId]);

  return loading ? (
    <FullPageLoader />
  ) : (
    <Box
      padding='20px 30px'
      paddingRight='60px'
      bg='#fff'
      borderRadius='5px'
      margin='20px'
      marginTop='50px'
    >
      <Flex justifyContent='flex-end'>
        {/* <Box>
          <Input width="300px" borderRadius={"25px"} bg="#fff" value={searchTerm} onChange={(e)=>{ setSearchTerm(e.target.value); handleSearch(e);}} />
        </Box> */}
        {/* <GuestListModal
          data={allGuestList}
          setFilter={(data) => setFilteredData(data)}
        /> */}
      </Flex>
      <CustomTable
        COLUMNS={guestListTableColumn}
        DATA={allGuestList}
        innerSearch
      />
    </Box>
  );
};

export default GuestList;
