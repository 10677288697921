export const EstateDueHeader = ['House address', 'Phone number', 'Name', 'Email', 'Unit no', 'Amount Paid', 'Payment date', 'Next Payment Month'];

export const vehicularPaymentsHeader = [
  'Type of vehicle',
  'Contact number',
  'Name of contact person',
  'Vehicle place number',
  'Amount Paid',
  'Payment date',
  'Action',
];

export const ContractorArtisanPaymentHeader = [
  'Type of Contractor/ Artisan',
  'Contact number',
  'Name of contact person',
  'Total number of people',
  'Amount Paid/days',
  'Payment date',
  'Action',
];

export const estatePaymentsTab = ['Estate Due', 'Vehicular payment', 'Contractor payment'];

export const selectedTabStyles = {
  fontWeight: 600,
  color: 'gray.700',
  pb: '2',
  _selected: { color: '#F09091', borderBottom: '3px solid #F09091 ' },
  _focus: { border: 'none' },
};
