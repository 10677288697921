import { successNotifier, errorNotifier } from '../../../../components/NotificationHandler';
import http, { AUTH_ROUTES } from '../../../../services/api';

export const getVenues = async (userId, setLoading, setVenues) => {
  setLoading(true);
  try {
    const {
      data: { result },
    } = await http.get(AUTH_ROUTES.MANAGER_GET_VENUES(userId));

    setVenues(result?.data?.venues);
  } catch (e) {
    setLoading(false);
  } finally {
    setLoading(false);
  }
};

export const deleteVenue = async (userId, venueId, setLoading, setRefresh) => {
  setLoading(true);
  try {
    const {
      data: { result },
    } = await http.delete(AUTH_ROUTES.MANAGER_DELETE_VENUE(userId, venueId));

    successNotifier(result?.message);
    setRefresh();
  } catch (e) {
    setLoading(false);
    errorNotifier({});
  } finally {
    setLoading(false);
  }
};

export const createVenue = async (payload, setLoading, setRefresh) => {
  setLoading(true);
  try {
    const {
      data: { result },
    } = await http.post(AUTH_ROUTES.MANAGER_CREATE_VENUE, payload);

    successNotifier(result?.message);
    setRefresh();
  } catch (e) {
    setLoading(false);
    errorNotifier();
  } finally {
    setLoading(false);
  }
};
