import http, { AUTH_ROUTES } from '../../../../services/api';
import { DATA_ROWS } from '../../../../app/constants';

export const getHouses = async (userId, setHouses, setLoading, skip = 0, limit = DATA_ROWS.LIMIT) => {
  const page = skip * limit;
  try {
    const {
      data: {
        result: {
          data: { houses },
        },
      },
    } = await http.get(AUTH_ROUTES.GET_HOUSE(userId, page, limit));
    setHouses(houses);
    setLoading(false);
  } catch (e) {
    // errorNotifier(e?.response?.data.result);
    setHouses([]);
    setLoading(false);
  }
};

export const getAllHouses = async (userId, setHouses, setLoading) => {
  try {
    const {
      data: {
        result: {
          data: { houses },
        },
      },
    } = await http.get(AUTH_ROUTES.GET_ALL_HOUSE(userId));
    setHouses(houses);

    setLoading(false);
  } catch (e) {
    // errorNotifier(e?.response?.data.result);
    setHouses([]);
    setLoading(false);
  } finally {
    setLoading(false);
  }
};
