import React, { useEffect, useState } from "react";
import { Flex, Text, Button, Input } from "@chakra-ui/react";
import UserValidationModal from "./userValidationModal";
import { getPhoneNumber } from "../service/index";
import { useSelector } from "react-redux";

function PhoneNumber() {
  const userId = useSelector((state) => state.auth.user);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [tenantData, setTenantData] = useState(null);
  const [shouldOpen, setShouldOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!tenantData) return;
    setShouldOpen(true);
  }, [tenantData]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const payload = {
      userId,
      phoneNumber,
    };
    getPhoneNumber(payload, setTenantData, setLoading);
  };

  const handleClose = () => {
    setShouldOpen(false);
  };

  return (
    <Flex flexDirection="column" marginTop="150px">
      <Text color="#949494" fontSize="1em" mb={10}>
        Please enter Resident phone number to access this estate gate
      </Text>
      <Flex alignItems="center" flexDirection="column">
        <Input
          _focus={{ outline: "none" }}
          fontSize=".9em"
          color="#614285"
          background="#F2F2F2"
          boxShadow="0px 3px 6px #00000029"
          placeholder="Enter Phone number"
          size="lg"
          name="phone-number"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
        />

        <Button
          _focus={{ outline: "none", color: "#fff" }}
          _hover={{
            outline: "none",
            background: "#A08CA8",
            color: "#fff",
            border: "1px solid #A08CA8",
          }}
          color="#fff"
          bg="#A08CA8"
          cursor="pointer"
          padding="20px 60px"
          marginRight="20px"
          size="lg"
          mt={10}
          p="20px"
          alignSelf="center"
          isLoading={loading}
          onClick={handleSubmit}
        >
          Submit
        </Button>
        <UserValidationModal
          tenantData={tenantData}
          isOpen={shouldOpen}
          handleClose={handleClose}
        />
      </Flex>
    </Flex>
  );
}

export default PhoneNumber;
