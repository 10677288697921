import React, { useEffect, useState } from "react";
import { getAllHouses, getHouses } from "./service/getHouse";
import { getStreets } from "./service/getStreets";
import { useSelector } from "react-redux";
import FullPageLoader from "../../../components/full-page-loader";
import { CustomTable } from "../../../components/customized table/CustomTable";
import { Flex, HStack, Stack } from "@chakra-ui/react";
import { EditHouse } from "./components/EditHouse";
import { DeleteHouse } from "./components/DeleteHouse";
import { Modal } from "../../../components/modal";
import CreateHouseBtn from "../../../components/CreateHouseBtn";
import CreateHouseForm from "../../../components/CreateHouseForm";

const Index = () => {
  const userId = useSelector((state) => state.auth.user);
  const [houses, setHouses] = useState([]);
  const [streets, setStreets] = useState([]);
  const [refresh, setRefresh] = useState({});
  const [allHouse, setAllHouse] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getHouses(userId, setHouses, setLoading);
    getAllHouses(userId, setAllHouse, setLoading);
    getStreets(userId, setStreets);
  }, [userId, refresh]);
  const houseTableHead = [
    {
      Header: "S/N",
      accessor: "id",
      Cell: ({ row }) => Number(row.id) + 1,
    },
    {
      Header: "Street",
      accessor: "streetId.streetName",
    },
    {
      Header: "Phone No",
      accessor: "phoneNumber",
    },
    {
      Header: "Designation",
      accessor: "designation",
    },
    {
      Header: "House Address",
      accessor: "estate[0].houseNumber",
    },
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Action",
      accessor: "_id",
      Cell: ({ row }) => (
        <HStack gap="1.2rem">
          <EditHouse
            initialData={row.original}
            setRefresh={() => setRefresh({})}
          />

          <DeleteHouse setRefresh={() => setRefresh({})} house={row.original} />
        </HStack>
      ),
    },
  ];

  return loading ? (
    <FullPageLoader />
  ) : (
    <Stack my="10" p="5" bg="#fff">
      <Flex justify="end">
        <Modal
          icon={<CreateHouseBtn text="Create House" />}
          title="Create House"
        >
          <CreateHouseForm
            setRefresh={() => setRefresh({})}
            houses={houses}
            streets={streets}
          />
        </Modal>
      </Flex>
      <CustomTable
        innerSearch
        COLUMNS={houseTableHead}
        DATA={allHouse}
      ></CustomTable>
    </Stack>
  );
};

export default Index;
