import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
} from "@chakra-ui/react";
import StreetCard from "./components/streetCard";
import { getStreets } from "./services/street";
import FullPageLoader from "../../../components/full-page-loader";
import { AiOutlineSearch } from "react-icons/ai";
import "./index.css";
import CustomModal from "../../../components/customModal";
import CreateStreetForm from "../../../components/createStreetForm";
import { CurvyBox } from "./../../../components/CurvyBox";

function Street() {
  const userId = useSelector((state) => state.auth.user);
  const [loading, setLoading] = useState(false);
  const [street, setStreet] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [filteredStreets, setFilteredStreets] = useState([]);

  const handleSearchStreet = (e) => {
    if (e.charCode === 13) {
      const filteredStreet = street?.streets?.filter(
        ({ streetName }) =>
          streetName.toLowerCase().indexOf(searchInput.toLowerCase()) !== -1
      );
      setFilteredStreets(filteredStreet);
    }
  };

  useEffect(() => {
    setLoading(true);
    getStreets(userId)
      .then((result) => {
        const street = result?.data?.result?.data;
        setStreet(street);
        setFilteredStreets(street?.streets);
      })
      .finally(() => setLoading(false));
  }, [refresh, userId]);

  return (
    <Box width="100%" mt="20px" px={4} py={8}>
      <Flex justifyContent="flex-end" alignItems="center" maxW="100%" mb={8}>
        <InputGroup width={["100%", "100%", "25%", "25%"]}>
          <InputLeftElement
            pointerEvents="none"
            children={<AiOutlineSearch color="gray.300" />}
          />
          <Input
            onChange={(e) => setSearchInput(e.target.value)}
            placeholder="Enter search"
            _placeholder={{
              color: "grey",
              fontSize: ".8rem",
            }}
            onKeyPress={handleSearchStreet}
            bg="white"
            color="black"
            outline="none"
            borderRadius="full"
            type="text"
            value={searchInput}
            _focus={{ outline: "none" }}
          />
        </InputGroup>
      </Flex>
      <Flex justifyContent="flex-end" mb={2} mr={4}>
        <CustomModal
          title="Create Street"
          buttonText="Add Street"
          bg="#614285"
          mb={2}
        >
          <CreateStreetForm
            streetId={street?._id}
            onRefresh={() => setRefresh(!refresh)}
          />
        </CustomModal>
      </Flex>
      <Box>
        {loading ? (
          <FullPageLoader />
        ) : (
          <CurvyBox bg="#F2F2F2">
            {filteredStreets?.length > 0 ? (
              filteredStreets?.map((street) => (
                <StreetCard
                  key={street._id}
                  street={street}
                  onRefresh={() => setRefresh(!refresh)}
                />
              ))
            ) : (
              <Text textAlign="center">No streets available</Text>
            )}
          </CurvyBox>
        )}
      </Box>
    </Box>
  );
}

export default Street;
