import { Flex, Stack, Table, Tbody, Td, Th, Thead, Tr, Button, HStack, Grid, Text, Input, Box } from '@chakra-ui/react';
import { useEffect, useMemo } from 'react';
import { useTable, useGlobalFilter, useFilters, useSortBy, usePagination } from 'react-table';
import { GlobalFilter } from './GlobalFilter';
import { FaLongArrowAltDown, FaLongArrowAltUp } from 'react-icons/fa';
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from 'react-icons/md';
import { BiCommentError, BiFirstPage, BiLastPage } from 'react-icons/bi';

export const CustomTable = ({ COLUMNS, DATA, innerSearch, children }) => {
  const columns = useMemo(() => COLUMNS, [COLUMNS]);
  const data = useMemo(() => DATA, [DATA]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    // rows,
    page,
    nextPage,
    previousPage,
    pageOptions,
    setPageSize,
    canPreviousPage,
    canNextPage,
    gotoPage,
    pageCount,
    prepareRow,
    state,
    setGlobalFilter,
  } = useTable({ columns, data }, useFilters, useGlobalFilter, useSortBy, usePagination);

  const { globalFilter, pageIndex, pageSize } = state;

  useEffect(() => {
    // sets the fixed page size on page load
    setPageSize(100);
  }, [setPageSize]);

  const handleGotoPageInput = (e) => {
    let pageNumber = e.target.value ? Number(e.target.value) - 1 : 0;

    gotoPage(pageNumber);
    return;
  };

  return (
    <Stack w='100%'>
      {DATA?.length && innerSearch && (
        <Flex justify='space-between' py='5'>
          <Box>{children}</Box>
          <GlobalFilter pageSize={pageSize} setPageSize={setPageSize} filter={globalFilter} setFilter={setGlobalFilter} />
        </Flex>
      )}
      {DATA.length <= 0 ? (
        <Grid placeContent='center' h='60vh'>
          <BiCommentError fontSize='16rem' opacity='0.2' />
        </Grid>
      ) : (
        <>
          <Table variant='unstyled' {...getTableProps()}>
            <Thead>
              {headerGroups.map((headerGroup) => (
                <Tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <Th
                      px='2'
                      // w='180px'
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      <Flex fontSize={'12px'}>
                        {column.isSorted ? column.isSortedDesc ? <FaLongArrowAltDown /> : <FaLongArrowAltUp /> : ' '}
                        {column.render('Header')}
                      </Flex>
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody {...getTableBodyProps()} fontSize='12px'>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <Tr {...row.getRowProps()} transitionDuration='300ms' _hover={{ bg: 'rgba(97, 66, 133, 0.06)' }}>
                    {row.cells.map((cell) => (
                      <Td px='2' {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </Td>
                    ))}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
          <HStack pt='5' fontSize='12px' justify='flex-end'>
            <span>
              page{' '}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>{' '}
              pages
            </span>

            <Button {...paginationBtn} isDisabled={!canPreviousPage} onClick={() => gotoPage(0)}>
              <BiFirstPage color={canPreviousPage ? '#fff' : undefined} size={18} />
            </Button>

            <Button {...paginationBtn} isDisabled={!canPreviousPage} onClick={previousPage} color={canPreviousPage && '#614285'}>
              <MdKeyboardArrowLeft color={canPreviousPage ? '#fff' : undefined} size={18} />
            </Button>

            <HStack>
              <Text w='70px'>go to page:</Text>
              <Input defaultValue={1} type='number' onChange={handleGotoPageInput} w='40px' borderRadius='4px' size='xs' />
            </HStack>

            <Button {...paginationBtn} isDisabled={!canNextPage} onClick={nextPage}>
              <MdKeyboardArrowRight color={canNextPage ? '#fff' : undefined} size={18} />
            </Button>

            <Button {...paginationBtn} isDisabled={!canNextPage} onClick={() => gotoPage(pageCount - 1)} color={canNextPage && '#614285'}>
              <BiLastPage color={canNextPage ? '#fff' : undefined} size={18} />
            </Button>
          </HStack>
        </>
      )}
    </Stack>
  );
};

const paginationBtn = {
  _focus: { border: 'none' },
  _hover: { bg: '#acacac' },
  size: 'xs',
  bg: '#614285',
  px: 1,
};
