import { useState } from 'react';
import {
  Button,
  useDisclosure,
  Input,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
  Text,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { createAnnouncement } from '../service/announcement';
import { useEffect } from 'react';
import { infoNotifier } from '../../../../components/NotificationHandler';

export default function CreateAnnouncement({ setRefresh, setCurrentTab }) {
  const userId = useSelector((state) => state.auth.user);
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = useState(false);
  const [diasble, setDiasble] = useState(true);
  const maxWords = 30;
  const [wordsAmount, setWordsAmount] = useState(0);

  useEffect(() => {
    title && body ? setDiasble(false) : setDiasble(true);
  }, [title, body]);

  const handleCreateAnnouncement = () => {
    const payload = { title, body, userId };

    createAnnouncement(
      payload,
      setLoading,
      onClose,
      setRefresh,
      setCurrentTab
    ).then(() => {
      setRefresh();
      setCurrentTab(1);
    });
  };

  const handleAnnouncementInput = (e) => {
    // const spaces = e.target.value.match(/\S+/g);
    // if (spaces?.length <= maxWords) {
    //   const words = spaces ? spaces.length : 0;
    //   setWordsAmount(words);
    //   setBody(e.target.value);
    //   return;
    // }
    const length = e.target.value.trim().split(' ').length;

    if (length <= maxWords) {
      setWordsAmount(length);
      setBody(e.target.value);
      return;
    }

    infoNotifier('Maximum number of words reached');
  };

  return (
    <>
      <Button
        onClick={onOpen}
        bg='#614285'
        color='white'
        _hover={{ bg: '#614285' }}
      >
        Announcement
      </Button>
      <Modal size='md' isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent color='white'>
          <ModalHeader bg='#F09091' p={5}>
            Create Announcement
          </ModalHeader>
          <ModalBody>
            <FormControl isRequired>
              <FormLabel color='#343434' fontSize='0.9rem' mt={5}>
                Title
              </FormLabel>
              <Input
                bg='#F6F6F6'
                color='black'
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel color='#343434' fontSize='0.9rem' mt={5}>
                Note
              </FormLabel>
              <Textarea
                bg='#F6F6F6'
                color='black'
                value={body}
                onChange={handleAnnouncementInput}
              />
              <Text color={wordsAmount > maxWords - 5 ? 'red.300' : '#333'}>
                {wordsAmount} of {maxWords} words
              </Text>
            </FormControl>
          </ModalBody>

          <ModalFooter w='100%' mb={10} mt={3} textAlign='center'>
            <Button
              _hover={{ outline: '#614285' }}
              bg='#614285'
              w='100%'
              isDisabled={diasble}
              isLoading={loading}
              onClick={handleCreateAnnouncement}
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
