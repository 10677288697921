import {
  Box,
  Flex,
  HStack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { MdDeleteForever } from 'react-icons/md';
import { TbEdit } from 'react-icons/tb';
import { useSelector } from 'react-redux';
import { AddEventsModal } from '../../../components/AddEventsModal';
import CautionAlertDialog from '../../../components/CautionAlertDialog';
import { CustomTable } from '../../../components/customized table/CustomTable';
import CustomModal from '../../../components/customModal';
import FullPageLoader from '../../../components/full-page-loader';
import CreateAnnouncement from './components/CreateAnnouncement';
import EditAnnouncement from './components/EditAnnouncement';
import { EditNewsEvent } from './components/EditNewsEvent';
import { deleteAnnouncement, getAnnouncement } from './service/announcement';
import { deleteNewsEvent, getNewsEvents } from './service/newsEvents';

const NewsEvents = () => {
  const userId = useSelector((state) => state.auth.user);
  const [refresh, setRefresh] = useState({});
  const [loading, setLoading] = useState(true);
  const [newsEvents, setNewsEvents] = useState([]);
  const [Annoucement, setAnnoucement] = useState([]);
  const [currentTab, setCurrentTab] = useState(0);

  useEffect(() => {
    getAnnouncement(setLoading, userId, setAnnoucement);
    getNewsEvents(setLoading, userId, setNewsEvents);
  }, [userId, refresh]);

  const newsColumn = [
    {
      Header: 'S/N',
      accessor: 'id',
      Cell: ({ row }) => Number(row.id) + 1,
    },
    {
      Header: 'Title',
      accessor: 'title',
    },
    {
      Header: 'Message',
      accessor: 'content',
    },
    {
      Header: 'Date Created',
      accessor: 'createdAt',
      Cell: ({ value }) => <Text>{dayjs(value).format('DD MMMM YYYY')}</Text>,
    },
    {
      Header: 'Action',
      accessor: 'userId.houseId.estate[0].houseNumber',
      Cell: ({ row }) => (
        <Flex gap='2rem'>
          <CustomModal
            title='Edit News'
            icon={<TbEdit color='green' size={20} />}
          >
            <EditNewsEvent
              data={row.original}
              setRefresh={() => setRefresh({})}
            />
          </CustomModal>

          <CautionAlertDialog
            cautionTitle='Are you sure you want to delete this News?'
            icon={<MdDeleteForever color='crimson' size={20} />}
            onContinue={() =>
              deleteNewsEvent(userId, row.original._id).then(() =>
                setRefresh({})
              )
            }
          />
        </Flex>
      ),
    },
  ];

  const announcementColumns = [
    {
      Header: 'S/N',
      accessor: 'id',
      Cell: ({ row }) => Number(row.id) + 1,
    },
    {
      Header: 'Title',
      accessor: 'title',
    },
    {
      Header: 'Message',
      accessor: 'body',
    },
    {
      Header: 'Date Created',
      accessor: 'createdAt',
      Cell: ({ value }) => <Text>{dayjs(value).format('DD MMMM YYYY')}</Text>,
    },
    {
      Header: 'Action',
      accessor: 'userId.houseId.estate[0].houseNumber',
      Cell: ({ row }) => (
        <Flex gap='2rem'>
          <CustomModal
            title='Edit Announcement'
            icon={<TbEdit color='green' size={20} />}
          >
            <EditAnnouncement
              data={row.original}
              setRefresh={() => setRefresh({})}
            />
          </CustomModal>

          <CautionAlertDialog
            cautionTitle='Are you sure you want to delete this Announcment'
            icon={<MdDeleteForever color='crimson' size={20} />}
            onContinue={() =>
              deleteAnnouncement(userId, row.original._id).then(() =>
                setRefresh({})
              )
            }
          />
        </Flex>
      ),
    },
  ];

  return loading ? (
    <FullPageLoader />
  ) : (
    <Box width='100%' mt='20px' px={4} py={8}>
      <Box
        padding={['5px', '20px', '20px']}
        bg='#fff'
        borderRadius='5px'
        m='25px 5px'
      >
        <Tabs onChange={(t) => setCurrentTab(t)}>
          <TabList display='flex' justifyContent='space-between'>
            <HStack>
              <Tab {...selectedTabStyles}>News/Event</Tab>
              <Tab {...selectedTabStyles}>Announcement</Tab>
            </HStack>
            {currentTab ? (
              <CreateAnnouncement
                setCurrentTab={setCurrentTab}
                setRefresh={() => setRefresh({})}
              />
            ) : (
              <AddEventsModal setRefresh={() => setRefresh({})} />
            )}
          </TabList>
          <TabPanels>
            <TabPanel>
              <CustomTable COLUMNS={newsColumn} DATA={newsEvents} />
            </TabPanel>

            <TabPanel>
              <CustomTable COLUMNS={announcementColumns} DATA={Annoucement} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Box>
  );
};

export default NewsEvents;

const selectedTabStyles = {
  fontWeight: 600,
  fontSize: '16px',
  color: 'gray.700',
  mx: '2rem',
  _selected: { color: '#F09091', borderBottom: '3px solid #F09091 ' },
  _focus: { border: 'none' },
};
