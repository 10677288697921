import {
  errorNotifier,
  successNotifier,
} from "../../../../components/NotificationHandler";
import htttp, { AUTH_ROUTES } from "../../../../services/api";

export const editHouse = async (payload, onClose, setRefresh, setLoading) => {
  try {
    await htttp.put(
      AUTH_ROUTES.EDIT_HOUSE(payload.userId, payload._id),
      payload
    );
    setRefresh();
    onClose();
    successNotifier("Successfully Edited House Information");
  } catch (e) {
    errorNotifier(e.response);
  } finally {
    setLoading(false);
  }
};
