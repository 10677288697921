import { Box, Text } from '@chakra-ui/react';
import { BsCheckCircle } from 'react-icons/bs';
import { ImCancelCircle } from 'react-icons/im';

export const Success = ({ onclickBack }) => {
  return (
    <Box
      position='relative'
      display='flex'
      justifyContent='center'
      alignItems='center'
      flexDirection='column'
      textAlign='center'
    >
      <Text color='#614285' fontSize='21px' mb={18}>
        Successful, Access granted
      </Text>
      <BsCheckCircle
        color='#614285'
        size={90}
        onClick={() => onclickBack(true)}
      />
      <Box position='absolute' right='10px' top='-20px'>
        <ImCancelCircle
          onClick={() => onclickBack(true)}
          fontSize='1.5em'
          color='#4a4a4a'
          cursor='pointer'
        />
      </Box>
    </Box>
  );
};
