import { errorNotifier, successNotifier } from '../../../../components/NotificationHandler';
import http, { AUTH_ROUTES } from '../../../../services/api';

export const getSpecialRequests = async (userId, setLoading, setSpecialRequest) => {
  setLoading(true);

  try {
    const {
      data: { data },
    } = await http.get(AUTH_ROUTES.GET_SPECIAL_REQUESTS(userId));

    setSpecialRequest(data?.requests);
  } catch (e) {
    setLoading(false);
  } finally {
    setLoading(false);
  }
};

export const approveRequest = async (payload, setLoading) => {
  setLoading(true);
  try {
    const {
      data: { result },
    } = await http.put(AUTH_ROUTES.MANAGER_APPROVE_REQUEST(payload.userId, payload.requestId), payload);

    successNotifier(result.message);
  } catch (e) {
    errorNotifier('check you connection');
  } finally {
    setLoading(false);
  }
};

export const declineRequest = async (payload, setLoading) => {
  setLoading(true);
  try {
    const {
      data: { result },
    } = await http.put(AUTH_ROUTES.MANAGER_DECLINE_REQUEST(payload.userId, payload.requestId), payload);

    successNotifier(result.message);
  } catch (e) {
    errorNotifier();
  } finally {
    setLoading(false);
  }
};
