import { Flex, Text, Tooltip, Box } from "@chakra-ui/react";
import { FiEdit } from "react-icons/fi";
import { useSelector } from "react-redux";
import { BiTrash } from "react-icons/bi";
import { deleteStreet } from "../services/street";
import CustomModal from "../../../../components/customModal";
import EditStreetForm from "../../../../components/editStreetForm";
import CautionAlertDialog from "../../../../components/CautionAlertDialog";

const StreetCard = ({ street, onRefresh }) => {
  const userId = useSelector((state) => state.auth.user);
  const c = { color: "#fc8181" };
  const ICON = { color: "#fc8181", fontSize: "1.3em" };
  const { _id: streetId } = street;

  const handleDelete = (streetId) => {
    deleteStreet(userId, streetId).then(() => onRefresh());
  };

  return (
    <Flex bg="white" p={6} justifyContent="space-between" mb={2}>
      <Flex
        flexDirection="column"
        sx={{
          "@media screen and (max-width:600px)": {
            gap: ".5rem",
          },
          "& > div": {
            "@media screen and (max-width:600px)": {
              "flexDirection": "column",
            },
          },
        }}
      >
        <Flex>
          <Text minW="8rem" fontWeight="bold">
            Street Name:
          </Text>
          <Text>{street.streetName}</Text>
        </Flex>
        <Flex>
          <Text minW="8rem" fontWeight="bold">
            Street Details:
          </Text>
          <Text>{street.streetDescription}</Text>
        </Flex>
      </Flex>
      <Flex>
        <Tooltip label="Edit Street">
          <Box>
            <CustomModal title="Edit Street" icon={<FiEdit {...ICON} color="green" />}>
              <EditStreetForm streetId={street._id} onRefresh={onRefresh} />
            </CustomModal>
          </Box>
        </Tooltip>

        <Tooltip label="delete street">
          <Box ml={5}>
            <CautionAlertDialog
              btnTitle="Delete"
              mt="5px"
              icon={<BiTrash {...c} fontSize="1.3em" />}
              cautionTitle="Are you sure you want to delete?"
              onContinue={() => handleDelete(streetId)}
            />
          </Box>
        </Tooltip>
      </Flex>
    </Flex>
  );
};

export default StreetCard;
