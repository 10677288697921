import { errorNotifier, successNotifier } from '../../../../components/NotificationHandler';
import http, { AUTH_ROUTES } from '../../../../services/api';

export const getVehiclePayments = async (userId, setLoading, setVehicularPayments) => {
  setLoading(true);
  try {
    const {
      data: { data },
    } = await http.get(AUTH_ROUTES.MANAGER_GET_VEHICLE_PAYMENTS(userId));

    setVehicularPayments(data?.requests);
  } catch (e) {
    setLoading(false);
  } finally {
    setLoading(false);
  }
};

export const setSpecialRequestPrice = async (setLoading, payload) => {
  setLoading(true);
  try {
    await http.post(AUTH_ROUTES.MANAGER_SET_SPECIAL_REQUEST_PRICE, payload);

    successNotifier('successfully updated');
  } catch (error) {
    errorNotifier();
  } finally {
    setLoading(false);
  }
};

export const getSpecialRequestprices = async (setLoading, userId, setSprecialRequestPrice) => {
  setLoading(true);
  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.MANAGER_GET_SPECIAL_PRICES(userId));

    setSprecialRequestPrice(data);
  } catch (e) {
    setLoading(false);
  } finally {
    setLoading(false);
  }
};
