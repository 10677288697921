import http, { AUTH_ROUTES } from '../../../../services/api';
import { errorNotifier, successNotifier } from '../../../../components/NotificationHandler';

export const updateEmergency = async (payload) => {
  try {
    const { data } = await http.put(AUTH_ROUTES.MANAGER_UPDATE_EMERGENCY(payload.userId, payload._id), { status: 'SORTED' });

    successNotifier(data.result.message);
  } catch (e) {
    errorNotifier(e?.response?.meassge);
  }
};
