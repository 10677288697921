import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { EmergencyTab } from './components/Emergency';
import { IssueReports } from './components/IssueReports';

const Emergency = () => {
  return (
    <Box mt='50px'>
      <Box padding={['5px', '20px', '20px']} bg='#fff' borderRadius='5px' m='25px 5px'>
        <Tabs>
          <TabList>
            <Tab {...selectedTabStyles}>Emergencies</Tab>
            <Tab {...selectedTabStyles}>Issue Reports</Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <EmergencyTab />
            </TabPanel>

            <TabPanel>
              <IssueReports />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Box>
  );
};

export default Emergency;

const selectedTabStyles = {
  fontWeight: 600,
  fontSize: '16px',
  color: 'gray.700',
  mx: '2rem',
  _selected: { color: '#F09091', borderBottom: '3px solid #F09091 ' },
  _focus: { border: 'none' },
};
