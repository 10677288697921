import { Box, Button, FormControl, FormLabel, HStack, Input, SimpleGrid, Stack, Text } from '@chakra-ui/react';
import { useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { labeStyles } from '../../EstateDue/components/PayoutRequestForm';
import DateTimePicker from 'react-datetime-picker';
import { BsCalendarDay } from 'react-icons/bs';
import { MdOutlineRemove } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { createPoll } from '../service';
import dayjs from 'dayjs';
import { infoNotifier } from '../../../../components/NotificationHandler';
const relativeTime = require('dayjs/plugin/relativeTime');
dayjs.extend(relativeTime);

export const CreatePoll = ({ onClose, setRefresh }) => {
  const managerId = useSelector((state) => state.auth.user);
  const today = new Date();
  const [pollOptions, setPollOptions] = useState([{ option: '' }, { option: '' }]);
  const initialValues = { title: '', description: '', startDate: '', answerOptions: [], endDate: '', pollQuestion: '' };
  const [pollValues, setPollValues] = useState(initialValues);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [loading, setLoading] = useState(false);

  const handleStartDate = (date) => {
    setStartDate(date);
    setPollValues((prev) => ({ ...prev, startDate: dayjs(date).format('YYYY-MM-DDTHH:mm') }));
  };

  const handleEndDate = (date) => {
    setEndDate(date);
    setPollValues((prev) => ({ ...prev, endDate: dayjs(date).format('YYYY-MM-DDTHH:mm') }));
  };

  const onChange = (e) => {
    setPollValues((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSubmit = () => {
    const { title, description, startDate, endDate, pollQuestion } = pollValues;

    const inputedStartDate = dayjs(startDate);
    const inputedEndDate = dayjs(endDate);
    const dateDifference = inputedEndDate.diff(inputedStartDate);

    if (dateDifference < 1) return infoNotifier('poll end date cannot be in the past please choos a date later than the start date');

    const answerOptionsArr = pollOptions.map((item) => item['option']);

    const formData = new FormData();
    formData.append('managerId', managerId);
    formData.append('title', title);
    formData.append('description', description);
    formData.append('startDate', startDate);
    formData.append('endDate', endDate);
    formData.append('pollQuestion', pollQuestion);
    formData.append('answerOptions', JSON.stringify(answerOptionsArr));

    createPoll(setLoading, formData).then(setRefresh);
  };

  const removeOption = (index) => {
    const data = [...pollOptions];
    data.splice(index, 1);
    setPollOptions(data);
  };

  const handleOptionChange = (e, index) => {
    let data = [...pollOptions];
    data[index][e.target.name] = e.target.value;
    setPollOptions(data);
  };

  const addOption = () => {
    setPollOptions([...pollOptions, { option: '' }]);
  };

  const disable =
    Boolean(startDate) &&
    Boolean(endDate) &&
    Boolean(pollValues.title) &&
    Boolean(pollValues.description) &&
    Boolean(pollValues.pollQuestion) &&
    Boolean(pollOptions.every((item) => item['option']));

  return (
    <Stack>
      <HStack justify='space-between'>
        <Text color='#F09091' fontSize='1.5rem'>
          Create Poll
        </Text>
        <Button onClick={onClose} leftIcon={<AiOutlineClose />} size='xs' bg='gray.100' _focus={{ outline: 'none' }}>
          Close
        </Button>
      </HStack>

      <Box pt='10'>
        <FormControl>
          <Stack spacing='8'>
            <Box>
              <FormLabel {...labeStyles}>Enter Poll Title</FormLabel>
              <Input {...inputStyles} name='title' onChange={onChange} />
            </Box>
            <Box>
              <FormLabel {...labeStyles}>Poll Description</FormLabel>
              <Input {...inputStyles} name='description' onChange={onChange} />
            </Box>

            <Box>
              <FormLabel {...labeStyles}>Start Date</FormLabel>
              <DateTimePicker
                onChange={handleStartDate}
                value={startDate}
                minDate={today}
                format='dd-MM-y h:mm:ss a'
                calendarIcon={<BsCalendarDay />}
              />
            </Box>

            <Box>
              <FormLabel {...labeStyles}>End Date</FormLabel>
              <DateTimePicker onChange={handleEndDate} value={endDate} minDate={today} format='dd-MM-y h:mm:ss a' calendarIcon={<BsCalendarDay />} />
            </Box>

            <Box>
              <FormLabel {...labeStyles}>Poll Question</FormLabel>
              <Input {...inputStyles} onChange={onChange} name='pollQuestion' />
            </Box>

            <SimpleGrid columns={2} spacing='2.5rem'>
              {pollOptions.map((pollOptions, index) => (
                <HStack align='flex-end' key={index}>
                  <Box>
                    <FormLabel {...labeStyles}>Option {index + 1}</FormLabel>
                    <Input
                      {...inputStyles}
                      size='sm'
                      name='option'
                      bg='#F6F6F6'
                      value={pollOptions.option}
                      onChange={(e) => handleOptionChange(e, index)}
                    />
                  </Box>
                  <Button bg='red.100' size='sm' _focus={{ outline: 'none' }} fontSize='13px' variant='ghost' onClick={() => removeOption(index)}>
                    <MdOutlineRemove />
                  </Button>
                </HStack>
              ))}
            </SimpleGrid>

            <HStack justify='space-between'>
              <Button onClick={addOption} _focus={{ outline: 'none' }} fontSize='13px' variant='outline'>
                Add more Option
              </Button>
              <Button isDisabled={!disable} isLoading={loading} onClick={handleSubmit} w='150px' color='white' fontSize='13px' bg='#614285'>
                Submit
              </Button>
            </HStack>
          </Stack>
        </FormControl>
      </Box>
    </Stack>
  );
};

const inputStyles = { bg: '#F6F6F6', size: 'sm', borderRadius: '4px' };
