import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Input,
  Text,
} from '@chakra-ui/react';

import { useState } from 'react';
import { useSelector } from 'react-redux';
import { ContractorAndVehicleVerification } from '../../../../Security-Portal/GuestCheckIn/security-special-request/ContractorAndVehicleVerification';
import { EventVerification } from '../../../../Security-Portal/GuestCheckIn/security-special-request/EventVerification';
import { validateCode } from '../../../../Security-Portal/GuestCheckIn/service/specialRequest';
import { SpecialRequestQRCodeCheckin } from './SepcialRequestCheckin';

export const SpecialRequestCheckin = ({ estateName }) => {
  const userId = useSelector((state) => state.auth.user);
  const [success, setSuccess] = useState(false);
  const [inviteCode, setInivteCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState({});

  const handleSubmit = () => {
    const payload = { userId, inviteCode };
    validateCode(payload, setLoading, setSuccess, setResponse);
  };

  return (
    <Box
      bg='#f2f2f2'
      display='flex'
      overflow={'hidden'}
      height={['92vh', '94vh', '80vh']}
      justifyContent='center'
      alignItems='center'
    >
      <Box padding='10px' maxW='700px' color='#fff'>
        <Heading marginBottom='10px' color='#614285' textAlign='center'>
          WELCOME TO <br /> {estateName}
        </Heading>
        {!success && (
          <Text
            fontSize='.9em'
            marginBottom='30px'
            color='#614285'
            textAlign='center'
          >
            Please enter the validation code sent to you to access this estate
            gate
          </Text>
        )}
        {!success ? (
          <Box>
            <HStack className='dd' marginTop='40px'>
              <Input
                _focus={{ outline: 'none' }}
                fontSize='.9em'
                color='#614285'
                background='#fff'
                boxShadow='0px 3px 6px #00000029'
                placeholder='Enter Code'
                size='lg'
                name='code'
                value={inviteCode}
                onChange={(e) => setInivteCode(e.target.value)}
              />
              <SpecialRequestQRCodeCheckin />
            </HStack>
            <Flex justify='center'>
              <Button
                {...btnStyles}
                isLoading={loading}
                isDisabled={!Boolean(inviteCode)}
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </Flex>
          </Box>
        ) : (
          <>
            {response.type === 'event' && (
              <EventVerification setSuccess={setSuccess} response={response} />
            )}

            {response.type !== 'event' && (
              <ContractorAndVehicleVerification
                setSuccess={setSuccess}
                response={response}
              />
            )}
          </>

          // <Stack
          //   color='#4d4e4f'
          //   bg='#f9f9f9'
          //   p='8'
          //   borderRadius='4px'
          //   shadow='lg'
          //   minW='350px'
          //   fontSize='12px'
          //   fontWeight='600'
          //   spacing='4'
          // >
          //   <HStack>
          //     <Text w='150px'>Event:</Text>
          //     <Text>{response?.subType}</Text>
          //   </HStack>
          //   <HStack>
          //     <Text w='150px'>Venue:</Text>
          //     <Text>{response?.address}</Text>
          //   </HStack>
          //   <HStack>
          //     <Text w='150px'>Description:</Text>
          //     <Text>{response?.description}</Text>
          //   </HStack>
          //   <Text w='150px'>Event Dates:</Text>
          //   <Flex gap='1rem' wrap='wrap'>
          //     {response?.dates?.map((date) => (
          //       <Text py='0.5' px='1' bg='gray.100' fontWeight='600'>
          //         {dayjs(date).format('DD MMM')}
          //       </Text>
          //     ))}
          //   </Flex>
          //   <Text>Invited Guests:</Text>
          //   <Stack w='100%' h='130px' overflowY='auto'>
          //     {response.length ? (
          //       response?.guestList?.map((guest) => (
          //         <HStack>
          //           <Text w='150px'>{guest?.name}</Text>
          //           <Text>{guest?.phone}</Text>
          //         </HStack>
          //       ))
          //     ) : (
          //       <Heading>No guest Invited yet</Heading>
          //     )}
          //   </Stack>
          //   <Button bg='#614285' color='#fff' onClick={() => setSuccess(false)}>
          //     Close
          //   </Button>
          // </Stack>
        )}
      </Box>
    </Box>
  );
};

export const btnStyles = {
  marginTop: '20px',
  bg: '#614285',
  w: '170px',
  color: '#fff',
  fontSize: '.9em',
  _focus: { outline: 'none', color: '#614285' },
  _hover: {
    outline: 'none',
    background: '#fff',
    color: '#614285',
    border: '1px solid #614285',
  },
};
