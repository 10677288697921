import http, { AUTH_ROUTES } from '../../../../services/api';
import { errorNotifier, successNotifier } from '../../../../components/NotificationHandler';

export const getIssueReports = async (userId, setLoading, setIssueReports) => {
  setLoading(true);
  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.GET_ISSUES(userId));

    setIssueReports(data.issues);
    setLoading(false);
  } catch (error) {
    setIssueReports([]);
    setLoading(false);
  }
};

export const updateIssue = async (payload) => {
  try {
    const {
      data: { result },
    } = await http.put(AUTH_ROUTES.EDIT_ISSUES(payload.id), payload);

    successNotifier(result.message);
  } catch (e) {
    errorNotifier(e?.response?.meassge);
  }
};
