import React from "react";

import {
  Text,
  Box,
  ModalContent,
  Modal,
  ModalOverlay,
  ModalBody,
  ModalCloseButton,
  Flex,
  Divider,
} from "@chakra-ui/react";
import { nanoid } from "@reduxjs/toolkit";

const EstateValidationModal = ({ estateIdTenantData, isOpen, handleClose }) => {
  return (
    <Modal size="3xl" isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <Flex alignItems="center" justifyContent="space-between" padding="20px">
          <Text
            fontSize="1.3em"
            fontWeight="400"
            // pt="10px"
            pl="30px"
            color="#614285"
          >
            Estate ID:
            {estateIdTenantData?.data?.result?.data?.house?.estate[0]
              ?.estateNumber || "not available"}
          </Text>

          <ModalCloseButton
            display="flex"
            alignItems="flex-start"
            // background="transparent"
            position="relative"
            _focus={{ boxShadow: "none" }}
          />
        </Flex>

        <Divider />
        <ModalBody>
          <Box bg="#fff" ml="30px" display="flex">
            <Flex flexDirection="column">
              <Text padding="5px 2px" fontSize="0.9em" color="#949494" fontWeight="medium">
                HOUSE ADDRESS NO:
              </Text>

              <Text fontSize="0.8em" marginTop="-3px" color="#A08CA8">
                {estateIdTenantData?.data?.result?.data?.house?.streetId
                  ?.streetName || "not available"}
              </Text>

              <Text padding="5px 2px" fontSize="0.9em" color="#949494" fontWeight="medium">
                LANDLORD NAME:
              </Text>

              <Text fontSize="0.8em" mt="-3px" color="#A08CA8">
                {estateIdTenantData?.data?.result?.data?.house?.landLordName ||
                  "not available"}
              </Text>

              <Text padding="5px 2px" fontSize="0.9em" color="#949494"fontWeight="medium">
                LANDLORD PHONE NO:
              </Text>

              <Text fontSize="0.8em" color="#A08CA8">
                {estateIdTenantData?.data?.result?.data?.house?.phoneNumber ||
                  "not available"}
              </Text>
            </Flex>
          </Box>
          <Box bg="#fff">
            <Text padding="25px 30px" fontSize="0.9em" color="#949494" fontWeight="medium">
              UNIT RESIDENTS:
            </Text>
            <Flex flexDirection={["column", "column", "row"]}>
              {estateIdTenantData?.data?.result?.data?.tenants?.map(
                (member) => {
                  return (
                    <Box mt={-4} key={nanoid()}>
                      <Text
                        pl={8}
                        fontSize="0.8em"
                        color="#949494"
                        fontWeight="medium"
                      >
                        FLAT {member.houseUnit || "not available"}
                      </Text>
                      <Text fontSize="0.8em" color="#A08CA8" pl={8} isTruncated>
                        {member.tenantName || "not available"}
                      </Text>
                      <Text
                        pl={7}
                        fontSize="0.8em"
                        color="#C4C4C4"
                        mb={10}
                        isTruncated
                      >
                        {member.tenantPhone || "not available"}
                      </Text>
                    </Box>
                  );
                }
              )}
            </Flex>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default EstateValidationModal;
