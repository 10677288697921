import {
  Modal as ChakraModal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Button,
  useDisclosure,
  Flex,
  Spacer,
  Text,
  Center,
  Box,
} from '@chakra-ui/react';
import { AiFillCloseCircle } from 'react-icons/ai';
import { Children, isValidElement, cloneElement } from 'react';

export function Modal(props) {
  const {
    title,
    buttonText,
    children,
    bg,
    small = false,
    icon = false,
  } = props;

  const { isOpen, onOpen, onClose } = useDisclosure();

  const childrenWithProps = Children.map(children, (child) => {
    // Checking isValidElement is the safe way and avoids a TS error too.
    if (isValidElement(child)) {
      return cloneElement(child, { onClose });
    }

    return child;
  });

  return (
    <>
      {icon ? (
        <Box cursor='pointer' onClick={onOpen}>
          {icon}
        </Box>
      ) : (
        <Button
          bg={bg ? bg : '#FF897A'}
          color='#fff'
          borderRadius='3px'
          height={small ? '6' : '10'}
          size={small ? 'xs' : 'md'}
          _focus={{ boxShadow: 'none' }}
          _hover={{ bg: bg ? bg : '#FF897A' }}
          onClick={onOpen}
        >
          {buttonText}
        </Button>
      )}

      <ChakraModal
        closeOnOverlayClick={true}
        isOpen={isOpen}
        onClose={onClose}
        isCentered
      >
        <ModalOverlay />
        <ModalContent sx={{ 'border-radius': '0' }}>
          <ModalHeader
            bg='#FF897A'
            px={{ base: 4, md: 10 }}
            py={{ base: 5, md: 1, lg: 3 }}
            fontWeight='normal'
          >
            <Flex>
              <Text fontSize='sm' color='#fff' alignSelf='center'>
                {title}
              </Text>
              <Spacer />

              <Center ml={3}>
                <AiFillCloseCircle
                  onClick={onClose}
                  color='#fff'
                  style={{ marginRight: '-.5rem', cursor: 'pointer' }}
                />
              </Center>
            </Flex>
          </ModalHeader>
          <ModalBody px={8} py={{ base: 6, md: 4, lg: 6 }}>
            {childrenWithProps}
          </ModalBody>
        </ModalContent>
      </ChakraModal>
    </>
  );
}
