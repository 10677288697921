import http, { AUTH_ROUTES } from '../../../../services/api';

export const getContractorAndArtisanPayments = async (userId, setLoading, setContractorAndArtisans) => {
  setLoading(true);
  try {
    const {
      data: { data },
    } = await http.get(AUTH_ROUTES.MANAGER_GET_CONTRACTOR_AND_ARTISANS_PAYMENT(userId));

    setContractorAndArtisans(data?.requests);
  } catch (e) {
    setLoading(false);
  } finally {
    setLoading(false);
  }
};
