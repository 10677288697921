import { Box, Heading, Text } from '@chakra-ui/react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { guestCheckOut } from '../service/guestCheckOutApi';
import { getGuestCode } from '../../GuestCheckIn/service/securityGuestValidationApi';
import { ValidationConfirmation } from '../components/ValidationConfirmation';
import { CheckInForm } from '../../GuestCheckIn/component/CheckInForm';
import { Success } from '../../GuestCheckIn/component/Success';

export const SecurityGuestCheckOut = ({ estateName }) => {
  const userId = useSelector((state) => state.auth.user);
  const [success, setSuccess] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [confirmationMsg, setConfirmationMsg] = useState('');
  const [guestCode, setGuestCode] = useState('');
  const [invitedGuest, setInvitedGuest] = useState([]);

  const handleSubmit = (e, guestCode) => {
    e.preventDefault();
    const payload = { guestCode, userId };

    guestCheckOut(payload, setLoading, setConfirmationMsg, setSuccess);
  };

  const handleGetGuest = (e) => {
    e.preventDefault();

    getGuestCode(setLoading, userId, guestCode, setInvitedGuest, setConfirm);
  };

  const onclickBack = () => {
    setConfirmationMsg('');
    setGuestCode('');
    setSuccess(false);
    setConfirm(false);
  };

  return (
    <Box bg='#f2f2f2' display='flex' height={['92vh', '94vh', '80vh']} justifyContent='center' alignItems='center'>
      <Box padding='10px' maxW='700px' color='#fff' textAlign='center'>
        <Heading marginBottom='10px' color='#614285'>
          GOODBYE FROM <br /> {estateName}
        </Heading>
        {!success && (
          <Text fontSize='.9em' marginBottom='30px' color='#614285'>
            Please enter the validation code sent to you to sign out from this estate
          </Text>
        )}

        <Box className='dd' marginTop='40px'>
          {!success && !confirm && (
            <CheckInForm handleGetGuest={handleGetGuest} loading={loading} guestCode={guestCode} setGuestCode={setGuestCode} />
          )}

          {!success && confirm && (
            <ValidationConfirmation
              onclickBack={onclickBack}
              guestCode={guestCode}
              loading={loading}
              invitedGuest={invitedGuest}
              setGuestCode={setGuestCode}
              handleSubmit={handleSubmit}
              setConfirmationMsg={setConfirmationMsg}
              confirmationMsg={confirmationMsg}
            />
          )}

          {success && <Success onclickBack={onclickBack} />}
        </Box>
      </Box>
    </Box>
  );
};
