export const getBadgeColor = (type) => {
    if (!type) return;
    if(type.toLowerCase() === "in"){
        return "green";
    }
    if (type.toLowerCase() === 'out'){
        return "red";
    }
    if (type.toLowerCase() === 'pending'){
        return "#bdbd1b"
    }
    return "blackAplha";
} 