import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { getEstateName } from '../GuestCheckIn/service/securityGuestValidationApi';
import { SecurityGuestCheckOut } from './components/SecurityGuestCheckout';
import { SecuritySpecialRequestCheckout } from './components/SecuritySpecialRequestCheckout';

export const SecurityCheckout = ({ setShowSideBar }) => {
  const userId = useSelector((state) => state.auth.user);
  const [estateName, setEstateName] = useState('Our Estate');

  useEffect(() => {
    setShowSideBar();
  }, [setShowSideBar]);

  useEffect(() => {
    getEstateName(setEstateName, userId);
  }, [userId]);

  return (
    <Tabs variant='unstyled' mt='10'>
      <TabList display='flex' justifyContent='center'>
        <Tab {...selectedTabStyles}>Guest Check out</Tab>
        <Tab {...selectedTabStyles}>Special Request Check out</Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <SecurityGuestCheckOut estateName={estateName} />
        </TabPanel>
        <TabPanel>
          <SecuritySpecialRequestCheckout />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export const selectedTabStyles = {
  fontWeight: 600,
  fontSize: '16px',
  color: 'gray.700',
  mx: '2rem',
  _selected: { color: '#F09091', borderBottom: '3px solid #F09091 ' },
  _focus: { border: 'none' },
};
