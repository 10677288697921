import { Box, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import dayjs from 'dayjs';

import Card from './Card';

const Calender = () => {
  const options = {
    month: 'long'
  }

  return (
    <Card>
      <Text as='h3' textAlign='center' fontWeight='600'>
        Calender
      </Text>
      <VStack spacing={0} mt={2}>
        <Box
          color='white'
          bg='red.500'
          w='60%'
          textAlign='center'
          fontSize='12px'
        >
          <Text>{new Intl.DateTimeFormat('en-US', options).format(Date.now())}, {dayjs().set(Date.now()).year()}</Text>
          <Text></Text>
          <Text>{new Intl.DateTimeFormat('en-US', {weekday: 'long'}).format(Date.now())}</Text>
        </Box>
        <Text
          bg='white'
          w='60%'
          textAlign='center'
          fontSize='50px'
          color='black'
          fontWeight={600}
        >
          {dayjs().set(Date.now()).date()}
        </Text>
      </VStack>
    </Card>
  );
};

export default Calender;
