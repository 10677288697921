import { Box, Drawer, DrawerBody, DrawerContent, DrawerOverlay, useDisclosure } from '@chakra-ui/react';
import { Children, cloneElement, isValidElement } from 'react';

export const CustomDrawer = ({ children, btnTitle, btnProps, size }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const childrenWithProps = Children.map(children, (child) => {
    // Checking isValidElement is the safe way and avoids a TS error too.
    if (isValidElement(child)) {
      return cloneElement(child, { onClose });
    }

    return child;
  });

  return (
    <>
      <Box onClick={onOpen} {...btnProps}>
        {btnTitle}
      </Box>
      <Drawer size={size || 'lg'} isOpen={isOpen} placement='right' onClose={onClose} isLazy>
        <DrawerOverlay />
        <DrawerContent py='5'>
          {/* <DrawerCloseButton bg='gray.200' mt='5' _focus={{ outline: 'none' }}>
            Close
          </DrawerCloseButton>
          <DrawerHeader color='#F09091'>Issue Report</DrawerHeader> */}

          <DrawerBody>{childrenWithProps}</DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};
