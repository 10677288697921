import http, { AUTH_ROUTES } from '../../../../services/api';

export const getEmergency = async (setLoading, userId, setEmergency) => {
  try {
    const {
      data: {
        result: {
          data: { emergencies },
        },
      },
    } = await http.get(AUTH_ROUTES.SECURITY_GET_EMERGENCIES(userId));
    setEmergency(emergencies);

    setLoading(false);
  } catch (error) {
    setLoading(false);
    // errorNotifier(error?.message);
    setEmergency([]);
  } finally {
    setLoading(false);
  }
};
