import { Button, Flex, Heading, HStack, Stack, Text } from '@chakra-ui/react';
import { useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { HiOutlineSpeakerphone } from 'react-icons/hi';
import { useSelector } from 'react-redux';
import CautionAlertDialog from '../../../../components/CautionAlertDialog';
import { setVisibility } from '../service';
import ProgressBar from './Progressbar';

export const PollResult = ({ onClose, result, setRefresh }) => {
  const userId = useSelector((state) => state.auth.user);
  const [loading, setLoading] = useState(false);

  const handleVisibility = (setType) => {
    const payload = { userId, pollId: result.data._id, resultVisibility: setType === 'publish' ? true : false };

    setVisibility(setLoading, payload, onClose).then(setRefresh);
  };

  const voteCountsArray = Object.values(result.votes);

  const totalVoteCounts = voteCountsArray.reduce((accumulator, currentValue) => accumulator + currentValue);

  const calculatePercentage = (numA, numB) => (numA / numB) * 100;

  return (
    <Stack py='5'>
      <HStack justify='space-between' borderBottom='1px solid #eee' pb='8'>
        <Heading fontSize='1.2rem' color='#F09091'>
          Poll Result
        </Heading>

        <Button onClick={onClose} leftIcon={<AiOutlineClose />} size='xs' bg='gray.100' _focus={{ outline: 'none' }}>
          Close
        </Button>
      </HStack>
      <Text {...heads}>Poll Title:</Text>

      <Text p='2' bg='gray.50'>
        {result?.data?.title}
      </Text>

      <Text {...heads}> Poll Description</Text>
      <Text p='2' bg='gray.50' fontSize='14px'>
        {result?.data?.description}
      </Text>

      <Text {...heads} pt='8'>
        Options
      </Text>
      <Stack spacing={5}>
        {result?.data?.answerOptions?.map((option) => (
          <ProgressBar completed={calculatePercentage(result.votes[option], totalVoteCounts) || 0} optionName={option.toUpperCase()} />
        ))}
      </Stack>
      <Text fontWeight={600} fontSize={'14px'}>
        Total votes:{totalVoteCounts}
      </Text>
      <Flex justify='center' pt='5rem' gap='2.5rem'>
        <CautionAlertDialog
          bg='none'
          cautionTitle={'Release Poll Result?'}
          btnProps={{ loading: { loading }, w: '150px', color: '#000', size: 'sm', variant: 'outline' }}
          btnTitle='Unpublish result'
          onContinue={() => handleVisibility('unpublish')}
        />
        <CautionAlertDialog
          bg='teal.200'
          cautionTitle={'Release Poll Result?'}
          btnProps={{ leftIcon: <HiOutlineSpeakerphone />, color: '#000', w: '150px', size: 'sm' }}
          btnTitle='Publish result'
          onContinue={() => handleVisibility('publish')}
        />
      </Flex>
    </Stack>
  );
};

const heads = { fontSize: '0.9rem', pt: '3', fontWeight: '600' };
