import { Box, Flex, Heading, Text } from '@chakra-ui/layout';
import { useState } from 'react';
import dayjs from 'dayjs';
import './navigation.css';
import { useLocation } from 'react-router-dom';
import { getComponentTitle } from './components/GetComponentTitle';
import { AiOutlineAlignLeft } from 'react-icons/ai';

export const Navigation = ({ toggle, showSidBar, userType, type }) => {
  const [toggleSide] = useState(false);
  const location = useLocation();
  const pathname = location.pathname;

  const handleToggle = () => {
    toggle(!toggleSide);
  };

  return (
    <Box position='relative'>
      <Box className='navigation-inner'>
        <Box
          display='grid'
          gridAutoFlow='column'
          width='100%'
          padding='20px 10px'
          alignItems='center'
        >
          <Flex alignItems='center'>
            <Box className='toggle-side-bar'>
              {showSidBar ? (
                <AiOutlineAlignLeft
                  onClick={handleToggle}
                  style={{ cursor: 'pointer' }}
                />
              ) : (
                ''
              )}
            </Box>
            <Heading color='red.300' size='md' marginLeft={['5px', '50px']}>
              {getComponentTitle(pathname)}
            </Heading>
          </Flex>
          <Box
            display={'flex'}
            alignItems='center'
            className='date'
            fontSize={['.8em', '.9em', '1em']}
          >
            <Text color='#614285'> {dayjs().format('ddd, MMM D, YYYY')} </Text>
            <Text ml='20px' color='#614285'>
              {' '}
              {dayjs().format('HH:mm')}{' '}
            </Text>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
