import http, { AUTH_ROUTES } from '../../../../services/api';
import { errorNotifier, successNotifier } from '../../../../components/NotificationHandler';

//MANAGER GUEST VALIDATION
export const guestCheckOut = async (payload, setLoading, setConfirmationMsg, setSuccess) => {
  setLoading(true);
  try {
    const data = await http.post(AUTH_ROUTES.MANAGER_GUEST_CHECKOUT, payload);

    successNotifier(data?.data?.result?.message);
    setSuccess(true);
  } catch (e) {
    setConfirmationMsg(e.response?.data?.result?.message);
  } finally {
    setLoading(false);
  }
};

export const getEstateName = async (setEstateName, userId) => {
  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.GET_ESTATE_INFO(userId));
    setEstateName(data?.estateName);
  } catch (e) {}
};

export const specialRequestCheckout = async (setLoading, payload, setSuccess, setMessage) => {
  setLoading(true);
  try {
    const {
      data: { result },
    } = await http.post(AUTH_ROUTES.MANAGER_SPECIAL_REQUEST_CHECKOUT, payload);

    successNotifier(result.message);
    setSuccess(true);
  } catch (e) {
    errorNotifier();
  } finally {
    setLoading(false);
  }
};
