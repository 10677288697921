import { HStack, Stack, Text } from '@chakra-ui/react';
import { useState } from 'react';
import { CgArrowLongLeft } from 'react-icons/cg';
import { useHistory } from 'react-router-dom';
import { CustomTable } from '../../../../components/customized table/CustomTable';
import FullPageLoader from '../../../../components/full-page-loader';

export const PayoutHistory = () => {
  const history = useHistory();
  const [loading] = useState(false);

  const emergencyTableHead = [
    {
      Header: 'S/N',
      accessor: 'id',
      Cell: ({ row }) => Number(row.id) + 1,
    },
    {
      Header: 'Estate Name',
      accessor: 'u',
    },
    {
      Header: 'Account numbere',
      // accessor: 'userId.tenantPhone',
    },
    {
      Header: 'Bank Name',
      // accessor: 'userId.tenantName',
    },
    {
      Header: 'Account Name',
      // accessor: 'userId.houseId.estate[0].houseNumber',
    },
    {
      Header: 'Amount',
      // accessor: 'type',
    },
    {
      Header: 'Time Request',
      // accessor: 'type',
    },
    {
      Header: 'Date Request',
      // accessor: 'type',
    },
    {
      Header: 'Status',
      // accessor: 'type',
    },
  ];

  return loading ? (
    <FullPageLoader />
  ) : (
    <Stack p='10'>
      <HStack py='5' cursor='pointer' onClick={() => history.goBack()}>
        <CgArrowLongLeft size={25} />
        <Text>Back</Text>
      </HStack>

      <Text pb='3' borderBottom='2px solid #ddd' fontSize='1.3rem' fontWeight={700} color='#F09091'>
        Payout History
      </Text>
      <CustomTable COLUMNS={emergencyTableHead} DATA={[]} />
    </Stack>
  );
};
