import http, { AUTH_ROUTES } from '../../../../services/api';
import { successNotifier, errorNotifier } from '../../../../components/NotificationHandler';

export const createHouse = async (payload, setLoading, onClose, setRefresh) => {
  setLoading(true);
  try {
    await http.post(AUTH_ROUTES.CREATE_HOUSE, payload);
    successNotifier('House Successfully created');
    onClose();
    setRefresh(true)
  } catch (e) {
    if(e.response) {
      errorNotifier(e?.response?.data?.result?.message);
    }
    errorNotifier("Network Error");
  } finally {
    setLoading(false);
  }
};
