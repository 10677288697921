import { Box, Button, Flex, Heading, HStack, Input, Text } from '@chakra-ui/react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { SpecialRequestQRCodeCheckin } from '../../../Manager-Portal/GuestCheckIn/component/manager special request/SepcialRequestCheckin';
import { validateCode } from '../service/specialRequest';
import { ContractorAndVehicleVerification } from './ContractorAndVehicleVerification';
import { EventVerification } from './EventVerification';

export const SpecialRequestCheckin = ({ estateName }) => {
  const userId = useSelector((state) => state.auth.user);
  const [success, setSuccess] = useState(false);
  const [inviteCode, setInivteCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState('');

  const handleSubmit = () => {
    const payload = { userId, inviteCode };
    validateCode(payload, setLoading, setSuccess, setResponse);
  };

  return (
    <Box bg='#f2f2f2' display='flex' overflow={'hidden'} height={['92vh', '94vh', '75vh']} justifyContent='center' alignItems='center'>
      <Box padding='10px' maxW='700px' color='#fff'>
        <Heading marginBottom='10px' color='#614285' textAlign='center'>
          WELCOME TO <br /> {estateName}
        </Heading>
        {!success && (
          <Text fontSize='.9em' marginBottom='30px' color='#614285' textAlign='center'>
            Please enter the validation code sent to you to access this estate gate
          </Text>
        )}
        {!success ? (
          <Box className='dd' marginTop='40px'>
            <HStack>
              <Input
                _focus={{ outline: 'none' }}
                fontSize='.9em'
                color='#614285'
                background='#fff'
                boxShadow='0px 3px 6px #00000029'
                placeholder='Enter Code'
                size='lg'
                name='code'
                value={inviteCode}
                onChange={(e) => setInivteCode(e.target.value)}
              />
              <SpecialRequestQRCodeCheckin />
            </HStack>
            <Flex justify='center'>
              <Button {...btnStyles} isLoading={loading} isDisabled={!Boolean(inviteCode)} onClick={handleSubmit}>
                Submit
              </Button>
            </Flex>
          </Box>
        ) : (
          <>
            {response.type === 'event' && <EventVerification setSuccess={setSuccess} response={response} />}

            {response.type !== 'event' && <ContractorAndVehicleVerification setSuccess={setSuccess} response={response} />}
          </>
        )}
      </Box>
    </Box>
  );
};

export const btnStyles = {
  marginTop: '20px',
  bg: '#614285',
  w: '180px',
  color: '#fff',
  fontSize: '.9em',
  _focus: { outline: 'none', color: '#614285' },
  _hover: {
    outline: 'none',
    background: '#fff',
    color: '#614285',
    border: '1px solid #614285',
  },
};
