import {
  Button,
  useDisclosure,
  Input,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Textarea,
  Flex,
  Img,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { errorNotifier } from './NotificationHandler';
import { BsPlusCircleFill } from 'react-icons/bs';
import { PrimaryBTN } from './CustomButtons';
import { useEffect, useState } from 'react';
import { nanoid } from '@reduxjs/toolkit';
import axios from 'axios';
import { createNewsEvent } from '../pages/Manager-Portal/news events/service/newsEvents';

export const AddEventsModal = ({ setRefresh }) => {
  const userId = useSelector((state) => state.auth.user);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const [newsImage, setNewsImage] = useState([]);
  let imgUrlsFromCloudinary = [];
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [disable, setDisable] = useState(true);

  useEffect(() => {
    title && message && newsImage.length !== 0 ? setDisable(false) : setDisable(true);
  }, [title, message, newsImage.length]);

  const handleImage = (e) => {
    if (!e.target.files) return;

    if (Array(...e.target.files).length > 5) {
      errorNotifier('You can only 5images at most');
      return;
    }

    if (e.target.files) {
      Array(...e.target.files).forEach((file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onloadend = () => {
          setNewsImage((prev) => [...prev, { imageUrl: reader.result, file }]);
        };
      });
    }
  };

  const handleCreateNewsEvent = (e, files) => {
    e.preventDefault();
    setLoading(true);

    const uploaders = files?.map((file) => {
      const formData = new FormData();

      formData.append('file', file.file);
      formData.append('upload_preset', 'akogwu-cloudinary');

      return axios
        .post('https://api.cloudinary.com/v1_1/akogwu/image/upload', formData, {
          headers: { 'X-Requested-With': 'XMLHttpRequest' },
        })
        .then((response) => {
          const data = response.data;
          const fileURL = data.secure_url; // You should store this URL for future references in your app
          imgUrlsFromCloudinary.push(fileURL);
        });
    });

    axios.all(uploaders).then(() => {
      const payload = {
        title,
        content: message,
        userId,
        image: imgUrlsFromCloudinary,
      };

  
      createNewsEvent(payload, setLoading, setRefresh);
    });
  };

  return (
    <>
      <Button onClick={onOpen} bg='#614285' color='white' _hover={{ bg: '#614285' }}>
        Create Event
      </Button>
      <Modal size='md' isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader color='white' bg='#F09091' p={5}>
            Add Event
          </ModalHeader>
          <ModalBody>
            <FormControl>
              <FormLabel color='#343434' fontSize='0.9rem' mt={5}>
                Title
              </FormLabel>
              <Input bg='#F6F6F6' color='black' name='' value={title} onChange={(e) => setTitle(e.target.value)} />

              <FormLabel color='#343434' fontSize='0.9rem' mt={5}>
                Content
              </FormLabel>
              <Textarea bg='#F6F6F6' color='black' name='' value={message} onChange={(e) => setMessage(e.target.value)} />
              <Flex my='5'>
                <FormLabel w='110px' htmlFor='image-picker' fontSize='12px'>
                  upload a maximum of 5 images
                  <BsPlusCircleFill color='#ff897a' size='1.5rem' />
                </FormLabel>
                <Flex wrap='wrap'>
                  {newsImage?.map((img) => (
                    <Img key={nanoid()} borderRadius='2px' shadow='md' w='70px' h='70px' m='2' src={img.imageUrl} alt={img.file.name} />
                  ))}
                  <Input type='file' id='image-picker' multiple display='none' accept='image/*' onChange={handleImage} />
                </Flex>
              </Flex>
              <PrimaryBTN
                isLoading={loading}
                mt='10'
                isDisabled={disable}
                mb='5'
                loadingText='updating'
                onClick={(e) => handleCreateNewsEvent(e, newsImage)}
              >
                Create Event
              </PrimaryBTN>
            </FormControl>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
