import { Box } from '@chakra-ui/react';
import FullPageLoader from '../../../../components/full-page-loader';
import ValidVisitDetails from '../../../Manager-Portal/GuestCheckIn/component/ValidVisitDetails';

export const ValidationConfirmation = ({ loading, handleSubmit, guestCode, onclickBack, invitedGuest, setConfirmationMsg, confirmationMsg }) => {
  const guest = invitedGuest?.guests?.filter((guest) => guest?.guestCode === guestCode);

  return loading ? (
    <FullPageLoader bg='#f2f2f2' h='100%' />
  ) : (
    <Box className='confirmation-data' bg='#fff' paddingLeft='35px' color='#4f4d4d' borderRadius='3px'>
      <ValidVisitDetails
        setConfirmationMsg={setConfirmationMsg}
        confirmationMsg={confirmationMsg}
        guest={guest}
        handleSubmit={handleSubmit}
        guestCode={guestCode}
        onclickBack={onclickBack}
      />
    </Box>
  );
};
