import { Box, Text } from '@chakra-ui/react';

const ProgressBar = (props) => {
  const { completed, optionName, handleVote } = props;

  const containerStyles = {
    height: 30,
    width: '100%',
    backgroundColor: '#e0e0de',
    borderRadius: 10,
    position: 'relative',
    // display: 'flex',
  };

  const fillerStyles = {
    height: '100%',
    width: `${completed || 0}%`,
    // backgroundColor: bgcolor,
    backgroundColor: '#73C2FB',
    borderRadius: 10,
    display: 'flex',
    // // alignItems: 'center',
  };

  const labelStyles = {
    padding: 5,
    color: 'white',
    fontWeight: 'bold',
  };

  return (
    <Box style={containerStyles} onClick={handleVote}>
      <Box style={fillerStyles}>
        <Text fontSize='15px' as='span' style={labelStyles}>{`${completed}%`}</Text>
      </Box>
      <Text fontSize='15px' fontWeight='600' pos='absolute' top='3px' right='10px' m='auto'>
        {optionName || 'name'}
      </Text>
    </Box>
  );
};

export default ProgressBar;
