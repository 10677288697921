import React, { useState } from 'react';

import {
  FormControl,
  FormLabel,
  Button,
  Input,
  Box,
  Heading,
  Flex,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { updateResident } from '../service';

export const EditResidentForm = ({ initialData, onClose, setRefresh }) => {
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState(initialData);
  const userId = useSelector((state) => state.auth.user);

  const INPUT_STYLE = {
    mb: '22px',
    fontSize: '.9em',
    color: '#4f4f4f',
  };
  const BTN_STYLE = {
    color: '#fff',
    bg: '#614285',
    _hover: { bg: '#462d64' },
    w: '100%',
    _focus: { borderRadius: 'none' },
  };
  const LABEL_STYLE = { mb: '3px' };
  const FLEX_STYLE = { bg: '#FFFFFF' };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };
  const handleUpdate = async (e) => {
    e.preventDefault();
    const params = {
      ...formValues,
      userId,
    };
    setLoading(true);
    await updateResident(params, setLoading, onClose).then(setRefresh);
  };

  return (
    <Box color='#8366A5' margin='10px 0'>
      <Heading fontSize='lg' mb={5}>
        Edit resident
      </Heading>
      <Flex {...FLEX_STYLE}>
        <FormControl as='form' isRequired>
          <FormLabel {...LABEL_STYLE}>Tenant name</FormLabel>
          <Input
            type='text'
            {...INPUT_STYLE}
            name='tenantName'
            value={formValues?.tenantName}
            onChange={handleChange}
          />
          <FormLabel {...LABEL_STYLE}>Address</FormLabel>
          <Input
            type='text'
            {...INPUT_STYLE}
            name='houseAddress'
            value={formValues?.streets?.streetName}
            onChange={handleChange}
          />

          <FormLabel {...LABEL_STYLE}>Phone number</FormLabel>
          <Input
            type='tel'
            {...INPUT_STYLE}
            name='tenantPhone'
            value={formValues?.tenantPhone}
            onChange={handleChange}
          />

          <FormLabel {...LABEL_STYLE}>House no</FormLabel>
          <Input
            type='text'
            {...INPUT_STYLE}
            name='houseUnit'
            value={formValues?.houseUnit}
            onChange={handleChange}
          />

          <Button
            onClick={handleUpdate}
            isLoading={loading}
            type='Submit'
            {...BTN_STYLE}
          >
            Update
          </Button>
        </FormControl>
      </Flex>
    </Box>
  );
};
