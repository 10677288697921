import { DashboardArray } from '../../../Security-Portal/Dashboard/component/DashboardDataArray';
import http, { AUTH_ROUTES } from '../../../../services/api';
import { errorNotifier, successNotifier } from '../../../../components/NotificationHandler';
import { DATA_ROWS } from '../../../../app/constants';

//DASHBOARD ANALYTICS FOR THE TOP CARDS
export const getDashboard = async (setLoading, userId, setDashData) => {
  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.SECURITY_GET_DASHBOARD(userId));

    const info = DashboardArray.map((val) => {
      return {
        ...val,
        value: data[val.key],
      };
    });
    setDashData(info);
  } catch (e) {
    // errorNotifier(e?.response?.data.result);
    setDashData(DashboardArray);
    setLoading(false);
  } finally {
    setLoading(false);
  }
};

//GET EMERGENCIES HANDLER
export const getEmergencies = async (setLoading, userId, skip = 0, limit = DATA_ROWS.LIMIT, setEmergencies, status = 'PENDING') => {
  const page = limit * skip;
  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.SECURITY_GET_EMERGENCIES(userId, page, limit, status));

    setEmergencies(data.emergencies);
    setLoading(false);
  } catch (e) {
    // errorNotifier(e.response?.data.result);
    setEmergencies([]);
    setLoading(false);
  } finally {
    setLoading(false);
  }
};

//GET NOTICES HANDLER
export const getNotices = async (setLoading, userId, setNotices) => {
  const params = {
    userId,
    // sort: "",
    limit: '',
    skip: '',
  };
  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.SECURITY_GET_NOTICES(params));

    setNotices(data.notices);
    setLoading(false);
  } catch (e) {
    setLoading(false);
  } finally {
    setLoading(false);
  }
};

//GUEST VALIDATION
export const guestValidation = async (payload) => {
  try {
    const data = await http.post(AUTH_ROUTES.SECURITY_GUEST_VALIDATION, payload);
    successNotifier(data?.result?.message);
  } catch (e) {
    errorNotifier(e.response?.data?.result?.message);
  }
};

export const getEmergencyChartData = async (setLoading, userId, setEmergencyChartData) => {
  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.EMERGENCY_CHART_DATA(userId));
    setEmergencyChartData(data);
    setLoading(false);
  } catch (e) {
    setLoading(false);
  } finally {
    setLoading(false);
  }
};
