import React, { useEffect, useState } from 'react';
import { Flex, Box } from '@chakra-ui/react';
import NotificationCard from './component/NotificationCard';
import { getSecurityNotifications } from './service/index';

const Security_Notification = () => {
  const [getNotifications, setNotification] = useState([]);

  useEffect(() => {
    getSecurityNotifications(setNotification);
  }, []);

  return (
    <Flex mt='30px'>
      <Box w={['90%', '90%', '95', '98%']} h={['100vh', '100vh', '90vh', '80vh']}>
        <Box color='white'>
          {getNotifications?.map((content, key) => {
            return (
              <NotificationCard
                key={key}
                bgColor={content?.background}
                title={content?.title}
                description={content?.body}
                img={content?.image}
                amount={content?.value}
                alt={content?.title}
              />
            );
          })}
        </Box>
      </Box>
    </Flex>
  );
};

export default Security_Notification;
