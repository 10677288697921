import http, { AUTH_ROUTES } from '../../../../services/api';

export const getEmergency = async (userId, setLoading, setEmergency) => {
  setLoading(true);
  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.GET_EMERGENCY(userId));

    setEmergency(data?.emergencies);
    setLoading(false);
  } catch (error) {
    setEmergency([]);
    setLoading(false);
  } finally {
    setLoading(false);
  }
};
