import http from '../../../../services/api';
import { AUTH_ROUTES } from '../../../../services/routes.constants';
import { errorNotifier, successNotifier } from '../../../../components/NotificationHandler';

export const getStreets = async (userId) => {
  try {
    const result = await http.get(AUTH_ROUTES.GET_STREETS(userId));
    return result;
  } catch (e) {
    // errorNotifier(e.response?.data?.result?.message);
  }
};

export const getStreet = async (setLoading, userId, streetId, setStreet) => {
  try {
    const result = await http.get(AUTH_ROUTES.GET_STREET(userId, streetId));
    setStreet(result.data.result.data.streets[0]);
    setLoading(false);
  } catch (e) {
    setLoading(false);
  }
};

export const createStreet = async (setLoading, payload, onClose) => {
  setLoading(true);
  try {
    await http.post(AUTH_ROUTES.CREATE_STREET, payload);
    successNotifier('Successfully created street');
    setLoading(false);
    onClose();
  } catch (e) {
    setLoading(false);
    errorNotifier(e.response?.data?.result?.message);
  }
};

export const editStreet = async (setLoading, userId, streetId, payload, onClose) => {
  setLoading(true);
  try {
    await http.put(AUTH_ROUTES.EDIT_STREET(userId, streetId), payload);
    successNotifier('Successfully updated street');
    onClose();
  } catch (e) {
    errorNotifier(e.response?.data?.result?.message);
  } finally {
    setLoading(false);
  }
};

export const deleteStreet = async (userId, streetId) => {
  try {
    await http.delete(AUTH_ROUTES.DELETE_STREET(userId, streetId));
    successNotifier('Successfully deleted street');
  } catch (e) {
    errorNotifier(e.response?.data?.result?.message);
  }
};
