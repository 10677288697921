import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Stack,
  Switch,
  Text,
} from '@chakra-ui/react';
import { AiOutlineClose } from 'react-icons/ai';
import { RiErrorWarningFill } from 'react-icons/ri';

export const PayoutRequestForm = ({ onClose }) => {
  const handleMax = () => {};
  return (
    <Stack px='2rem' py='2'>
      <HStack justify='space-between' borderBottom='1px solid #eee' pb='5'>
        <Heading color='#F09091' fontSize='1.5rem'>
          {' '}
          Request Withdrawal
        </Heading>
        <Button onClick={onClose} leftIcon={<AiOutlineClose />} size='xs' bg='gray.100' _focus={{ outline: 'none' }}>
          Close
        </Button>
      </HStack>

      <Box pt='10'>
        <FormControl>
          <Stack spacing='10'>
            <Box>
              <FormLabel {...labeStyles}>Choose wallet to debit</FormLabel>
              <Select placeholder='select wallet'>
                <option>Estate Due wallet</option>
                <option>Vehicle Due wallet</option>
                <option>Contractors payment wallet</option>
              </Select>
            </Box>

            <Box>
              <FormLabel {...labeStyles}>Amount</FormLabel>
              <InputGroup size='md'>
                <Input pr='4.5rem' type='number' placeholder='Enter Amount to withdraw' />
                <InputRightElement width='4.5rem'>
                  <Button h='1.75rem' size='sm' onClick={handleMax}>
                    Max
                  </Button>
                </InputRightElement>
              </InputGroup>
            </Box>
            <HStack>
              <Box>
                <FormLabel {...labeStyles}>Bank Account Number</FormLabel>
                <Input />
              </Box>
              <Box>
                <FormLabel {...labeStyles}>Bank name</FormLabel>
                <Input />
              </Box>
            </HStack>

            <Box>
              <FormLabel {...labeStyles}>Account name</FormLabel>
              <Input />
            </Box>

            <Switch>Save Details</Switch>
            <Flex jutify='space-between' gap='2rem'>
              <HStack flex='1'>
                <RiErrorWarningFill color='crimson' size={50} />
                <Text fontSize='12px'>Please Cross check the details you provided before clicking submit</Text>
              </HStack>
              <Button w='150px' color='white' bg='#614285'>
                Submit
              </Button>
            </Flex>
          </Stack>
        </FormControl>
      </Box>
    </Stack>
  );
};

export const labeStyles = { m: '0', fontSize: '14px', color: '#333' };
