import React from "react";
import {
  Text,
  Box,
  ModalContent,
  Modal,
  ModalOverlay,
  ModalBody,
  ModalCloseButton,
  Flex,
  Divider,
  Image,
} from "@chakra-ui/react";
import { nanoid } from "@reduxjs/toolkit";

const UserValidationModal = ({ tenantData, isOpen, handleClose }) => {
  return (
    <Modal size="3xl" isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent p="20px">
        <Box paddingLeft="6">
          <Text fontSize="1.3em" fontWeight="500" color="#614285">
            More Information
          </Text>

          <ModalCloseButton _focus={{ boxShadow: "none" }} />
        </Box>
        <Divider />
        <ModalBody paddingLeft="0">
          <Box
            bg="#fff"
            display="flex"
            flexDirection={["column", "row", "row"]}
            justifyContent="space-between"
            textAlign={["center", "left"]}
            alignItems="center"
          >
            <Flex flexDirection="column">
              <Text p={5} mt={4} fontSize="0.8em" color="#949494">
                NAME:
              </Text>
              <Text px={5} fontSize="0.8em" mt={-4} color="#614285">
                {tenantData?.data?.result?.data?.tenant[0]?.tenantName ||
                  "not available"}
              </Text>

              <Text p={5} mt={4} fontSize="0.8em" color="#949494">
                PHONE NUMBER:
              </Text>
              <Text px={5} fontSize="0.8em" mt={-4} color="#614285">
                {tenantData?.data?.result?.data?.tenant[0]?.tenantPhone ||
                  "not available"}
              </Text>

              <Text p={5} mt={4} fontSize="0.8em" color="#949494">
                HOUSE UNIT NO:
              </Text>
              <Text px={5} fontSize="0.8em" mt={-4} color="#614285">
                Flat
                {tenantData?.data?.result?.data?.tenant[0]?.houseUnit ||
                  "not available"}{" "}
                {tenantData?.data?.result?.data?.tenant[0]?.streets
                  ?.streetName || "not available"}
              </Text>
            </Flex>

            <Image
              src={tenantData?.data?.result?.data?.tenant[0]?.profilePicture}
              borderRadius="50%"
              height="180px"
              width="180px"
              mt={20}
              ml={10}
            />
          </Box>
          <Box textAlign={["center", "left"]} bg="#fff">
            <Text p={5} fontSize="0.8em" mb={4} color="#949494">
              FAMILY MEMBER:
            </Text>
            <Flex flexDirection="column">
              <Flex flexDirection={["column", "row"]}>
                {tenantData?.data?.result?.data?.tenantFamily?.map((member) => {
                  return (
                    <Box mt={-5} key={nanoid()}>
                      <Text px={5} fontSize="1.3em" color="#A08CA8">
                        {member?.tenantName || "not available"}
                      </Text>
                      <Text px={5} fontSize="0.9em" color="#C4C4C4">
                        {member?.tenantPhone || "not available"}
                      </Text>
                    </Box>
                  );
                })}
              </Flex>
            </Flex>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default UserValidationModal;
