import { Button } from '@chakra-ui/react';

export const PrimaryBTN = ({ children, ...props }) => {
  return (
    <Button
      w='100%'
      bg='#614285'
      color='white'
      _hover={{ bg: '#614285' }}
      _focus={{ border: 'none' }}
      {...props}
    >
      {children}
    </Button>
  );
};
