import {
  Box,
  Button,
  HStack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import CautionAlertDialog from '../../../components/CautionAlertDialog';
import { CustomTable } from '../../../components/customized table/CustomTable';
import FullPageLoader from '../../../components/full-page-loader';
import { CreateVenue } from './components/CreateVenue';
import { eventTabs } from './components/eventsRequestData';
import { approveRequest, declineRequest, getSpecialRequests } from './service';
import { deleteVenue, getVenues } from './service/venue';
import { v4 } from 'uuid';
import { RejecetedAndApprovedEvents } from './components/tables/RejecetedAndApprovedEvents';
const advancedFormat = require('dayjs/plugin/advancedFormat');
dayjs.extend(advancedFormat);

const EventRequest = () => {
  const userId = useSelector((state) => state.auth.user);
  const [loading, setLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const [specialRequest, setSpecialRequest] = useState([]);
  const [venues, setVenues] = useState([]);
  const [refresh, setRefresh] = useState({});
  const [btnLoading, setBtnLoading] = useState(false);
  const [approveBtn, setApproveBtn] = useState(false);
  const [declineBtn, setDeclineBtn] = useState(false);

  useEffect(() => {
    getSpecialRequests(userId, setLoading, setSpecialRequest);
    getVenues(userId, setLoading, setVenues);
  }, [userId, refresh]);

  const pendingSpecialRequest = specialRequest?.filter(
    (req) => req?.requestStatus === 'pending'
  );

  const approvedAndRejectedEvents = specialRequest?.filter(
    (req) => req?.requestStatus !== 'pending'
  );

  const pendingRequetsTableHead = [
    {
      Header: 'S/N',
      accessor: 'id',
      Cell: ({ row }) => Number(row.id) + 1,
    },
    {
      Header: 'Event Name',
      accessor: 'subType',
    },
    {
      Header: 'Phone number',
      accessor: 'userId.tenantPhone',
    },
    {
      Header: 'Guest Request no',
      accessor: 'numberOfGuests',
    },
    {
      Header: 'Venue',
      accessor: 'address',
    },
    {
      Header: 'Date',
      accessor: 'dates[0]',
      Cell: ({ value }) => <Text>{dayjs(value).format('Do MMM')}</Text>,
    },
    {
      Header: 'Action',
      accessor: 'status',
      Cell: ({ row }) => (
        <HStack>
          <Button
            isLoading={approveBtn}
            _focus={{ border: 'none' }}
            size='xs'
            variant='outline'
            color='#3CDB4C'
            onClick={() => handleApproveRequest(row.original)}
          >
            Approve
          </Button>

          <Button
            isLoading={declineBtn}
            _focus={{ border: 'none' }}
            size='xs'
            variant='outline'
            color='crimson'
            onClick={() => handleRejectRequest(row.original)}
          >
            decline
          </Button>
        </HStack>
      ),
    },
  ];

  const handleApproveRequest = (request) => {
    const payload = {
      userId,
      requestId: request._id,
      requestStatus: 'approved',
    };

    approveRequest(payload, setApproveBtn).then(setRefresh);
  };

  const handleRejectRequest = (request) => {
    const payload = {
      userId,
      requestId: request._id,
      requestStatus: 'approved',
    };

    declineRequest(payload, setDeclineBtn).then(setRefresh);
  };

  const approvedAndRejectedTableHead = [
    {
      Header: 'S/N',
      accessor: 'id',
      Cell: ({ row }) => Number(row.id) + 1,
    },
    {
      Header: 'Event Name',
      accessor: 'subType',
    },
    {
      Header: 'Phone number',
      accessor: 'userId.tenantPhone',
    },
    {
      Header: 'Guest Request no',
      accessor: 'numberOfGuests',
    },
    {
      Header: 'Venue',
      accessor: 'address',
    },
    {
      Header: 'Date',
      accessor: 'dates[0]',
      Cell: ({ value }) => <Text>{dayjs(value).format('Do MMM')}</Text>,
    },
  ];

  const Deletebutton = ({ btnLoading }) => {
    return (
      <Button
        size='xs'
        isLoading={btnLoading}
        _focus={{ border: 'none' }}
        variant='outline'
        color='#F09091'
      >
        Delete
      </Button>
    );
  };

  const venueTable = [
    {
      Header: 'S/N',
      accessor: 'id',
      Cell: ({ row }) => Number(row.id) + 1,
    },
    {
      Header: 'Venue',
      accessor: 'name',
    },

    {
      Header: 'Delete Venue',
      accessor: 'dates[0]',
      Cell: ({ row }) => (
        <CautionAlertDialog
          size='xs'
          onContinue={() =>
            deleteVenue(userId, row.original._id, setBtnLoading, setRefresh)
          }
          icon={<Deletebutton btnLoading={btnLoading} />}
          cautionTitle='Delete Venue?'
          btnTitle='Delete'
        />
      ),
    },
  ];

  return loading ? (
    <FullPageLoader />
  ) : (
    <Box mt='50px'>
      <Tabs onChange={(e) => setCurrentTab(e)}>
        <TabList
          mx='6'
          display='flex'
          alignItems={'center'}
          justifyContent='space-between'
        >
          <HStack>
            {eventTabs?.map((tab) => (
              <Tab key={v4()} {...selectedTabStyles}>
                {tab}
              </Tab>
            ))}
          </HStack>
          {currentTab === 2 && (
            <CreateVenue setRefresh={() => setRefresh({})} />
          )}
        </TabList>

        <TabPanels>
          <TabPanel>
            <Box bg='#fff' p='5'>
              <CustomTable
                COLUMNS={pendingRequetsTableHead}
                DATA={pendingSpecialRequest}
              />
            </Box>
          </TabPanel>

          <TabPanel>
            <Box bg='#fff' p='5'>
              {/* <CustomTable
                COLUMNS={approvedAndRejectedTableHead}
                DATA={approvedAndRejectedEvents}
              /> */}
              <RejecetedAndApprovedEvents
                COLUMNS={approvedAndRejectedTableHead}
                DATA={approvedAndRejectedEvents}
              />
            </Box>
          </TabPanel>

          <TabPanel>
            <Box bg='#fff' p='5'>
              <CustomTable COLUMNS={venueTable} DATA={venues} />
            </Box>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default EventRequest;

const selectedTabStyles = {
  fontWeight: 600,
  color: 'gray.700',
  pb: '2',
  px: '5',
  _selected: { color: '#F09091', borderBottom: '3px solid #F09091 ' },
  _focus: { border: 'none' },
};
