import React, { useState } from "react"
import PhoneNumber from "./components/phoneNumber"
import EstateID from "./components/estateID"
import {
  Box,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react"
import { getEstateID } from "./service/index"
import { useSelector } from "react-redux"

function UserValidation() {
  const userId = useSelector(state => state.auth.user)
  const [loading, setLoading] = useState(false)
  const [estateIdTenantData, setEstateIdTenantData] = useState([])

  const estateIdHandleSubmit = async (e, estateId) => {
    e.preventDefault()
    setLoading(true)
    await getEstateID(estateId, userId, setEstateIdTenantData, setLoading)
    setLoading(false)
  }

  return (
    <Box bg="#fff" p={5} mt={5} height="89vh">
      <Tabs variant="line">
        <TabList borderBottomWidth="1px">
          <Tab
            _focus={{ boxShadow: "none" }}
            color="#1A1A1A"
            _selected={{
              borderBottom: "4px solid #614285",
              borderBottomRadius: "3px",
            }}
          >
            Phone number
          </Tab>
          {/* <Tab
            _focus={{ boxShadow: "none" }}
            color="#1A1A1A"
            _selected={{
              borderBottom: "4px solid #614285",
              borderBottomRadius: "3px",
            }}
          >
            Estate ID
          </Tab> */}
        </TabList>
        <TabPanels>
          <TabPanel p={["0", "0", "auto"]}>
            <Flex justifyContent="center">
              <PhoneNumber />
            </Flex>
          </TabPanel>

          <TabPanel p={["0", "0", "auto"]}>
            <Flex justifyContent="center">
              <EstateID
                estateIdHandleSubmit={estateIdHandleSubmit}
                estateIdTenantData={estateIdTenantData}
                loading={loading}
              />
            </Flex>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  )
}

export default UserValidation
