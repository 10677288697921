import http, { AUTH_ROUTES } from "../../../../services/api";
import { errorNotifier } from "../../../../components/NotificationHandler";

export const getPhoneNumber = async (payload, setTenantData, setLoading) => {
  try {
    const data = await http.get(
      AUTH_ROUTES.SECURITY_PHONE_NUMBER_VALIDATION(
        payload.phoneNumber,
        payload.userId
      )
    );

    setTenantData(data);
    setLoading(false);
  } catch (e) {
    setLoading(false);
    setTenantData(null);
    errorNotifier(e.response?.data?.result?.message);
  } finally {
    setLoading(false);
  }
};

export const getEstateID = async (
  payload,
  setEstateIdTenantData,
  setLoading
) => {
  try {
    const data = await http.get(
      AUTH_ROUTES.SECURITY_ESTATE_ID_VALIDATION(
        payload.estateId,
        payload.userId
      )
    );
    setEstateIdTenantData(data);
    setLoading(false);
  } catch (e) {
    setLoading(false);
    setEstateIdTenantData(null);
    errorNotifier(e.response?.data?.result?.message);
  } finally {
    setLoading(false);
  }
};
