import { Button, Box, Input, HStack } from '@chakra-ui/react';
import { useEffect, useRef } from 'react';
import FullPageLoader from '../../../../components/full-page-loader';
// import { QRCodeCheckin } from './qr scanner';

export const CheckInForm = ({
  handleGetGuest,
  loading,
  guestCode,
  setGuestCode,
}) => {
  const inputRef = useRef(null);

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  return loading ? (
    <FullPageLoader bg='#f2f2f2' h='100%' />
  ) : (
    <Box>
      <HStack gap='3'>
        <Input
          ref={inputRef}
          _focus={{ outline: 'none' }}
          fontSize='.9em'
          color='#614285'
          background='#fff'
          boxShadow='0px 3px 6px #00000029'
          placeholder='Enter Validation Code'
          size='lg'
          name='guestCode'
          value={guestCode}
          onChange={(e) => setGuestCode(e.target.value)}
        />
        {/* <QRCodeCheckin /> */}
      </HStack>

      <Button
        marginTop='20px'
        _focus={{ outline: 'none', color: '#614285' }}
        _hover={{
          outline: 'none',
          background: '#fff',
          color: '#614285',
          border: '1px solid #614285',
        }}
        isDisabled={guestCode === ''}
        color='#fff'
        bg='#614285'
        cursor='pointer'
        padding='20px 60px'
        fontSize='.9em'
        onClick={(e) => handleGetGuest(e)}
        isLoading={loading}
      >
        Submit
      </Button>
    </Box>
  );
};
