import { useState } from "react"
import { useSelector } from "react-redux"
import {
  Button,
  FormLabel,
  Input,
  Text,
  Textarea,
  Center,
} from "@chakra-ui/react"
import { createStreet } from "../pages/Manager-Portal/Street/services/street"

const CreateStreetForm = ({ onClose, onRefresh }) => {
  const userId = useSelector(state => state.auth.user)
  const [buttonLoading, setButtonLoading] = useState(false)
  const [formValues, setFormValues] = useState({
    streetName: "",
    streetDescription: "",
    userId: userId,
  })

  const handleChange = e => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value })
  }

  const handleSubmit = e => {
    e.preventDefault()
    createStreet(setButtonLoading, formValues, onClose).then(() => onRefresh())
  }
  return (
    <>
      <Center mb={4}>
        <Text color="#A08CA8" textAlign="center">
          Enter street name and description <br /> of the street to easily
          locate
        </Text>
      </Center>
      <form
        id="editStreet"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <FormLabel>Street Name</FormLabel>
        <Input
          value={formValues.streetName}
          onChange={handleChange}
          type="text"
          name="streetName"
          mb={2}
          variant="filled"
          required
        />
        <FormLabel>Street Description</FormLabel>
        <Textarea
          value={formValues.streetDescription}
          onChange={handleChange}
          type="text"
          name="streetDescription"
          mb={4}
          variant="filled"
          h={10}
          required
        />
        <Button
          isLoading={buttonLoading}
          bg="#8366A5"
          color="#fff"
          h={50}
          fontWeight="medium"
          _hover={{ bg: "#8366A5" }}
          type="submit"
          onClick={handleSubmit}
        >
          Create
        </Button>
      </form>
    </>
  )
}

export default CreateStreetForm
