import { errorNotifier } from "../../../components/NotificationHandler"
import http, { AUTH_ROUTES } from "../../../services/api"

export const loginUser = async (payload, setLoading) => {
  try {
    const {
      data: { result },
    } = await http.post(AUTH_ROUTES.LOGIN, payload)
    const token = result?.data?.token
    localStorage.setItem("11#221#", token)
    if (result?.data?.userType === "manager") {
      window.location.href = "/manager/dashboard"
    } else if (result?.data?.userType === "security") {
      window.location.href = "/security/dashboard"
    } else window.location.href = "/login"
  } catch (e) {
    setLoading(false)
    errorNotifier(e.response?.data?.result?.message)
  }
}
