import http, { AUTH_ROUTES } from '../../../../services/api';
import { DATA_ROWS } from '../../../../app/constants';
import { errorNotifier, successNotifier } from '../../../../components/NotificationHandler';

export const getNewsEvents = async (setLoading, userId, setNewsEvents, skip = 0, limit = DATA_ROWS.LIMIT) => {
  setLoading(true);
  const page = skip * limit;

  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.GET_NEW_EVENTS(userId, page, limit));

    setNewsEvents(data?.events);
  } catch (err) {
    setLoading(false);
  } finally {
    setLoading(false);
  }
};

export const newsEvent = async (setLoading, payload, setRefresh) => {
  setLoading(true);

  try {
    const { data } = await http.put(AUTH_ROUTES.MANGER_EDIT_NEWS_EVENT(payload.userId, payload.id), payload);

    successNotifier(data.result.message);
    setRefresh();
  } catch (err) {
    errorNotifier(err.response.data.message);
  } finally {
    setLoading(false);
  }
};

export const deleteNewsEvent = async (userId, eventId) => {
  try {
    const { data } = await http.delete(AUTH_ROUTES.MANAGER_DELETE_NEWS_EVENT(userId, eventId));
    successNotifier(data.result.message);
  } catch (err) {
    errorNotifier(err.response.data.message);
  }
};

export const createNewsEvent = async (payload, setLoading, setRefresh) => {
  setLoading(true);
  try {
    const {
      data: { result },
    } = await http.post(AUTH_ROUTES.MANAGER_CREATE_NEWS_EVENT, payload);
    successNotifier(result.message);

    setRefresh();
  } catch (error) {
    errorNotifier(error.response.data.result.message);
  } finally {
    setLoading(false);
  }
};
