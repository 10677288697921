import { Box, Tooltip } from '@chakra-ui/react';
import React from 'react';
import { FiEdit } from 'react-icons/fi';
import { Modal } from '../../../../components/modal';
import EditHouseForm from '../../../../components/EditHouseForm';

export const EditHouse = ({ initialData, setRefresh }) => {
  return (
    <Modal
      icon={
        <Tooltip label='edit house' aria-label='A tooltip'>
          <Box color='teal.300'>
            <FiEdit fontSize='1.2rem' />
          </Box>
        </Tooltip>
      }
      title='Edit House'
    >
      <EditHouseForm setRefresh={setRefresh} initialData={initialData} />
    </Modal>
  );
};
