import { Button, Flex, Input, Stack, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { setSpecialRequestPrice } from '../services/vehicle';
import { selectedTabStyles } from './EstateDueHeader';

export const VehiclePaymentModal = ({ sprecialRequestPrice, setRefresh }) => {
  const userId = useSelector((state) => state.auth.user);
  const [truckPrice, setTruckPrice] = useState(sprecialRequestPrice.truckFee);
  const [otherPrices, setOtherPrices] = useState(sprecialRequestPrice.tricycleFee);
  const [btnLoading, setBtnLoading] = useState(false);

  const handleSetPrice = () => {
    const payload = { userId, truckFee: truckPrice, tricycleFee: otherPrices };
    setSpecialRequestPrice(setBtnLoading, payload).then(setRefresh);
  };

  return (
    <Stack color='#3F3F3F'>
      <Text pb='5'>Estate Vehicle payment</Text>

      <Tabs isFitted>
        <TabList>
          <Tab {...selectedTabStyles} fontSize='14px'>
            Truck
          </Tab>
          <Tab {...selectedTabStyles} fontSize='14px'>
            Others (Tricycle)
          </Tab>
        </TabList>
        <Text py='5' textAlign='center' fontSize='12px'>
          Setup the amount to be paid for vehicle entering the estate
        </Text>
        <TabPanels>
          <TabPanel>
            <Input type='number' placeholder='Enter Amount for Trucks' value={truckPrice} onChange={(e) => setTruckPrice(e.target.value)} />
          </TabPanel>
          <TabPanel>
            <Input type='number' placeholder='Enter Amount for Tricycles' value={otherPrices} onChange={(e) => setOtherPrices(e.target.value)} />
          </TabPanel>
        </TabPanels>
      </Tabs>
      <Flex p='4' justify='center'>
        <Button bg='#614285' color='#fff' w='100%' isLoading={btnLoading} onClick={handleSetPrice}>
          save
        </Button>
      </Flex>
    </Stack>
  );
};
