import React from 'react';
import { Box } from '@chakra-ui/react';

const Card = ({ children, ...props }) => {
  return (
    <Box
      mx='15px'
      mt='12px'
      borderRadius='10px'
      flex={1}
      p='15px'
      bg='#DBDBDB'
      color='#614285'
      boxShadow='0px 3px 6px #0000003B'
      {...props}
    >
      {children}
    </Box>
  );
};

export default Card;
