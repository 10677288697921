import { Button, Flex, Heading, HStack, Img, Stack, Text } from '@chakra-ui/react';
import { AiOutlineClose } from 'react-icons/ai';
import { PrimaryBTN } from '../../../../components/CustomButtons';
import { CustomDrawer } from '../../../../components/CustomDrawer';
import { IssueMessaging } from './IssueMessaging';
import { nanoid } from '@reduxjs/toolkit';

export const ViewIssueDetails = ({ onClose, issue }) => {
  return (
    <Stack p='5'>
      <HStack justify='space-between' borderBottom='1px solid #eee' pb='8'>
        <Heading fontSize='1.2rem' color='#F09091'>
          Issue Report
        </Heading>

        <Button onClick={onClose} leftIcon={<AiOutlineClose />} size='xs' bg='gray.100' _focus={{ outline: 'none' }}>
          Close
        </Button>
      </HStack>
      <HStack justify='space-between' py='5'>
        <Text fontSize='12px' py='2' px='4' borderRadius='full' border='1px solid #FEE8E7' bg='#FAF6FF'>
          {issue?.issueType}
        </Text>
        <Flex>
          <CustomDrawer btnTitle={<PrimaryBTN size='sm'>Send Message</PrimaryBTN>}>
            <IssueMessaging issue={issue} />
          </CustomDrawer>
        </Flex>
      </HStack>

      <Text color='#614385' fontWeight='500' fontSize='1.5rem'>
        {issue?.title}
      </Text>

      <Text fontSize={'13px'}>{issue?.description}</Text>
      <Stack spacing='5'>
        {issue?.media?.map((img) => (
          <Img key={nanoid()} src={img} my='5' h='50vh' borderRadius='3px' shadow='md' objectFit={'cover'} alt='issue-image' />
        ))}
      </Stack>
    </Stack>
  );
};
