import { Box, Button, Heading, HStack, Input, Text } from '@chakra-ui/react';
import React, { useState } from 'react';
import { BsCheckCircle } from 'react-icons/bs';
import { ImCancelCircle } from 'react-icons/im';
import { useSelector } from 'react-redux';
import { btnStyles } from '../../../Security-Portal/GuestCheckIn/security-special-request/SpecialRequestCheckin';
import { specialRequestCheckout } from '../../../Security-Portal/GuestCheckIn/service/specialRequest';
import { SpecialRequestQRCodeCheckout } from './QRCodeSpecialRequestCheckout';

export const SpecialRequestCheckoutManager = ({ estateName }) => {
  const userId = useSelector((state) => state.auth.user);
  const [success, setSuccess] = useState(false);
  const [inviteCode, setInviteCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  const handleCheckout = () => {
    const payload = { userId, inviteCode };
    specialRequestCheckout(payload, setLoading, setMessage, setSuccess);
  };


  return (
    <Box
      bg='#f2f2f2'
      display='flex'
      height={['92vh', '94vh', '80vh']}
      justifyContent='center'
      alignItems='center'
    >
      <Box padding='10px' maxW='700px' color='#fff' textAlign='center'>
        <Heading marginBottom='10px' color='#614285'>
          GOODBYE FROM <br /> {estateName}
        </Heading>
        {!success && (
          <Text fontSize='.9em' marginBottom='30px' color='#614285'>
            Please enter the validation code sent to you to sign out from this
            estate
          </Text>
        )}

        {!success ? (
          <Box className='dd' marginTop='40px'>
            <HStack>
              <Input
                _focus={{ outline: 'none' }}
                fontSize='.9em'
                color='#614285'
                background='#fff'
                boxShadow='0px 3px 6px #00000029'
                placeholder='Enter Code'
                size='lg'
                name='code'
                value={inviteCode}
                onChange={(e) => setInviteCode(e.target.value)}
              />
              <SpecialRequestQRCodeCheckout />
            </HStack>
            <Button
              {...btnStyles}
              isLoading={loading}
              isDisabled={!Boolean(inviteCode)}
              onClick={handleCheckout}
            >
              Submit
            </Button>
          </Box>
        ) : (
          <Box
            position='relative'
            display='flex'
            justifyContent='center'
            alignItems='center'
            flexDirection='column'
            textAlign='center'
          >
            <Text color='#614285' fontSize='21px' mb={18}>
              {message}
            </Text>
            <BsCheckCircle
              color='#614285'
              size={90}
              cursor='pointer'
              onClick={() => setSuccess(false)}
            />
            <Box position='absolute' right='10px' top='-10px'>
              <ImCancelCircle
                fontSize='1.5em'
                color='#4a4a4a'
                cursor='pointer'
                onClick={() => setSuccess(false)}
              />
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};
