import React from 'react';
import { Td, Tr } from '@chakra-ui/react';

const TableRow = ({ houseNo, type }) => {
  return (
    <Tr borderBottom='10px solid #fff' bg='#E0D8E4'>
      <Td>{houseNo || 'Estate Name'}</Td>
      <Td>{type || 'emergency'}</Td>
    </Tr>
  );
};

export default TableRow;
