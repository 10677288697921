import {
  FormControl,
  FormLabel,
  Textarea,
  Input,
  Button,
  HStack,
  Select,
  Flex,
} from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { createHouse } from "../pages/Manager-Portal/House/service/createHouse";

const labelFonstSize = { fontSize: "13px" };

const CreateHouseForm = ({ streets, onClose, setRefresh }) => {
  const userId = useSelector((state) => state.auth.user);
  const [loading, setLoading] = useState(false);
  const [streetId, setStreetId] = useState("");
  const [designation, setDesignation] = useState("landlord");
  const [estateValue, setEstateValue] = useState({ houseNumber: "" });

  const initialValues = {
    name: "",
    phoneNumber: "",
    email: "",
    streetId,
    direction: "",
    userId,
  };

  const [formValues, setFormValues] = useState(initialValues);

  const handleEstateChange = (e) => {
    setEstateValue({ ...estateValue, [e.target.name]: e.target.value });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const { name, phoneNumber, email, direction } = formValues;

  const disable =
    Boolean(name) &&
    Boolean(phoneNumber) &&
    Boolean(email) &&
    Boolean(direction) &&
    Boolean(streetId);

  const handleCreate = () => {
    const { houseNumber } = estateValue;
    const payload = {
      ...formValues,
      estate: [{ houseNumber }],
      userId,
      streetId,
      designation,
    };

    createHouse(payload, setLoading, onClose, setRefresh);
  };

  useEffect(() => {
    setFormValues((initial) => ({ ...initial, estate: [estateValue] }));
  }, [estateValue]);

  return (
    <FormControl
      id="createCategories"
      display="flex"
      flexDirection="column"
      fontSize="13px"
    >
      <FormLabel {...labelFonstSize}>Designation</FormLabel>
      <Select
        mb="10px"
        onChange={(e) => setDesignation(e.target.value)}
        value={designation}
      >
        <option value="landlord">Landlord</option>
        <option value="tenant">Tenant</option>
        <option value="agent">Agent</option>
      </Select>
      <FormLabel {...labelFonstSize}>
        {designation?.charAt(0)?.toUpperCase() + designation.slice(1)} Name
      </FormLabel>
      <Input
        mb={4}
        value={formValues.name}
        variant="filled"
        type="text"
        name="name"
        onChange={handleChange}
      />

      <FormLabel {...labelFonstSize}>Phone Number</FormLabel>
      <Input
        mb={4}
        value={formValues.phoneNumber}
        variant="filled"
        type="number"
        name="phoneNumber"
        onChange={handleChange}
      />
      <FormLabel mt={4} {...labelFonstSize}>
        Email
      </FormLabel>
      <Input
        mb={4}
        variant="filled"
        value={formValues.email}
        type="text"
        name="email"
        onChange={handleChange}
      />
      <FormLabel mt={4} {...labelFonstSize}>
        Street
      </FormLabel>
      <Select
        placeholder="Select Street"
        name="streetId"
        onChange={(e) => setStreetId(e.target.value)}
        mb="20px"
      >
        {streets?.map((street) => (
          <option value={street?._id}>
            {street?.streetName || "No street available yet"}
          </option>
        ))}
      </Select>

      <HStack spacing={8}>
        <Flex direction="column" width={"100%"}>
          <FormLabel {...labelFonstSize}>House number</FormLabel>
          <Input
            width={"100%"}
            mb={4}
            variant="filled"
            value={estateValue?.houseNumber}
            type="text"
            name="houseNumber"
            onChange={handleEstateChange}
          />
        </Flex>
      </HStack>
      <FormLabel {...labelFonstSize}>Direction to the house</FormLabel>
      <Textarea
        mb={4}
        resize="none"
        value={formValues.direction}
        variant="filled"
        type="text"
        name="direction"
        onChange={handleChange}
      />

      <Button
        bg="#8366A5"
        color="#fff"
        _hover={{ bg: "#8366A5" }}
        _focus={{ boxShadow: "none" }}
        h={50}
        fontWeight="medium"
        onClick={handleCreate}
        isLoading={loading}
        disabled={!disable}
      >
        Create New House
      </Button>
    </FormControl>
  );
};

export default CreateHouseForm;
