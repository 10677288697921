import { errorNotifier, successNotifier } from '../../../../components/NotificationHandler';
import http, { AUTH_ROUTES } from '../../../../services/api';

export const createPoll = async (setLoading, payload) => {
  setLoading(true);

  try {
    await http.post(AUTH_ROUTES.MANAGER_CREATE_POLL, payload);

    successNotifier('Poll Created');
  } catch (e) {
    errorNotifier(e.response.result);
  } finally {
    setLoading(false);
  }
};

export const getAllPolls = async (userId, setLoading, setAllPolls) => {
  setLoading(true);
  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.MANAGER_GET_POLLS(userId));

    setAllPolls(data?.userPolls);
  } catch (e) {
    setLoading(false);
  } finally {
    setLoading(false);
  }
};

export const singlePollResult = async (setLoading, userId, pollId, setPoll) => {
  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.MANAGER_GET_SINGLE_POLL(userId, pollId));

    setPoll(data?.polls[0]);
  } catch (e) {
    setLoading(false);
  } finally {
    setLoading(false);
  }
};

export const setVisibility = async (setLoading, payload, onClose) => {
  setLoading(true);
  try {
    await http.put(AUTH_ROUTES.MANAGER_SET_POLL_VISIBILITY, payload);

    payload.resultVisibility ? successNotifier('Poll result Will now be Public') : successNotifier('Poll result Will hidden from Residents');
    onClose();
  } catch (e) {
    errorNotifier();
  } finally {
    setLoading(false);
  }
};
