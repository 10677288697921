import {
  Button,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { PrimaryBTN } from '../../../../components/CustomButtons';
import { createVenue } from '../service/venue';

export const CreateVenue = ({ setRefresh }) => {
  const userId = useSelector((state) => state.auth.user);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [venueName, setVenueName] = useState('');
  const [loading, setLoading] = useState(false);

  const handleCreateVanue = () => {
    const payload = { userId, name: venueName };

    createVenue(payload, setLoading, setRefresh);
  };

  return (
    <>
      <Button
        onClick={onOpen}
        bg='#614285'
        color='white'
        size='sm'
        _hover={{ bg: '#614285' }}
      >
        Create Venue
      </Button>
      <Modal size='md' isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent color='white'>
          <ModalHeader bg='#F09091' p={5}>
            Create Venue for events
          </ModalHeader>
          <ModalBody>
            <FormLabel>Enter The Venue name</FormLabel>
            <Input
              color='#333'
              placeholder='Central hall'
              value={venueName}
              onChange={(e) => setVenueName(e.target.value)}
            />
          </ModalBody>

          <ModalFooter>
            <PrimaryBTN isLoading={loading} my='10' onClick={handleCreateVanue}>
              Create Venue
            </PrimaryBTN>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
