import { Button, Flex, Input, Stack, Text } from '@chakra-ui/react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { setSpecialRequestPrice } from '../services/vehicle';

export const ContractorArtisanPaymentModal = ({ sprecialRequestPrice, setRefresh }) => {
  const userId = useSelector((state) => state.auth.user);
  const [contractorFee, setContractorFee] = useState(sprecialRequestPrice?.contractorFee);
  const [btnLoading, setBtnLoading] = useState(false);

  const handleSetPrice = () => {
    const payload = { userId, contractorFee };

    setSpecialRequestPrice(setBtnLoading, payload).then(setRefresh);
  };
  return (
    <Stack color='#3F3F3F'>
      <Text pb='5'>Estate Contractor/Artisan payment</Text>
      <Input placeholder='Enter Amount ' value={contractorFee} onChange={(e) => setContractorFee(e.target.value)} />

      <Flex py='4' justify='center'>
        <Button onClick={handleSetPrice} bg='#614285' color='#fff' w='100%' isLoading={btnLoading}>
          save
        </Button>
      </Flex>
    </Stack>
  );
};
