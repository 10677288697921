import React, { useState } from "react";

import {
  FormControl,
  FormLabel,
  Button,
  Input,
  Box,
  Heading,
  useDisclosure,
  ModalContent,
  Modal,
  ModalOverlay,
  ModalBody,
  ModalCloseButton,
  Flex,
  Select,
  Text,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";

import { useSelector } from "react-redux";
import { createResident } from "../service";
import { HiOutlineChevronDown } from "react-icons/hi";

const AddResidentModal = ({ onRefresh, houses, streets }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const getUserId = useSelector((state) => state?.auth?.user);
  const [userId] = useState(getUserId);
  const [allStreets, setAllStreets] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [display, setDisplay] = useState(false);

  const [, setSearchHouse] = useState(true);
  const initialValues = {
    tenantName: "",
    tenantPhone: "",
    email: "",
    userId,
    residentCode: "",
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [loading, setLoading] = useState(false);
  const INPUT_STYLE = {
    mb: "22px",
    fontSize: ".9em",
    color: "#4f4f4f",
  };
  const BTN_STYLE = {
    color: "#fff",
    bg: "#614285",
    _hover: { bg: "#462d64" },
    w: "100%",
    _focus: { borderRadius: "none" },
  };
  const LABEL_STYLE = { mb: "3px" };
  const FLEX_STYLE = { bg: "#FFFFFF" };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    const params = {
      ...formValues,
      userId,
      houseNumber: searchTerm,
      residentCode: "AWE20",
    };

    createResident(params, setLoading, onClose)
      .then(() => {
        setFormValues(initialValues);
        onRefresh(true);
      })
      .catch((e) => console.log("Error creating resident", e?.response));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  return (
    <Flex bg="#F6F6F6">
      <Button
        bg="#614285"
        color="#fff"
        size="sm"
        _focus={{ boxShadow: "none" }}
        _hover={{ bg: "#4b2f6a" }}
        onClick={onOpen}
      >
        Create Resident
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalCloseButton />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <Box color="#8366A5" margin="10px 0">
              <Heading fontSize="lg" mb={5}>
                Create Resident
              </Heading>
              <Flex {...FLEX_STYLE}>
                <FormControl as="form" onSubmit={handleSubmit} isRequired>
                  <FormLabel {...LABEL_STYLE}>Name</FormLabel>
                  <Input
                    type="text"
                    {...INPUT_STYLE}
                    name="tenantName"
                    value={formValues?.tenantName}
                    onChange={handleChange}
                  />
                  <FormLabel {...LABEL_STYLE}>Email</FormLabel>
                  <Input
                    type="email"
                    {...INPUT_STYLE}
                    name="email"
                    value={formValues?.email}
                    onChange={handleChange}
                  />

                  <FormLabel {...LABEL_STYLE}>Street</FormLabel>
                  <Select
                    placeholder="Select Street"
                    name="streetId"
                    onChange={(e) => setAllStreets(e.target.value)}
                    mb="20px"
                  >
                    {streets?.map((street, index) => (
                      <option key={index} value={street?.streetName}>
                        {street?.streetName || "No street available yet"}
                      </option>
                    ))}
                  </Select>

                  <FormLabel {...LABEL_STYLE}>House Address</FormLabel>
                  <InputGroup px="2 !important">
                    <Input
                      width="100%"
                      value={searchTerm}
                      onChange={(e) => {
                        setSearchTerm(e.target.value);
                        setDisplay(true);
                      }}
                    />
                    <InputRightElement
                      children={
                        <HiOutlineChevronDown
                          cursor="pointer"
                          onClick={() => setDisplay(!display)}
                          color="green.500"
                        />
                      }
                    />
                  </InputGroup>
                  <Box maxH={"200px"} overflowY="scroll">
                    {houses
                      ?.filter((house) =>
                        searchTerm
                          ? house?.estate?.[0]?.houseNumber
                              .toLowerCase()
                              .includes(searchTerm.toLowerCase())
                          : house
                      )
                      ?.map((data, i) => {
                        return (
                          data?.streetId?.streetName === allStreets &&
                          display && (
                            <Text
                              key={i}
                              cursor="pointer"
                              onClick={(e) => {
                                setSearchTerm(e.target.textContent);
                                setSearchHouse(false);
                                setDisplay(!display);
                              }}
                            >
                              {data?.estate?.[0]?.houseNumber}
                            </Text>
                          )
                        );
                      })}
                  </Box>

                  <FormLabel {...LABEL_STYLE} mt="15px">
                    Phone number
                  </FormLabel>
                  <Input
                    type="tel"
                    {...INPUT_STYLE}
                    name="tenantPhone"
                    value={formValues?.tenantPhone}
                    onChange={handleChange}
                  />

                  <Button isLoading={loading} type="Submit" {...BTN_STYLE}>
                    Submit
                  </Button>
                </FormControl>
              </Flex>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default AddResidentModal;
