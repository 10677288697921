import http, { AUTH_ROUTES } from '../../../../services/api';
import { errorNotifier, successNotifier } from '../../../../components/NotificationHandler';

export const deleteHouse = async (payload, id) => {
  try {
    await http.delete(AUTH_ROUTES.DELETE_HOUSE(payload.userId, id), payload);
    successNotifier('House Deleted Successfully');
  } catch (e) {
    errorNotifier(e.response?.data?.errors[0]?.msg);
  }
};
