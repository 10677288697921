import { Box, Button, Text, VStack } from '@chakra-ui/react';
import dayjs from 'dayjs';
import React from 'react';
import Message from '../../../../components/Message';

const ValidVisitDetails = ({ guest, handleSubmit, guestCode, onclickBack, confirmationMsg }) => {
  return confirmationMsg ? (
    <Message message={confirmationMsg} onclickBack={onclickBack} />
  ) : (
    <Box className='confirmation-data__inner' maxW='400px' fontSize='.85em' padding='10px'>
      <VStack spacing='20px' paddingTop='20px' fontSize={12}>
        <Text textAlign='start' display='grid' gridAutoFlow='column' gridTemplateColumns='60% auto' width='100%'>
          <span>Name:</span>
          <span>
            <b>{guest?.[0]?.tenants?.tenantName || 'Not Available'}</b>
          </span>
        </Text>

        <Text textAlign='start' display='grid' gridAutoFlow='column' gridTemplateColumns='60% auto' width='100%'>
          <span>Phone number:</span>
          <span>
            <b>{guest?.[0]?.tenants?.tenantPhone || 'Not Available'}</b>
          </span>
        </Text>
        <Text textAlign='start' display='grid' gridAutoFlow='column' gridTemplateColumns='60% auto' width='100%'>
          <span>Invitee name:</span>
          <span>
            <b>{guest?.[0]?.name || 'Not Available'}</b>
          </span>
        </Text>
        <Text textAlign='start' display='grid' gridAutoFlow='column' gridTemplateColumns='60% auto' width='100%'>
          <span>invitee phone no:</span>
          <span>
            <b>{guest?.[0]?.phone || 'Not Available'}</b>
          </span>
        </Text>
        <Text textAlign='start' display='grid' gridAutoFlow='column' gridTemplateColumns='60% auto' width='100%'>
          <span>Address:</span>
          <span>
            <b>{guest?.[0]?.streets?.streetName || 'Not Available'}</b>
          </span>
        </Text>
        <Text textAlign='start' display='grid' gridAutoFlow='column' gridTemplateColumns='60% auto' width='100%'>
          <span>Period of invitation:</span>
          <span>
            <b>
              {dayjs(guest?.[0]?.startDate || '').format('DD-MMM-YYYY')} to {dayjs(guest?.[0]?.endDate || '').format('DD-MMM-YYYY')}
            </b>
          </span>
        </Text>
      </VStack>

      <Button
        onClick={(e) => handleSubmit(e, guestCode)}
        marginTop='20px'
        marginBottom='20px'
        marginRight='20px'
        _focus={{ outline: 'none', color: '#614285' }}
        _hover={{
          outline: 'none',
          background: '#fff',
          color: '#614285',
          border: '1px solid #614285',
        }}
        isDisabled={guest === undefined}
        color='#fff'
        bg='#614285'
        cursor='pointer'
        padding='20px 60px'
        fontSize='.9em'
      >
        Submit
      </Button>
      <Button variant='outline' colorScheme='grey' fontSize='.9em' padding='20px 60px' onClick={() => onclickBack(true)}>
        Cancel
      </Button>
    </Box>
  );
};

export default ValidVisitDetails;
