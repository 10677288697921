import { Flex, FormControl, FormLabel, Img, Input, Stack, Textarea } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { PrimaryBTN } from '../../../../components/CustomButtons';
import { BsPlusCircleFill } from 'react-icons/bs';
import { errorNotifier } from '../../../../components/NotificationHandler';
import axios from 'axios';
import { newsEvent } from '../service/newsEvents';

export const EditNewsEvent = ({ data, setRefresh, onClose }) => {
  const userId = useSelector((state) => state.auth.user);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState(data.title);
  const [message, setMessage] = useState(data.content);
  const [newsImage, setNewsImage] = useState([]);
  let imgUrlsFromCloudinary = [];

  const handleImage = (e) => {
    if (!e.target.files) return;

    if (Array(...e.target.files).length > 5) {
      errorNotifier('You can only 5images at most');
      return;
    }

    if (e.target.files) {
      Array(...e.target.files).forEach((file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onloadend = () => {
          setNewsImage((prev) => [...prev, { imageUrl: reader.result, file }]);
        };
      });
    }
  };

  const handleEdit = (e, data) => {
    e.preventDefault();

    setLoading(true);
    if (newsImage.length === 0) {
      const payload = { title, content: message, userId, id: data._id };
      newsEvent(setLoading, payload, setRefresh, onClose);
      return;
    }

    const uploaders = newsImage.map((file) => {
      const formData = new FormData();

      formData.append('file', file.file);
      formData.append('upload_preset', 'akogwu-cloudinary');
      return axios
        .post('https://api.cloudinary.com/v1_1/akogwu/image/upload', formData, {
          headers: { 'X-Requested-With': 'XMLHttpRequest' },
        })
        .then((response) => {
          const data = response.data;
          const fileURL = data.secure_url; // You should store this URL for future references in your app

          imgUrlsFromCloudinary.push(fileURL);
        });
    });

    // Once all the files are uploaded
    axios.all(uploaders).then(() => {
      // ... perform after upload is successful operation

      const payload = {
        title,
        content: message,
        userId,
        id: data._id,
        image: imgUrlsFromCloudinary,
      };

      newsEvent(setLoading, payload, setRefresh, onClose);
    });
  };

  return (
    <FormControl>
      <Stack mb='10'>
        <FormLabel>
          Title
          <Input value={title} onChange={(e) => setTitle(e.target.value)} placeholder='Title of announcement' />
        </FormLabel>

        <FormLabel>
          Content
          <Textarea value={message} onChange={(e) => setMessage(e.target.value)} placeholder='there shall be...' />
        </FormLabel>
        <Flex>
          <FormLabel w='110px' htmlFor='image-picker' fontSize='12px'>
            upload a maximum of 5 images
            <BsPlusCircleFill color='#ff897a' size='1.5rem' />
          </FormLabel>
          <Flex wrap='wrap'>
            {newsImage?.map((img) => (
              <Img borderRadius='2px' shadow='md' w='70px' m='2' src={img.imageUrl} alt={img.file.name} />
            ))}
          </Flex>
        </Flex>
        <Input type='file' id='image-picker' multiple display='none' accept='image/*' onChange={handleImage} />
      </Stack>
      <PrimaryBTN isLoading={loading} loadingText='updating' onClick={(e) => handleEdit(e, data)}>
        Edit
      </PrimaryBTN>
    </FormControl>
  );
};
