import { Checkbox, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CustomDrawer } from '../../../../components/CustomDrawer';
import { CustomTable } from '../../../../components/customized table/CustomTable';
import FullPageLoader from '../../../../components/full-page-loader';
import { getIssueReports, updateIssue } from '../services/issueReports';
import { ViewIssueDetails } from './ViewIssueDetails';

export const IssueReports = () => {
  const userId = useSelector((state) => state.auth.user);
  const [refresh, setRefresh] = useState({});
  const [loading, setLoading] = useState(false);
  const [issueReports, setIssueReports] = useState([]);

  useEffect(() => {
    getIssueReports(userId, setLoading, setIssueReports);
  }, [userId, refresh]);

  const issuseReportsTableHead = [
    {
      Header: 'S/N',
      accessor: 'id',
      Cell: ({ row }) => Number(row.id) + 1,
    },
    {
      Header: 'Name',
      accessor: 'houses.name',
    },
    {
      Header: 'Phone no',
      accessor: 'tenants.tenantPhone',
    },
    {
      Header: 'Occupant Name',
      accessor: 'tenants.tenantName',
    },
    {
      Header: 'Address',
      accessor: 'streets.streetName',
    },
    {
      Header: 'Issue Type',
      accessor: 'issueType',
    },
    {
      Header: 'Report Details',
      accessor: 'datils',
      Cell: ({ row }) => (
        <CustomDrawer btnProps={{ as: 'p', textDecoration: 'underline', cursor: 'pointer' }} btnTitle='View'>
          <ViewIssueDetails issue={row.original} />
        </CustomDrawer>
      ),
    },
    {
      Header: 'Issue Status',
      accessor: 'status',
      Cell: ({ row }) => (
        <Checkbox
          colorScheme={'teal'}
          color={row.original.status === 'pending' ? 'crimson' : 'teal'}
          isChecked={row.original.status === 'resolved'}
          fontSize='sm'
          onChange={() => updateIssueReport(row)}
        >
          <Text as='span' fontSize='12px'>
            {row.original.status}
          </Text>
        </Checkbox>
      ),
    },
  ];

  const updateIssueReport = (row) => {
    const payload = { id: row.original._id, userId, status: 'resolved' };
    updateIssue(payload).then(() => setRefresh({}));
  };

  return loading ? <FullPageLoader /> : <CustomTable COLUMNS={issuseReportsTableHead} DATA={issueReports} />;
};
