import { Box } from '@chakra-ui/react';
import FullPageLoader from '../../../../components/full-page-loader';
import ValidVisitDetails from '../../GuestCheckIn/component/ValidVisitDetails';

export const ValidationConfirmation = ({
  loading,
  handleSubmit,
  guestCode,
  onclickBack,
  invitedGuest,
  setConfirmationMsg,
  confirmationMsg,
}) => {
  const guest = invitedGuest?.guests?.filter(
    (guest) => guest?.guestCode === guestCode
  );

  return loading ? (
    <FullPageLoader bg='#f2f2f2' h='100%' />
  ) : (
    <Box
      className='confirmation-data'
      bg='#fff'
      paddingLeft='35px'
      color='#4f4d4d'
      borderRadius='3px'
    >
      <ValidVisitDetails
        setConfirmationMsg={setConfirmationMsg}
        confirmationMsg={confirmationMsg}
        guest={guest}
        handleSubmit={handleSubmit}
        guestCode={guestCode}
        onclickBack={onclickBack}
      />
      {/* {guest === undefined ? (
        <Message
          onclickBack={onclickBack}
          message="Invalid code, Please confirm the code again"
        />
      ) :
      guest?.[0]?.status === "IN" ? (
        <Box
          className="confirmation-data__inner"
          maxW="400px"
          fontSize=".85em"
          padding="10px"
        >
          <VStack spacing="20px" paddingTop="20px" fontSize={12}>
            <Text
              textAlign="start"
              display="grid"
              gridAutoFlow="column"
              gridTemplateColumns="60% auto"
              width="100%"
            >
              <span>Name:</span>
              <span>
                <b>{guest?.[0]?.tenants?.tenantName || "Not Available"}</b>
              </span>
            </Text>

            <Text
              textAlign="start"
              display="grid"
              gridAutoFlow="column"
              gridTemplateColumns="60% auto"
              width="100%"
            >
              <span>Phone number:</span>
              <span>
                <b>{guest?.[0]?.tenants?.tenantPhone || "Not Available"}</b>
              </span>
            </Text>
            <Text
              textAlign="start"
              display="grid"
              gridAutoFlow="column"
              gridTemplateColumns="60% auto"
              width="100%"
            >
              <span>Invitee name:</span>
              <span>
                <b>{guest?.[0]?.name || "Not Available"}</b>
              </span>
            </Text>
            <Text
              textAlign="start"
              display="grid"
              gridAutoFlow="column"
              gridTemplateColumns="60% auto"
              width="100%"
            >
              <span>invitee phone no:</span>
              <span>
                <b>{guest?.[0]?.phone || "Not Available"}</b>
              </span>
            </Text>
            <Text
              textAlign="start"
              display="grid"
              gridAutoFlow="column"
              gridTemplateColumns="60% auto"
              width="100%"
            >
              <span>Address:</span>
              <span>
                <b>{guest?.[0]?.streets?.streetName || "Not Available"}</b>
              </span>
            </Text>
            <Text
              textAlign="start"
              display="grid"
              gridAutoFlow="column"
              gridTemplateColumns="60% auto"
              width="100%"
            >
              <span>Period of invitation:</span>
              <span>
                <b>
                  {dayjs(guest?.[0]?.startDate || "").format("DD-MMM-YYYY")} to{" "}
                  {dayjs(guest?.[0]?.endDate || "").format("DD-MMM-YYYY")}
                </b>
              </span>
            </Text>
          </VStack>

          <Button
            onClick={(e) => handleSubmit(e, guestCode)}
            marginTop="20px"
            marginBottom="20px"
            marginRight="20px"
            _focus={{ outline: "none", color: "#614285" }}
            _hover={{
              outline: "none",
              background: "#fff",
              color: "#614285",
              border: "1px solid #614285",
            }}
            isDisabled={guest === undefined}
            color="#fff"
            bg="#614285"
            cursor="pointer"
            padding="20px 60px"
            fontSize=".9em"
          >
            Submit
          </Button>
          <Button
            variant="outline"
            colorScheme="grey"
            fontSize=".9em"
            padding="20px 60px"
            onClick={() => onclickBack(true)}
          >
            Cancel
          </Button>
        </Box>
      ) : guest[0]?.status === "OUT" ? (
        <Message onclickBack={onclickBack} message="Guest already signed Out" />
      ) : 
      dayjs(Date.now()) < dayjs(guest?.[0]?.endDate) &&
        guest?.[0]?.status === "PENDING" ? (
        <Message
          onclickBack={onclickBack}
          message="This Guest have not signed in yet"
        />
      ) :
      dayjs(Date.now()) > dayjs(guest?.[0]?.endDate) ? (
        <Message onclickBack={onclickBack} message="This Code has Expired" /> 
      ) : (
       
        ""
      )}*/}
    </Box>
  );
};
