import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { PUBLIC_PATHS } from "./constants";
import { Flex, Stack } from "@chakra-ui/react";
import { Login } from "../components/login";
const { LOGIN } = PUBLIC_PATHS;

export function UnAuthenticatedApp() {
  return (
    <BrowserRouter>
      <Flex direction="column" height="100vh">
        <Stack flex="1">
          <Switch>
            <Route path={LOGIN} exact component={Login} />
          </Switch>
          <Redirect from="/*" to={LOGIN} />
        </Stack>
      </Flex>
    </BrowserRouter>
  );
}
