import { Box } from '@chakra-ui/react';
import React from 'react';
import { MdOutlineDeleteOutline } from 'react-icons/md';
import { useSelector } from 'react-redux';
import CautionAlertDialog from '../../../../components/CautionAlertDialog';
import { deleteHouse } from '../service/deleteHouse';

export const DeleteHouse = ({ house, setRefresh }) => {
  const userId = useSelector((state) => state?.auth?.user);
  const handleDelete = (id) => {
    const payload = {
      userId,
    };
    deleteHouse(payload, id).then(setRefresh);
  };
  return (
    <>
      <CautionAlertDialog
        onContinue={() => handleDelete(house?._id)}
        size='xs'
        icon={
          <Box color='red.300'>
            <MdOutlineDeleteOutline fontSize='1.2rem' />
          </Box>
        }
        cautionTitle='Are you sure you want to delete this house?'
      />
    </>
  );
};
