import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getEstateDue } from './services/estateDueApis';
import FullPageLoader from '../../../components/full-page-loader';
import { estatePaymentsTab, selectedTabStyles } from './estateDueComponents/EstateDueHeader';
import AddDuesModal from './estateDueComponents/AddDuesModal';
import { Box, Flex, HStack, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react';
import { VehiclePaymentModal } from './estateDueComponents/VehiclePaymentModal';
import { ContractorArtisanPaymentModal } from './estateDueComponents/ContractorArtisanPaymentModal';
import GenericModal from '../../../components/modal/GenericModal';
import { getSpecialRequestprices } from './services/vehicle';
import { nanoid } from '@reduxjs/toolkit';
import { EstateDueTable } from './components/EstateDueTable';
import { VehicularPayment } from './components/VehicularPayment';
import { ContractorArtisanPayment } from './components/ContractorArtisanPayment';
import { EstateWallet } from './components/EstateWallet';

const EstateDue = () => {
  const userId = useSelector((state) => state.auth.user);
  const [loading, setLoading] = useState(true);
  const [currentTab, setCurrentTab] = useState(0);
  const [refresh, setRefresh] = useState({});
  const [dueAmount, setDueAmount] = useState([]);
  const [inputAmount, setInputAmount] = useState('');
  const [sprecialRequestPrice, setSprecialRequestPrice] = useState({});

  useEffect(() => {
    setInputAmount(dueAmount?.estateDue);
  }, [dueAmount]);

  useEffect(() => {
    getEstateDue(setDueAmount, userId);
    getSpecialRequestprices(setLoading, userId, setSprecialRequestPrice);
  }, [userId, setDueAmount, refresh]);

  const setInputAmountHandler = (value) => {
    setInputAmount(value);
  };

  return loading ? (
    <FullPageLoader />
  ) : (
    <Box mt='50px'>
      <EstateWallet />
      <Tabs onChange={(t) => setCurrentTab(t)}>
        <TabList mx='6' display='flex' alignItems={'center'} justifyContent='space-between'>
          {estatePaymentsTab?.map((tab) => (
            <Tab {...selectedTabStyles} key={nanoid()}>
              {tab}
            </Tab>
          ))}

          <Flex justifyContent='flex-end' align='center'>
            <Text fontSize='13px' fontWeight='600' mr='2'>
              {currentTab === 0 && `Estate Due: ₦ ${dueAmount?.estateDue?.toLocaleString()}`}
              {currentTab === 1 && (
                <HStack fontSize='13px' fontWeight='600'>
                  <Text>Truck: {sprecialRequestPrice?.truckFee}</Text>
                  <Text>Others: {sprecialRequestPrice?.tricycleFee}</Text>
                </HStack>
              )}
              {currentTab === 2 && <Text fontSize='13px'>Per Contractor: {sprecialRequestPrice?.contractorFee}</Text>}
            </Text>
            {currentTab === 0 ? (
              <AddDuesModal inputAmount={inputAmount} setInputAmount={setInputAmountHandler} dueAmount={dueAmount} update={() => setRefresh({})} />
            ) : (
              <>
                {currentTab === 1 && (
                  <GenericModal size='md' btnTitle='Set Amount' buttonProps={{ bg: '#614285', size: 'sm' }}>
                    <VehiclePaymentModal setRefresh={() => setRefresh({})} sprecialRequestPrice={sprecialRequestPrice} />
                  </GenericModal>
                )}

                {currentTab === 2 && (
                  <GenericModal size='md' btnTitle='Set Amount' buttonProps={{ bg: '#614285', size: 'sm' }}>
                    <ContractorArtisanPaymentModal setRefresh={() => setRefresh({})} sprecialRequestPrice={sprecialRequestPrice} />
                  </GenericModal>
                )}
              </>
            )}
          </Flex>
        </TabList>

        <TabPanels>
          <TabPanel>
            <Box bg='#fff' p='5' borderRadius='3px'>
              <EstateDueTable />
            </Box>
          </TabPanel>
          <TabPanel>
            <Box bg='#fff' p='5' borderRadius='3px'>
              <VehicularPayment />
            </Box>
          </TabPanel>
          <TabPanel>
            <Box bg='#fff' p='5' borderRadius='3px'>
              <ContractorArtisanPayment />
            </Box>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default EstateDue;
