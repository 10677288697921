import http, { AUTH_ROUTES } from '../../../../services/api';

export const getChatMessages = async (issueID, userId, setLoading, setChatMessages) => {
  setLoading(true);
  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.GET_CHAT_MESSAGE(issueID, userId));

    setChatMessages(data?.issueChats);
  } catch (e) {
    setLoading(false);
  } finally {
    setLoading(false);
  }
};

export const sendChatMessage = async (payload, setLoading, setRefresh, setTypedMessage) => {
  setLoading(true);
  try {
    await http.post(AUTH_ROUTES.SEND_CHAT_MESSAGE, payload);

    setRefresh({});
    setTypedMessage('');
  } catch (e) {
    setLoading(false);
  } finally {
    setLoading(false);
  }
};
