import { Button, Flex } from '@chakra-ui/react';
import { WalletCard } from './WalletCard';
import { MdOutlineRealEstateAgent, MdConstruction } from 'react-icons/md';
import { FiTruck } from 'react-icons/fi';
import { PROTECTED_PATHS } from '../../../../app/constants';
import { useHistory } from 'react-router-dom';
import { PayoutRequestForm } from './PayoutRequestForm';
import { CustomDrawer } from '../../../../components/CustomDrawer';

const { MANAGER_PAYOUT_HISTORY } = PROTECTED_PATHS;

export const EstateWallet = () => {
  const history = useHistory();

  return (
    <Flex pb='5' align='center'>
      <WalletCard title='Estate Due' amount='N 12,000' icon={<MdOutlineRealEstateAgent size={30} color='#ddd' />} bgColor='#EE4A4AE0' />
      <WalletCard title='Vehicle Payments' amount='N 1,000' icon={<FiTruck size={30} color='#ddd' />} bgColor='#A08CA8' />
      <WalletCard title='Contactors/Artisans' amount='N 18,500' icon={<MdConstruction size={30} color='#ddd' />} bgColor='#5FCD66' />

      <Flex ml='5rem' flex='1' maxW='200px' direction={['column']} justify={['space-between']} gap={5}>
        <CustomDrawer
          size='md'
          btnProps={{ _focus: { bg: '#66448e' }, _hover: { outline: 'none' }, color: 'white', as: Button, bg: '#66448E' }}
          btnTitle={'Request Payout'}
        >
          <PayoutRequestForm />
        </CustomDrawer>

        <Button h='40px' borderColor='#ccc' size='sm' variant='outline' onClick={() => history.push(MANAGER_PAYOUT_HISTORY)}>
          Payment history
        </Button>
      </Flex>
    </Flex>
  );
};
