import { Text } from '@chakra-ui/react';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CustomTable } from '../../../../components/customized table/CustomTable';
import { getEstateDues } from '../services/estateDueApis';

export const EstateDueTable = () => {
  const [estateDues, setEstateDues] = useState([]);
  const userId = useSelector((state) => state.auth.user);
  const [, setLoading] = useState(true);

  useEffect(() => {
    getEstateDues(setLoading, userId, setEstateDues);
  }, [userId]);

  const estateDueHeader = [
    {
      Header: 'S/N',
      accessor: 'id',
      Cell: ({ row }) => Number(row.id) + 1, //RDT provides index by default
    },
    {
      Header: 'House address',
      accessor: 'houses.estate[0].houseNumber',
    },
    {
      Header: 'Phone no',
      accessor: 'tenants.tenantPhone',
    },
    {
      Header: 'Occupant Name',
      accessor: 'tenants.tenantName',
    },
    {
      Header: 'Email',
      accessor: 'tenants.email',
    },
    // {
    //   Header: 'Unit no',
    //   accessor: 'type',
    // },
    {
      Header: 'Amount Paid',
      accessor: 'amountPaid',
    },
    {
      Header: 'Payment date',
      accessor: 'startDate',
      Cell: ({ value }) => <Text as='div'> {dayjs(value).format('DD-MMM-YYYY')}</Text>,
    },
    {
      Header: 'Next Payment Month',
      accessor: 'endDate',
      Cell: ({ value }) => <Text as='div'> {dayjs(value).format('DD-MMM-YYYY')}</Text>,
    },
  ];
  return <CustomTable COLUMNS={estateDueHeader} DATA={estateDues} />;
};
