import React from "react";
import { Box, Text, Flex } from "@chakra-ui/layout";
import { ImCancelCircle } from "react-icons/im";

export default function Message({ message, onclickBack }) {
  return (
    <Box p={3}>
      <Flex justifyContent="flex-end">
        <ImCancelCircle
          color="#614285"
          cursor="pointer"
          onClick={() => onclickBack(true)}
        />
      </Flex>
      <Box padding="30px 50px 50px 30px" color="#a08ca8" fontWeight="bold">
        <Text className="error">{message}</Text>
      </Box>
    </Box>
  );
}
