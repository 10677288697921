import { BellSvg } from '../../svgs/BellSvg';
import { CheckSvg } from '../../svgs/CheckSvg';
import { ErrorSvg } from '../../svgs/ErrorSvg';
import { HomeSvg } from '../../svgs/HomeSvg';
import { UserSvg } from '../../svgs/UserSvg';
import { PROTECTED_PATHS } from '../../app/constants';
import { HomeAltSvg } from '../../svgs/HomeAltSvg';
import { SearchSvg } from '../../svgs/SearchSvg';
import { UserListSvg } from '../../svgs/UserListSvg';
import { CreditCardSvg } from '../../svgs/CreditCardSvg';
import { LogOutSvg } from '../../svgs/LogOutSvg';
import { HiOutlineShieldCheck } from 'react-icons/hi';
import { GiNewspaper, GiVote } from 'react-icons/gi';
import { MdEventAvailable } from 'react-icons/md';

const {
  MANAGER_DASHBOARD,
  SECURITY_DASHBOARD,

  MANAGER_GUEST_LIST,
  SECURITY_GUEST_LIST,

  MANAGER_EMERGENCY,
  SECURITY_EMERGENCY,

  MANAGER_NOTIFICATION,
  // SECURITY_NOTIFICATION,

  MANAGER_GUEST_CHECK_IN,
  SECURITY_GUEST_CHECK_IN,

  MANAGER_GUEST_CHECK_OUT,
  SECURITY_GUEST_CHECK_OUT,
  SECURITY_USER_VALIDATION,

  MANAGER_STREET,
  MANAGER_HOUSE,
  MANAGER_RESIDENT,
  MANAGER_ESTATE_DUE,

  MANAGER_NEWS_EVENTS,
  MANGER_EVENT_REQUESTS,
  MANAGER_POLLS,
} = PROTECTED_PATHS;

export const MANAGER_NAV_ITEMS = [
  { title: 'Dashboard', to: MANAGER_DASHBOARD, icon: HomeSvg },
  { title: 'Guest Lists', to: MANAGER_GUEST_LIST, icon: UserSvg },
  { title: 'Emergencies', to: MANAGER_EMERGENCY, icon: ErrorSvg },
  { title: 'Notifications', to: MANAGER_NOTIFICATION, icon: BellSvg },
  { title: 'CheckIn', to: MANAGER_GUEST_CHECK_IN, icon: CheckSvg },
  { title: 'CheckOut', to: MANAGER_GUEST_CHECK_OUT, icon: LogOutSvg },
  { title: 'Streets', to: MANAGER_STREET, icon: SearchSvg },
  { title: 'Houses', to: MANAGER_HOUSE, icon: HomeAltSvg },
  { title: 'Residents', to: MANAGER_RESIDENT, icon: UserListSvg },
  { title: 'Estate Payments', to: MANAGER_ESTATE_DUE, icon: CreditCardSvg },
  {
    title: 'Event Requests',
    to: MANGER_EVENT_REQUESTS,
    icon: MdEventAvailable,
  },
  { title: 'News/Events', to: MANAGER_NEWS_EVENTS, icon: GiNewspaper },
  { title: 'Polls', to: MANAGER_POLLS, icon: GiVote },
];

export const SECURITY_PORTAL_NAV_ITEMS = [
  { title: 'Dashboard', to: SECURITY_DASHBOARD, icon: HomeSvg },
  { title: 'Guest Lists', to: SECURITY_GUEST_LIST, icon: UserSvg },
  { title: 'Emergencies', to: SECURITY_EMERGENCY, icon: ErrorSvg },
  // { title: "Notifications", to: SECURITY_NOTIFICATION, icon: BellSvg },
  { title: 'CheckIn', to: SECURITY_GUEST_CHECK_IN, icon: CheckSvg },
  { title: 'CheckOut', to: SECURITY_GUEST_CHECK_OUT, icon: LogOutSvg },
  {
    title: 'User Validation',
    to: SECURITY_USER_VALIDATION,
    icon: HiOutlineShieldCheck,
  },
];
