import { Box, Flex, HStack, Text } from '@chakra-ui/react';

export const WalletCard = ({ bgColor, title, amount, icon }) => {
  return (
    <Box bg={bgColor} borderRadius='10' p={4} pr={7} m='10px' color='#eee' w='270px'>
      <HStack justify='space-between'>
        <Text fontWeight='600' fontSize='15'>
          {title}
        </Text>
        <Box mt={4}>{icon}</Box>
      </HStack>

      <Flex mt={3}>
        <Text fontSize='30px' fontWeight='600'>
          {amount}
        </Text>
      </Flex>
    </Box>
  );
};
