import React from "react";
import { Bar } from "react-chartjs-2";

const AlarmChart = ({ data }) => {
  const data3 = {
    labels: ["week 1", "week 2", "week 3", "week 4"],
    datasets: [
      {
        label: "Health",
        data: [data?.health],
        backgroundColor: "#3CDB4C",
        borderWidth: 1,
        barThickness: 15,
        minBarLength: 2,
        barPercentage: 5.0,
      },
      {
        label: "Theft",
        data: [data?.theft],
        backgroundColor: "#FF001A",
        borderWidth: 1,
        barThickness: 15,
        minBarLength: 2,
        barPercentage: 5.0,
      },
      {
        label: "fire",
        data: [data?.fire],
        backgroundColor: "#FB3578",
        borderWidth: 1,
        barThickness: 15,
        minBarLength: 2,
        barPercentage: 5.0,
      },
      {
        label: "others",
        data: [data?.others],
        backgroundColor: "#CD845F",
        borderWidth: 1,
        barThickness: 15,
        minBarLength: 2,
        barPercentage: 5.0,
      },
    ],
  };

  const options = {
    scales: {
      y: {
        max: 100,
        ticks: {
          // Include a percentage sign in the ticks
          callback: function (value) {
            return value + "%";
          },
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: "top",
      },
    },
  };

  return <Bar data={data3} options={options} />;
};

export default AlarmChart;
