import http, { AUTH_ROUTES } from '../../../../services/api';
import { errorNotifier, successNotifier } from '../../../../components/NotificationHandler';
import { DATA_ROWS } from '../../../../app/constants';

//GET ESTATE DUES HANDLER / LIST OF TENANTS THAT HAVE PAID THEIR ESTATE DUES
export const getEstateDues = async (setLoading, userId, setEstateDues, skip = 0, limit = DATA_ROWS.LIMIT) => {
  const page = skip * limit;
  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.GET_ESTATE_DUES(userId, page, limit));

    setEstateDues(data.estateDues);
    setLoading(false);
  } catch (e) {
    setEstateDues([]);
    setLoading(false);
  }
};

//UPDATE ESTATE DUE AMOUNT
export const addEstateDueAmount = async (setBtnLoading, payload, onClose) => {
  try {
    await http.post(AUTH_ROUTES.POST_ESTATE_DUE, payload);
    setBtnLoading(false);
    successNotifier('Estate due successfully updated');
    onClose();
  } catch (e) {
    errorNotifier(e);
    setBtnLoading(false);
  }
};

//GET CURRENT ESTATE DUE AMOUNT
export const getEstateDue = async (setEstateDue, userId) => {
  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.GET_ESTATE_INFO(userId));
    setEstateDue(data);
  } catch (e) {
    // errorNotifier(e.response?.data.result);
  }
};
