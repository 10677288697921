import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@chakra-ui/react';
import FullPageLoader from '../../../components/full-page-loader';
import { getGuestList } from './service/guestListApi';
import { CustomTable } from '../../../components/customized table/CustomTable';
import { guestListTableColumn } from '../../Manager-Portal/GuestList/component/guestListTableColumn';

export const Security_GuestList = () => {
  // const { onOpen } = useDisclosure();
  const userId = useSelector((state) => state.auth.user);
  const [loading, setLoading] = useState(true);
  const [guestList, setGuestList] = useState([]);
  // const [filteredData, setFilteredData] = useState(guestList);

  // const handleRowClick = () => {
  //   onOpen(true);
  // };

  useEffect(() => {
    getGuestList(setLoading, userId, setGuestList);
  }, [userId]);

  // useEffect(() => {
  //   setFilteredData(guestList);
  // }, [guestList]);

  return loading ? (
    <FullPageLoader />
  ) : (
    <Box
      padding='20px 30px'
      paddingRight='60px'
      bg='#fff'
      borderRadius='5px'
      margin='20px'
      marginTop='50px'
    >
      {/* <Flex justifyContent='flex-end'>
        <GuestListModal
          data={guestList}
          setFilter={(data) => setFilteredData(data)}
        />
      </Flex> */}
      <CustomTable
        COLUMNS={guestListTableColumn}
        DATA={guestList}
        innerSearch
      />
    </Box>
  );
};
