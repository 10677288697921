import octagonImg from "../../../../assets/feather-alert-octagon.svg"
import usersImg from "../../../../assets/feather-users.svg"
import userImg from "../../../../assets/feather-user.svg"
import homeImg from "../../../../assets/feather-home.svg"

export const DashboardArray = [
  {
    id: 1,
    title: "Total Guests",
    background: "#F09091",
    value: "0",
    key: "totalGuests",
    image: usersImg,
  },
  {
    id: 2,
    title: "Emergency Requests",
    background: "#CD845F",
    value: "0",
    key: "totalEmergencyRequests",
    image: octagonImg,
  },
  {
    id: 3,
    title: "Total Residents",
    background: "#A08CA8",
    value: "0",
    key: "totalUsers",
    image: userImg,
  },
  {
    id: 4,
    title: "Total Houses",
    background: "#5FCD66",
    value: 0,
    key: "totalHouses",
    image: homeImg,
  },
  {
    id: 5,
    value: "0",
    key: "guestIn",
  },
  {
    id: 6,
    value: 0,
    key: "guestOut",
  },
]
