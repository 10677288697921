import { Input, Text } from '@chakra-ui/react';
import React, { useState } from 'react';
import Card from './Card';
import MyButton from './MyButton';

const GuestCodeValidationCard = ({ handleClick, loading }) => {
  const [guestCode, setGuestCode] = useState("");

   return (
     <Card>
       <Text as="h3" fontSize="15px" fontWeight="bold">
         Guest Code Validation
       </Text>
       <Text mb="8px" fontSize="12px">
         Enter code
       </Text>
       <Input
         size="sm"
         borderRadius="6px"
         mb="10px"
         border="1px solid #614285;"
         _hover={{ border: "1px solid #614285" }}
         borderColor="#614285"
         value={guestCode}
         onChange={(e) => setGuestCode(e.target.value)}
       />
       <MyButton
         px="35px"
         bg="#A08CA8"
         color="#fff"
         size="sm"
         _hover={{ bg: "#A08CA8" }}
         focus="none"
         mybtnCont="right"
         isLoading={loading}
         onClick={() => handleClick(guestCode, setGuestCode)}
       />
     </Card>
   ); 

};

export default GuestCodeValidationCard;
