import http, { AUTH_ROUTES } from "../../../../services/api";
// import { errorNotifier } from "../../../../components/NotificationHandler";

export const getStreets = async (userId, setStreets) => {
  try {
    const {
      data: {
        result: {
          data: { streets },
        },
      },
    } = await http.get(AUTH_ROUTES.GET_STREETS(userId, setStreets));
    setStreets(streets);
  } catch (e) {
    // errorNotifier(e?.response?.data.result);
  }
};
