import React from 'react';
import { Box, Button } from '@chakra-ui/react';

const MyButton = ({ click, mybtnCont, ...props }) => {
  return (
    <Box w='100%' textAlign={mybtnCont}>
      <Button
        onClick={click}
        className='btn'
        variant='solid'
        color='#A08CA8'
        fontSize='14px'
        borderRadius='6px'
        {...props}
      >
        Submit
      </Button>
    </Box>
  );
};

export default MyButton;
