export const partyEventRequest = [
  'Name',
  'Phone number',
  'Guest Requests',
  'Venue',
  'Date',
  'Action',
];
export const rejectedAndApprovedEvents = [
  'Name',
  'Phone number',
  'Guest Requests',
  'Venue',
  'Date',
];

export const estateVenue = ['Venue', 'Action'];

export const eventTabs = [
  'Pending Requests',
  'Approved/Rejected Requests',
  'Estate Venues',
];
