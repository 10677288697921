import http, { AUTH_ROUTES } from '../../../../services/api';
import { errorNotifier, successNotifier } from '../../../../components/NotificationHandler';

export const validateCode = async (payload, setLoading, setSuccess, setMessage) => {
  setLoading(true);
  try {
    const {
      data: { result },
    } = await http.post(AUTH_ROUTES.SPECIAL_REQUEST_CHECKIN, payload);

    setMessage(result.data);

    setSuccess(true);
    successNotifier(result.message);
  } catch (e) {
    errorNotifier(e.response.data.result.message);
    setMessage(e.response.data.result.message);
  } finally {
    setLoading(false);
  }
};

export const specialRequestCheckout = async (payload, setLoading, setMessage, setSuccess) => {
  setLoading(true);
  try {
    const {
      data: { result },
    } = await http.post(AUTH_ROUTES.SPECIAL_REQUEST_CHEKOUT, payload);

    setSuccess(true);
    setMessage(result.message);
  } catch (e) {
    errorNotifier(e.response.data);
  } finally {
    setLoading(false);
  }
};
