import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { Flex, Box, SimpleGrid } from "@chakra-ui/react";

import DashboardCard from "./component/DashboardCard";
// import { DashboardArray } from './component/DashboardDataArray';
import PendingEmergency from "./component/PendingEmegency";
import {
  getDashboard,
  getEmergencies,
  getNotices,
  guestValidation,
  getEmergencyChartData,
} from "./service/dashboardApis";
import FullPageLoader from "../../../components/full-page-loader";

import GuestCodeValidationCard from "./component/GuestCodeValidationCard";
import GuestCounter from "./component/GuestCounter";
import Calender from "./component/Calender";
import Notice from "./component/Notice";
import AlarmChart from "../../../components/Chart";

const Dashboard = () => {
  const userId = useSelector((state) => state.auth.user);
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [dashData, setDashData] = useState([]);
  const [emergencies, setEmergencies] = useState([]);
  const [notices, setNotices] = useState([]);
  const [emergencyChartData, setEmergencyChartData] = useState({});

  useEffect(() => {
    getDashboard(setLoading, userId, setDashData);
    getEmergencies(setLoading, userId, setEmergencies);
    getNotices(setLoading, userId, setNotices);

    getEmergencyChartData(setLoading, userId, setEmergencyChartData);
  }, [userId]);


  const handleClick = async (guestCode) => {
    // e.preventDefault()
    setBtnLoading(true);
    await guestValidation({ guestCode, userId });
    setBtnLoading(false)
  };

  return loading ? (
    <FullPageLoader />
  ) : (
    <Flex className="content" p="5px" direction="column" w="100%">
      <SimpleGrid
        pt={[10, 9, 1]}
        columns={{ sm: 1, md: 2, lg: 4 }}
        color="white"
      >
        {dashData
          ?.map((content) => {
            return (
              <DashboardCard
                key={content?.id}
                bgColor={content?.background}
                title={content?.title}
                img={content?.image}
                amount={content?.value || 0}
                alt={content?.title}
              />
            );
          })
          .splice(0, 4)}
      </SimpleGrid>
      <Flex
        py="5px"
        mx="15px"
        my="3px"
        flex={1}
        direction={["column", "column", "column", "row"]}
        gridGap={8}
      >
        <Box
          flex={1}
          bg="#fff"
          boxShadow="0px 3px 6px #0000003B"
          borderRadius="10px"
        >
          <PendingEmergency
            emergencyData={emergencies?.filter(
              (emergency) => emergency?.status === "PENDING"
            )}
          />
        </Box>
        <Box
          flex={1}
          bg="gray.200"
          boxShadow="0px 3px 6px #0000003B"
          borderRadius="10px"
        >
          <AlarmChart data={emergencyChartData} />
        </Box>
      </Flex>
      <SimpleGrid columns={{ sm: 2, md: 2, lg: 4 }}>
        <GuestCodeValidationCard
          handleClick={handleClick}
          btnLoading={btnLoading}
        />
        <GuestCounter dashData={dashData} />
        <Calender />
        <Notice notices={notices} />
      </SimpleGrid>
    </Flex>
  );
};

export default Dashboard;
