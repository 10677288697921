import React from 'react';

import { Box, Heading, useDisclosure, ModalContent, Modal, ModalOverlay, ModalBody, ModalCloseButton, Flex, Text, Avatar } from '@chakra-ui/react';
import dayjs from 'dayjs';

const ResidentDetailsModal = ({ tenantData }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Flex>
      <Text onClick={onOpen} cursor='pointer' fontWeight={600} color='purple.400'>
        {tenantData?.tenantName}
      </Text>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalCloseButton />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <Box color='#8366A5' margin='10px 0'>
              <Heading fontSize='lg' mb={5}>
                Resident Details:
              </Heading>
              <Flex justifyContent={'center'} mb='20px'>
                <Avatar src={tenantData?.profilePicture} alt='' name={tenantData?.tenantName} size='xl' />
              </Flex>
              <Flex width='100%' justifyContent={'center'}>
                <Text textAlign={'left'} color='#000'>
                  Name: <span>{tenantData?.tenantName}</span>
                </Text>
              </Flex>
              <Flex width='100%' justifyContent={'center'}>
                <Text textAlign={'left'} color='#000'>
                  Designation: <span>{tenantData?.houses?.designation}</span>
                </Text>
              </Flex>
              <Flex width='100%' justifyContent={'center'}>
                <Text textAlign={'left'} color='#000'>
                  Status: {tenantData?.status}
                </Text>
              </Flex>
              <Flex width='100%' justifyContent={'center'}>
                <Text color='#000' textAlign={'left'}>
                  Date Created: {dayjs(tenantData?.createdAt)?.format('DD-MM-YYYY')}
                </Text>
              </Flex>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default ResidentDetailsModal;
