import { HStack, Text, Box, Tooltip } from "@chakra-ui/react";
import React, { useState } from "react";
import Card from "./Card";
import dayjs from 'dayjs';

const Notice = ({ notices }) => {

  const [activeIndex, setActiveIndex ] = useState(0)
  const nextSlide = () => {
    if(activeIndex < notices?.length - 1) {
      setActiveIndex(activeIndex + 1)
     }  
  }

  const prevSlide = () => {
       if(activeIndex > 0) {
         setActiveIndex(activeIndex - 1)
       }
  }

  return (
    <Card>
      <Text mb="3px" as="h3" fontWeight="600" h={"10%"}>
        Notice
      </Text>
          <HStack spacing={2} justifyContent="center" height="90%" alignItems="center">
            <i style={{ fontSize: "21px", width: "5%" }} className="fas fa-chevron-left" onClick={prevSlide}></i>
            <Box fontSize="12px" w={"90%"}>
              <Tooltip label={notices[activeIndex]?.content ? notices[activeIndex]?.content : ''} aria-label='A tooltip'>
                  <Text>
                    {notices[activeIndex]?.content ? notices[activeIndex]?.content.slice(0, 150) + '...' : ''}
                  </Text>
                </Tooltip>
                <Text fontWeight={"bold"} mt={1}>
                  {notices[activeIndex]?.content ? dayjs(notices[activeIndex]?.createdAt).format("DD-MMM-YYYY") : ''}
              </Text>
              </Box>
            <i style={{ fontSize: "21px", width: "5%" }} className="fas fa-chevron-right" onClick={nextSlide}></i>
          </HStack>
    </Card>
  );
};

export default Notice;
