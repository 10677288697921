import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getEmergency } from './service/securityEmergencyApi';
import FullPageLoader from '../../../components/full-page-loader';
import { CustomTable } from '../../../components/customized table/CustomTable';
import { getIssueReports } from '../../Manager-Portal/Emergency/services/issueReports';

export const Security_Emergency = () => {
  const userId = useSelector((state) => state.auth.user);
  const [emergency, setEmergency] = useState([]);
  const [refresh] = useState({});
  const [loading, setLoading] = useState(true);
  const [issueReports, setIssueReports] = useState([]);

  useEffect(() => {
    getEmergency(setLoading, userId, setEmergency);
    getIssueReports(userId, setLoading, setIssueReports);
  }, [userId, refresh]);

  const emergencyTableHead = [
    {
      Header: 'S/N',
      accessor: 'id',
      Cell: ({ row }) => Number(row.id) + 1, //RDT provides index by default
    },
    {
      Header: 'Name',
      accessor: 'userId.houseId.name',
    },
    {
      Header: 'Phone no',
      accessor: 'userId.tenantPhone',
    },
    {
      Header: 'Occupant Name',
      accessor: 'userId.tenantName',
    },
    {
      Header: 'Address',
      accessor: 'userId.houseId.estate[0].houseNumber',
    },
    {
      Header: 'Emmergency Type',
      accessor: 'type',
    },
    // {
    //   Header: 'Emergency Status',
    //   accessor: 'status',
    //   Cell: ({ row }) => (
    //     <Checkbox
    //       colorScheme={'teal'}
    //       color={row.original.status === 'PENDING' ? 'crimson' : 'teal'}
    //       isChecked={row.original.status === 'SORTED'}
    //       fontSize='sm'
    //       onChange={() => handleChecked(row)}
    //     >
    //       <Text as='span' fontSize='12px'>
    //         {row.original.status}
    //       </Text>
    //     </Checkbox>
    //   ),
    // },
  ];

  const issuseReportsTableHead = [
    {
      Header: 'S/N',
      accessor: 'id',
      Cell: ({ row }) => Number(row.id) + 1, //RDT provides index by default
    },
    {
      Header: 'Name',
      accessor: 'houses.name',
    },
    {
      Header: 'Phone no',
      accessor: 'tenants.tenantPhone',
    },
    {
      Header: 'Occupant Name',
      accessor: 'tenants.tenantName',
    },
    {
      Header: 'Address',
      accessor: 'streets.streetName',
    },
    {
      Header: 'Emmergency Type',
      accessor: 'issueType',
    },
  ];

  return (
    <Box>
      {loading ? (
        <FullPageLoader />
      ) : (
        <Box padding={['5px', '20px', '20px']} bg='#fff' borderRadius='5px' m='25px 5px'>
          <Tabs>
            <TabList>
              <Tab {...selectedTabStyles}>Emergencies</Tab>
              <Tab {...selectedTabStyles}>Issue Reports</Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                <CustomTable COLUMNS={emergencyTableHead} DATA={emergency} />
              </TabPanel>

              <TabPanel>
                <CustomTable COLUMNS={issuseReportsTableHead} DATA={issueReports} />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      )}
    </Box>
  );
};

const selectedTabStyles = {
  fontWeight: 600,
  fontSize: '16px',
  color: 'gray.700',
  mx: '2rem',
  _selected: { color: '#F09091', borderBottom: '3px solid #F09091 ' },
  _focus: { border: 'none' },
};
