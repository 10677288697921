import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { getEstateName } from '../../Security-Portal/GuestCheckIn/service/securityGuestValidationApi';
import { selectedTabStyles } from '../../Security-Portal/GuestCheckOut';
import { ManagerGuestCheckOut } from './components/ManagerGuestCheckout';
import { SpecialRequestCheckoutManager } from './components/SpecialRequestCheckoutManager';

const ManagerCheckout = ({ setShowSideBar }) => {
  const userId = useSelector((state) => state.auth.user);
  const [estateName, setEstateName] = useState('Our Estate');

  useEffect(() => {
    setShowSideBar();
  }, [setShowSideBar]);

  useEffect(() => {
    getEstateName(setEstateName, userId);
  }, [userId]);

  return (
    <Tabs variant='unstyled' mt='10'>
      <TabList display='flex' justifyContent='center'>
        <Tab {...selectedTabStyles}>Guest Check out</Tab>
        <Tab {...selectedTabStyles}>Special Request Check out</Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <ManagerGuestCheckOut estateName={estateName} />
        </TabPanel>
        <TabPanel>
          <SpecialRequestCheckoutManager estateName={estateName} />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default ManagerCheckout;
