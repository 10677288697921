export const getComponentTitle = (pathname) => {
  switch (pathname) {
    //MANAGER
    case "/manager/guest-list":
      return "Guests";
    case "/manager/emergency":
      return "Emergencies";
    case "/manager/notification":
      return "Notifications";
    case "/manager/dashboard":
      return "Dashboard";
    case "/manager/street":
      return "Streets";
    case "/manager/house":
      return "Houses";
    case "/manager/resident":
      return "Residents";
    case "/manager/estate-due":
      return "Estate Dues";
    case "/manager/guest-check-in":
      return "Guest Check In";
    case "/manager/guest-check-out":
      return "Guest check out";

    //SECURITY
    case "/security/guest-list":
      return "Guests";
    case "/security/emergency":
      return "Emergencies";
    case "/security/notification":
      return "Notifications";
    case "/security/dashboard":
      return "Dashboard";
    case "/security/guest-check-in":
      return "Guest Check In";
    case "/security/guest-check-out":
      return "Guest check out";

    case "/security/user-validation":
      return "User Validation";

    default:
      return "Welcome";
  }
};
