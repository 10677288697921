import { Checkbox, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CustomTable } from '../../../../components/customized table/CustomTable';
import FullPageLoader from '../../../../components/full-page-loader';
import { getEmergency } from '../services/getEmergency';
import { updateEmergency } from '../services/updateEmergency';

export const EmergencyTab = () => {
  const userId = useSelector((state) => state.auth.user);
  const [emergency, setEmergency] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState({});

  useEffect(() => {
    getEmergency(userId, setLoading, setEmergency);
  }, [userId, refresh]);

  const emergencyTableHead = [
    {
      Header: 'S/N',
      accessor: 'id',
      Cell: ({ row }) => Number(row.id) + 1,
    },
    {
      Header: 'Name',
      accessor: 'userId.houseId.name',
    },
    {
      Header: 'Phone no',
      accessor: 'userId.tenantPhone',
    },
    {
      Header: 'Occupant Name',
      accessor: 'userId.tenantName',
    },
    {
      Header: 'Address',
      accessor: 'userId.houseId.estate[0].houseNumber',
    },
    {
      Header: 'Emmergency Type',
      accessor: 'type',
    },
    {
      Header: 'Emergency Status',
      accessor: 'status',
      Cell: ({ row }) => (
        <Checkbox
          colorScheme={'teal'}
          color={row.original.status === 'PENDING' ? 'crimson' : 'teal'}
          isChecked={row.original.status === 'SORTED'}
          fontSize='sm'
          onChange={() => handleChecked(row)}
        >
          <Text as='span' fontSize='12px'>
            {row.original.status}
          </Text>
        </Checkbox>
      ),
    },
  ];

  const handleChecked = async (row) => {
    const payload = { ...row.original, userId };
    updateEmergency(payload).then(() => setRefresh({}));
  };

  return loading ? <FullPageLoader /> : <CustomTable COLUMNS={emergencyTableHead} DATA={emergency} />;
};
