import {
  Avatar,
  Box,
  Button,
  Center,
  Flex,
  FormLabel,
  Heading,
  HStack,
  Image,
  Img,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { nanoid } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { ImAttachment } from 'react-icons/im';
import { RiSendPlane2Fill } from 'react-icons/ri';
import { useSelector } from 'react-redux';
import { getChatMessages, sendChatMessage } from '../services/chatMessage';
const relativeTime = require('dayjs/plugin/relativeTime');
dayjs.extend(relativeTime);

export const IssueMessaging = ({ issue }) => {
  const userId = useSelector((state) => state.auth.user);
  const messageEndRef = useRef(null);
  const [typedMessage, setTypedMessage] = useState('');
  const [chatImage, setChatImage] = useState({});
  const [chatMessages, setChatMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState({});
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    messageEndRef.current?.scrollIntoView({ behaviour: 'smooth' });
  }, [chatImage, chatMessages]);

  useEffect(() => {
    getChatMessages(issue._id, userId, setLoading, setChatMessages);
  }, [userId, issue._id, refresh]);

  const handleImageUpload = (e) => {
    if (!e.target.files) return;
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = async () => {
      setChatImage({ imageUrl: reader.result, file });
      onOpen();
    };
  };

  const handleSend = () => {
    const formData = new FormData();
    formData.append('issueImage', chatImage.file || {});
    formData.append('issueId', issue._id);
    formData.append('message', typedMessage);
    formData.append('tenantId', issue.userId);
    formData.append('managerId', userId);
    formData.append('sender', 'managers');
    sendChatMessage(formData, setLoading, setRefresh, setTypedMessage);
    isOpen && onClose();
  };

  return (
    <Stack p='5'>
      <HStack justify='space-between' borderBottom='1px solid #eee' pb='5'>
        <HStack>
          <Avatar name='E A' src='https://bit.ly/dan-abramov' />

          <Text fontSize='13px'>Estate Manager</Text>
        </HStack>

        <Button onClick={onClose} leftIcon={<AiOutlineClose />} size='xs' bg='gray.100' _focus={{ outline: 'none' }}>
          Close
        </Button>
      </HStack>
      <HStack justify='space-between' py='2'>
        <Text fontSize='12px' py='2' px='4' borderRadius='full' border='1px solid #FEE8E7' bg='#FAF6FF'>
          {issue?.issueType}
        </Text>
      </HStack>
      <Text borderBottom='1px solid #eee' pb='5' color='#614385' fontWeight='500' fontSize='1.4rem'>
        {issue?.title}
      </Text>
      <Stack spacing='5' bg='gray.50' p='5' h='55vh' overflowX='hidden' overflowY={'scroll'}>
        {!chatMessages?.length ? (
          <Center w='100%' h='100%'>
            <Heading fontSize={'1.5rem'}>Start Messaging with Tenant</Heading>
          </Center>
        ) : (
          chatMessages?.map((chat) => (
            <Flex key={nanoid()} pt='2' pl={chat?.sender !== 'tenants' && 5} pr={chat?.sender === 'tenants' && 5}>
              {/* <Avatar order={chat.sender === 'tenants' && 1} name='Dan Abrahmov' size='sm' src='https://bit.ly/kent-c-dodds' /> */}
              <Stack w='100%' align={chat?.sender === 'tenants' && 'flex-end'}>
                {chat?.attachment ? <Img borderRadius='3px' shadow='sm' w='65%' h='40vh' objectFit='cover' src={chat?.attachment} alt='...' /> : null}
                {chat?.message ? (
                  <Text className={chat?.sender !== 'tenants' ? 'speech-bubble' : 'tenant-speech-bubble'}>
                    {chat?.message}
                    <Flex pt='2' justify='flex-end'>
                      <Text fontWeight={500} fontSize='12px'>
                        {dayjs().to(dayjs(chat?.createdAt))}
                      </Text>
                    </Flex>
                  </Text>
                ) : null}
              </Stack>
            </Flex>
          ))
        )}

        <Box ref={messageEndRef} />
      </Stack>

      <HStack pt='10'>
        <InputGroup>
          <Input
            autoFocus
            pl='10'
            _focus={{ outline: 'none' }}
            placeholder=' Message'
            shadow='sm'
            value={typedMessage}
            onChange={(e) => setTypedMessage(e.target.value)}
          />
          <Input type='file' id='file' accept='image/png, image/gif, image/jpeg' display='none' onChange={handleImageUpload} />
          <FormLabel htmlFor='file'>
            <InputLeftElement cursor='pointer' children={<ImAttachment />} />
          </FormLabel>
        </InputGroup>

        <Button
          isDisabled={!typedMessage}
          isLoading={loading}
          color='#fff'
          bg='#614385'
          _hover={{ bg: '#614385' }}
          rightIcon={<RiSendPlane2Fill />}
          onClick={handleSend}
        >
          Send
        </Button>
      </HStack>

      <Modal isCentered isOpen={isOpen} onClose={onClose} zIindex={9999999}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Send media</ModalHeader>

          <ModalBody>
            <VStack spacing='10'>
              <Image w='100%' h='50vh' objectFit='cover' src={chatImage.imageUrl} alt='...' />
              <Input
                autoFocus
                border='2px solid #ddd'
                _focus={{ borderColor: '#ddd' }}
                placeholder='Message'
                shadow='sm'
                value={typedMessage}
                onChange={(e) => setTypedMessage(e.target.value)}
              />
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Button variant='outline' mr={5} onClick={onClose}>
              Close
            </Button>
            <Button onClick={handleSend} color='white' bg='#614385'>
              Send
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Stack>
  );
};
