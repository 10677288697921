import React from "react";

import { Flex, Box, Text, Image, Spacer } from "@chakra-ui/react";

const DashboardCard = ({ title, amount, img, bgColor, alt }) => {
  return (
    <Box bg={bgColor} borderRadius="10" p={4} pr={7} m="15px">
      <Text textAlign="left" fontSize="15">
        {title}
      </Text>
      <Flex mb={3}>
        <Box>
          <Text fontSize="44" fontWeight="400">
            {amount}
          </Text>
        </Box>
        <Spacer />
        <Box boxSize="10" mt={4}>
          <Image src={img} alt={alt} />
        </Box>
      </Flex>
    </Box>
  );
};

export default DashboardCard;
