import React, { useState } from 'react';
import {
  Box,
  Text,
  Button,
  useDisclosure,
  Input,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';

import { addEstateDueAmount } from '../services/estateDueApis';
import { useSelector } from 'react-redux';

const AddDuesModal = ({ inputAmount, setInputAmount, update, dueAmount }) => {
  const userId = useSelector((state) => state.auth.user);
  const [btnloading, setBtnLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleSave = async () => {
    setBtnLoading(true);
    const payload = {
      ...dueAmount,
      estateDue: inputAmount,
      userId,
    };
    await addEstateDueAmount(setBtnLoading, payload, onClose).then(() =>
      update()
    );
  };

  return (
    <>
      <Button
        onClick={onOpen}
        bg='#614285'
        color='white'
        size='sm'
        _hover={{ bg: '#614285' }}
      >
        Set Dues
      </Button>
      <Modal size='md' isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent color='white'>
          <ModalHeader bg='#F09091' p={5}>
            Add Dues
          </ModalHeader>
          <ModalBody>
            <Text color='#343434' fontSize='16px'>
              update the amount to be paid as estate dues by residents
            </Text>
            <FormControl isRequired>
              <FormLabel color='#343434' fontSize='0.9rem' mt={5}>
                Amount
              </FormLabel>
              <Input
                bg='#F6F6F6'
                color='black'
                name=''
                value={inputAmount}
                onChange={(e) => setInputAmount(e.target.value)}
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Box w='100%' textAlign='center'>
              <Button
                _hover={{ outline: '#614285' }}
                bg='#614285'
                mb={10}
                mt={3}
                py='22px'
                w='100%'
                isDisabled={inputAmount === ''}
                onClick={handleSave}
                isLoading={btnloading}
              >
                Submit
              </Button>
            </Box>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddDuesModal;
