import { isValidElement, Children, cloneElement } from 'react';
import {
  Modal as ChakraModal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Button,
  useDisclosure,
  Box,
  Slide,
} from '@chakra-ui/react';

export default function GenericModal({
  children,
  size,
  btnTitle,
  btnIcon,
  buttonProps,
  addCloseModalBtn,
  overlayBG,
  div,
}) {
  const { onOpen, isOpen, onClose } = useDisclosure();
  const childrenWithProps = Children.map(children, (child) => {
    // Checking isValidElement is the safe way and avoids a TS error too.
    if (isValidElement(child)) {
      return cloneElement(child, { onClose });
    }

    return child;
  });

  return (
    <>
      {div ? (
        <Box onClick={onOpen} cursor='pointer' {...buttonProps}>
          {btnTitle}
        </Box>
      ) : (
        <Button
          _focus={{ border: 'none' }}
          leftIcon={btnIcon}
          onClick={onOpen}
          color='#fff'
          bg='none'
          _hover={{ opacity: 0.8 }}
          {...buttonProps}
        >
          {btnTitle}
        </Button>
      )}
      <Slide direction='bottom' in={isOpen} style={{ zIndex: 10 }}>
        <ChakraModal
          size={size || 'xs'}
          isOpen={isOpen}
          onClose={onClose}
          isCentered
          motionPreset='slideInBottom'
        >
          <ModalOverlay bg={overlayBG} />
          <ModalContent py={5} borderRadius='5px'>
            {addCloseModalBtn && <ModalCloseButton />}
            <ModalBody py={{ base: 4, md: 4, lg: 6 }}>
              {childrenWithProps}
            </ModalBody>
          </ModalContent>
        </ChakraModal>
      </Slide>
    </>
  );
}
