import { Box, useMediaQuery } from '@chakra-ui/react';
import { Route, Switch, BrowserRouter as Router, Redirect } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Navigation } from '../components/Nav/Navigation';
import { SideBar } from '../components/Layout/SideBar';
import { PROTECTED_PATHS } from './constants';

import { Security_Dashboard } from '../pages/Security-Portal/Dashboard';
import { Security_GuestList } from '../pages/Security-Portal/GuestList';
import { Security_Emergency } from '../pages/Security-Portal/Emergency';
import { SecurityCheckin } from '../pages/Security-Portal/GuestCheckIn';
import { SecurityCheckout } from '../pages/Security-Portal/GuestCheckOut';
import Security_Notification from '../pages/Security-Portal/Notification';

import UserValidation from './../pages/Security-Portal/UserValidation/index';

const {
  SECURITY_DASHBOARD,
  SECURITY_EMERGENCY,
  SECURITY_GUEST_CHECK_IN,
  SECURITY_GUEST_CHECK_OUT,
  SECURITY_GUEST_LIST,
  SECURITY_NOTIFICATION,
  SECURITY_USER_VALIDATION,
} = PROTECTED_PATHS;

export function AuthenticatedSecurityApp({ userType }) {
  const [toggleSide, setToggleSide] = useState(false);
  const [showSidBar, setShowSideBar] = useState(true);
  const [isMobileScreen] = useMediaQuery('(max-width: 600px)');

  useEffect(() => {
    if (isMobileScreen) {
      setToggleSide((initial) => !initial);
    }
  }, [isMobileScreen]);

  const handleToggle = () => {
    setToggleSide((initial) => !initial);
  };

  return (
    <Box className='App' display='flex'>
      <Box className={`app-container`}>
        <Router>
          <Box display={`${toggleSide ? 'none' : 'block'}`}>{showSidBar ? <SideBar type='security' toggle={handleToggle} /> : ''}</Box>
          <Box className='app-right' width='100%'>
            <Navigation toggle={handleToggle} sideBarActive={toggleSide} showSidBar={showSidBar} userType={userType} type='security' />

            <Box width='100%' maxWidth='100%' mt='60px' padding={['5px', '10px', '20px']}>
              <Switch>
                <Route path={SECURITY_USER_VALIDATION} exact component={UserValidation} />
                <Route path={SECURITY_DASHBOARD} exact component={Security_Dashboard} />
                <Route path={SECURITY_GUEST_LIST} exact component={Security_GuestList} />

                <Route path={SECURITY_EMERGENCY} exact component={Security_Emergency} />

                <Route path={SECURITY_NOTIFICATION} exact component={Security_Notification} />
                <Route path={SECURITY_GUEST_CHECK_IN} exact>
                  <SecurityCheckin setShowSideBar={() => setShowSideBar(false)} />
                </Route>
                <Route path={SECURITY_GUEST_CHECK_OUT} exact>
                  <SecurityCheckout setShowSideBar={() => setShowSideBar(false)} />
                </Route>

                <Redirect from='/*' to={SECURITY_DASHBOARD} />
              </Switch>
            </Box>
          </Box>
        </Router>
      </Box>
    </Box>
  );
}
