import http, { AUTH_ROUTES } from '../../../../services/api';
import { errorNotifier } from '../../../../components/NotificationHandler';

// Get ALL GUEST LIST
export const getGuestList = async (setLoading, userId, setGuestList) => {
  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.SECURITY_GET_GUEST_LIST(userId));
    setGuestList(data.guests);

    setLoading(false);
  } catch (e) {
    setGuestList([]);
    setLoading(false);
  } finally {
    setLoading(false);
  }
};

//GET SPECIFIC TENANT BY PHONE NUMBER
export const getPhoneNumber = async (payload, setTenantData) => {
  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.SECURITY_PHONE_NUMBER_VALIDATION(payload.phone, payload.userId));

    setTenantData(data);
  } catch (e) {
    setTenantData([]);
    errorNotifier(e.response?.data?.result?.message);
  }
};
