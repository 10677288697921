/**
 * Paths available to users who are not logged in
 * @constant
 */
export const PUBLIC_PATHS = {
  LANDING: '/',
  LOGIN: '/login',
};

/**
 * Paths available to users who are  logged in
 * @constant
 */
export const PROTECTED_PATHS = {
  MANAGER_DASHBOARD: '/manager/dashboard',
  SECURITY_DASHBOARD: '/security/dashboard',

  MANAGER_GUEST_LIST: '/manager/guest-list',
  SECURITY_GUEST_LIST: '/security/guest-list',

  MANAGER_EMERGENCY: '/manager/emergency',
  SECURITY_EMERGENCY: '/security/emergency',

  MANAGER_NOTIFICATION: '/manager/notification',
  SECURITY_NOTIFICATION: '/security/notification',

  MANAGER_GUEST_CHECK_IN: '/manager/guest-check-in',
  SECURITY_GUEST_CHECK_IN: '/security/guest-check-in',

  MANAGER_GUEST_CHECK_OUT: '/manager/guest-check-out',
  SECURITY_GUEST_CHECK_OUT: '/security/guest-check-out',
  SECURITY_USER_VALIDATION: '/security/user-validation',

  MANAGER_STREET: '/manager/street',
  MANAGER_HOUSE: '/manager/house',
  MANAGER_RESIDENT: '/manager/resident',
  MANAGER_ESTATE_DUE: '/manager/estate-due',

  MANAGER_NEWS_EVENTS: '/manager/news-events',

  MANGER_EVENT_REQUESTS: '/manager/events-request',
  MANAGER_PAYOUT_HISTORY: '/manager/request-payment-history',

  MANAGER_POLLS: '/manager/polls',
};

/**
 * the number of table rows daat that will be display
 * @constant
 */

export const DATA_ROWS = {
  LIMIT: 100,
};
