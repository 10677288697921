import {
  Box,
  Button,
  Flex,
  HStack,
  TableContainer,
  Tooltip,
} from "@chakra-ui/react";
import AddResidentModal from "./components/AddResidentModal";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { banResident, deleteResident, getAllResidents } from "./service";
import FullPageLoader from "../../../components/full-page-loader";
import { AiOutlineDownload } from "react-icons/ai";
import { creatCsvFile, downloadFile } from "download-csv";
import { getAllHouses } from "../House/service/getHouse";
import { getStreets } from "../House/service/getStreets";
import { CustomTable } from "../../../components/customized table/CustomTable";
import { Modal } from "../../../components/modal";
import { EditResidentForm } from "./components/EditResidentForm";
import { FiEdit } from "react-icons/fi";
import CautionAlertDialog from "../../../components/CautionAlertDialog";
import { BiBlock, BiTrash } from "react-icons/bi";
import { GrShareOption } from "react-icons/gr";
import {
  errorNotifier,
  successNotifier,
} from "../../../components/NotificationHandler";
import ResidentDetailsModal from "./components/ResidentDetailsModal";

const ICON = { color: "#fc8181", fontSize: "1.3em" };
const Resident = () => {
  const userId = useSelector((state) => state.auth.user);
  const [refresh, setRefresh] = useState({});
  const [allResidentRows, setAllResidentRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [houses, setHouses] = useState([]);
  const [streets, setStreets] = useState([]);

  useEffect(() => {
    // getResidents(userId, setLoading, setResidentRows);
    getAllResidents(userId, setLoading, setAllResidentRows);
    getStreets(userId, setStreets);
    getAllHouses(userId, setHouses, setLoading);
    // return () => {
    //   setResidentRows([]);
    // };
  }, [userId, refresh]);

  const residentsColumn = [
    {
      Header: "S/N",
      accessor: "id",
      Cell: ({ row }) => Number(row.id) + 1, //RDT provides index by default
    },
    {
      Header: "House address",
      accessor: "streets.streetName",
    },
    {
      Header: "Phone no",
      accessor: "tenantPhone",
    },
    {
      Header: "Occupant's Name",
      accessor: "tenantName",
      Cell: ({ row }) => <ResidentDetailsModal tenantData={row.original} />,
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "House no",
      accessor: "houses.estate[0].houseNumber",
    },
    {
      Header: "Tenant code ",
      accessor: "tenantCode",
      Cell: ({ row }) => (
        <Box>
          {row.original.resetPassword ? "*****" : row.original.tenantCode}
        </Box>
      ),
    },
    {
      Header: "Action",
      accessor: "",
      Cell: ({ row }) => (
        <Flex justifyContent="space-between">
          <Tooltip label="edit resident">
            <Box>
              <Modal icon={<FiEdit {...ICON} color="green" />}>
                <EditResidentForm
                  initialData={row.original}
                  setRefresh={() => setRefresh({})}
                  // updateTable={() => updateTable()}
                />
              </Modal>
            </Box>
          </Tooltip>

          <Tooltip label="delete resident">
            <Box>
              <CautionAlertDialog
                onContinue={() => handleDelete(row.original)}
                cautionTitle="Are you sure you want to remove this resident"
                icon={<BiTrash {...ICON} />}
              />
            </Box>
          </Tooltip>

          <Tooltip label="Ban resident">
            <Box>
              <CautionAlertDialog
                onContinue={() => handleBan(row.original)}
                cautionTitle="Are you sure you want to ban this resident"
                icon={<BiBlock {...ICON} color="black" />}
              />
            </Box>
          </Tooltip>
          <Tooltip label="share tenant code">
            <Box
              ml="5px"
              onClick={() =>
                window.innerWidth < 760
                  ? shareLink(row.original?.tenantCode)
                  : copyToClipBoard(row.original)
              }
            >
              <GrShareOption cursor={"pointer"} {...ICON} />
            </Box>
          </Tooltip>
        </Flex>
      ),
    },
  ];

  const handleBan = (data) => {
    const payload = { ...data, userId, status: "Banned" };
    banResident(payload).then(() => setRefresh({}));
  };

  const handleDelete = (data) => {
    const _id = data?._id;
    const payload = { userId, _id };
    deleteResident(payload).then(() => setRefresh({}));
  };

  const shareLink = (data) => {
    const code = data?.resetPassword ? "*****" : data?.tenantCode;
    if (navigator.share) {
      navigator
        .share({
          title: `Tenant code`,
          text: `Your tenant code is ${code}, Please use this tenant code to log into your mobile app`,
          url: `https://aahera.com.ng`,
        })
        .then(() => console.log("Successfully share"))
        .catch((error) => console.log("Error sharing", error));
    } else {
      console.log(`Your system doesn't support sharing`);
      errorNotifier(`Your system doesn't support sharing`);
    }
  };

  const copyToClipBoard = async (data) => {
    try {
      const code = data?.resetPassword ? "*****" : data?.tenantCode;
      await navigator.clipboard.writeText(code);
      successNotifier("Copied!");
    } catch (err) {
      errorNotifier("Failed to copy!");
    }
  };

  const data = allResidentRows.map((item) => {
    return {
      HouseAddress: item?.streets?.streetName,
      phoneNumber: item?.tenantPhone,
      name: item?.tenantName,
      email: item?.email,
      HouseNumber: item?.houses?.estate[0].houseNumber,
    };
  });

  const handleCsvDownload = () => {
    const headerNames = residentsColumn.map((col) => col.Header).slice(0, -1);

    const csvFile = creatCsvFile(data, headerNames);
    downloadFile(csvFile);
  };

  return loading ? (
    <FullPageLoader />
  ) : (
    <Box margin="20px" mt="60px" padding="20px" bg="#fff">
      <Flex
        flexDirection=""
        alignItems="flex-end"
        justifyContent={"space-between"}
      >
        <HStack width="100%" spacing="5" justify="end">
          <AddResidentModal
            streets={streets}
            houses={houses}
            onRefresh={() => setRefresh({})}
          />

          <Button
            size="sm"
            leftIcon={<AiOutlineDownload />}
            variant="outline"
            _focus={{ outline: "none" }}
            onClick={handleCsvDownload}
          >
            CSV
          </Button>
        </HStack>
      </Flex>

      <TableContainer>
        <CustomTable
          COLUMNS={residentsColumn}
          DATA={allResidentRows}
          innerSearch
        ></CustomTable>
      </TableContainer>
    </Box>
  );
};

export default Resident;
