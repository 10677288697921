import { Flex, Heading, HStack, Stack, Text } from '@chakra-ui/react';
import dayjs from 'dayjs';
import React from 'react';
import CautionAlertDialog from '../../../../components/CautionAlertDialog';

const text = { w: '150px', color: 'gray.600', fontWeight: '500' };

export const EventVerification = ({ response, setSuccess }) => {
  return (
    <Stack
      color='#4d4e4f'
      bg='#f9f9f9'
      p='8'
      borderRadius='4px'
      shadow='lg'
      minW='350px'
      fontSize='12px'
      fontWeight='600'
      spacing='4'
    >
      <HStack>
        <Text {...text}>Resident's name:</Text>
        <Text>{response?.tenantName}</Text>
      </HStack>
      <HStack>
        <Text {...text}>Event:</Text>
        <Text>{response?.subType}</Text>
      </HStack>
      <HStack>
        <Text {...text}>Venue:</Text>
        <Text>{response?.address}</Text>
      </HStack>
      <HStack>
        <Text {...text}>Description:</Text>
        <Text>{response?.description}</Text>
      </HStack>
      <Text {...text} fontWeight='14px'>
        Event Dates:
      </Text>
      <Flex gap='1rem' wrap='wrap'>
        {response?.dates?.map((date) => (
          <Text py='0.5' px='1' bg='gray.100' fontWeight='600'>
            {dayjs(date).format('DD MMM')}
          </Text>
        ))}
      </Flex>
      <Text {...text} fontWeight='14px'>
        Invited Guests:
      </Text>
      <Stack w='100%' h='130px' overflowY='auto'>
        {response?.guestList?.length ? (
          response?.guestList?.map((guest) => (
            <HStack>
              <Text w='150px'>{guest?.name}</Text>
              <Text>{guest?.phone}</Text>
            </HStack>
          ))
        ) : (
          <Heading fontSize='1.8rem' pt='5' color='gray.500'>
            No guest Invited yet
          </Heading>
        )}
      </Stack>

      <CautionAlertDialog
        bg='#614285'
        size='xs'
        onContinue={() => setSuccess(false)}
        btnTitle='Close'
        cautionTitle='Close Invite Information?'
      />
    </Stack>
  );
};
