import React from "react";
import { Button } from "@chakra-ui/button";

const CreateHouseBtn = ({ text }) => {
  return (
    <Button
      _focus={{ border: "none" }}
      _hover={{ bg: "#A08CA8" }}
      bg="#614285"
      color="#fff"
    >
      {text}
    </Button>
  );
};

export default CreateHouseBtn;
