import { Box } from "@chakra-ui/react";

export const CurvyBox = ({ children, bg }) => {
  return (
    <Box
      padding="20px 30px"
      paddingRight="60px"
      bg={bg || "#fff"}
      borderRadius="5px"
    >
      {children}
    </Box>
  );
};
