// import {
//   errorNotifier,
//   successNotifier,
// } from '../../../../components/NotificationHandler';
import http, { AUTH_ROUTES } from '../../../../services/api';

//SECURITY GUEST CHECKOUT
export const guestCheckOut = async (payload, setLoading, setConfirmationMsg, setSuccess) => {
  try {
    await http.post(AUTH_ROUTES.SECURITY_GUEST_CHECKOUT, payload);
    setLoading(false);

    setSuccess(true);
  } catch (e) {
    setLoading(false);
    setConfirmationMsg(e.response?.data?.result?.message);
    // errorNotifier(e.response?.data?.result?.message);
  } finally {
    setLoading(false);
  }
};
