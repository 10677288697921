import { FormControl, FormLabel, Input, Stack, Textarea } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { PrimaryBTN } from '../../../../components/CustomButtons';
import { editAnnouncement } from '../service/announcement';

const EditAnnouncement = ({ data, setRefresh }) => {
  const userId = useSelector((state) => state.auth.user);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState(data.title);
  const [message, setMessage] = useState(data.body);

  const handleEdit = (e, data) => {
    e.preventDefault();
    const payload = { title, body: message, userId, announcementId: data._id };

    editAnnouncement(setLoading, payload).then(setRefresh);
  };

  return (
    <FormControl>
      <Stack mb='10'>
        <FormLabel>
          Title
          <Input value={title} onChange={(e) => setTitle(e.target.value)} placeholder='Title of announcement' />
        </FormLabel>
        <FormLabel>
          Message
          <Textarea value={message} onChange={(e) => setMessage(e.target.value)} placeholder='there shall be...' />
        </FormLabel>
      </Stack>
      <PrimaryBTN isLoading={loading} loadingText='updating' onClick={(e) => handleEdit(e, data)}>
        Edit
      </PrimaryBTN>
    </FormControl>
  );
};

export default EditAnnouncement;
