import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  HStack,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import TableRow from './TableRow';
import { nanoid } from '@reduxjs/toolkit';

const PendingEmergency = ({ emergencyData }) => {
  const history = useHistory();

  return (
    <div style={{ padding: '15px' }}>
      <HStack mb='10px' justifyContent='space-between' color='#614285'>
        <Text fontWeight='bold' fontSize='16px'>
          Pending Emergency
        </Text>
        <Button size='xs' variant='ghost' onClick={()=>history.push('/security/emergency')}>
          View All
        </Button>
      </HStack>
      <Table varaiant='simple' size='sm' color='#614285'>
        <Thead>
          <Tr>
            <Th fontSize={15} fontWeight='medium' textTransform='capitalize'>
              House No
            </Th>
            <Th fontSize={15} fontWeight='medium' textTransform='capitalize' px={'0 !important'}>
              Emergency Type
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {emergencyData?.map((emergency) => (
            <TableRow
            key={nanoid()} 
           
            houseNo={emergency?.userId?.houseId?.houseNo || emergency?.userId?.tenantPhone} 
            type={emergency?.type}
           />
          )).splice(0,4)}
        </Tbody>
      </Table>
    </div>
  );
};

export default PendingEmergency;
