import {
  FormControl,
  FormLabel,
  Input,
  Button,
  Select,
} from "@chakra-ui/react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { editHouse } from "../pages/Manager-Portal/House/service/editHouse";

const EditHouseForm = ({ setRefresh, onClose, initialData }) => {
  const userId = useSelector((state) => state.auth.user);
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState(initialData);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleUpdate = (event) => {
    setLoading(true);
    event.preventDefault();
    const payload = {
      ...formValues,
      userId,
    };
    editHouse(payload, onClose, setRefresh, setLoading);
  };
  return (
    <FormControl id="createHouse" display="flex" flexDirection="column">
      <FormLabel>Designation</FormLabel>
      <Select
        placeholder="Designation"
        mb="10px"
        onChange={handleChange}
        value={formValues?.designation}
        name="designation"
      >
        <option value="landlord">Landlord</option>
        <option value="tenant">Tenant</option>
        <option value="agent">Agent</option>
      </Select>
      <FormLabel>
        {formValues?.designation?.charAt(0)?.toUpperCase() +
          formValues?.designation?.slice(1) || ''}{' '}
        Name
      </FormLabel>
      {/* <FormLabel>Landlord Name</FormLabel> */}
      <Input
        mb={4}
        value={formValues?.landLordName}
        variant="filled"
        type="text"
        name="landLordName"
        onChange={handleChange}
      />
      <FormLabel>Phone Nmuber</FormLabel>
      <Input
        mb={4}
        value={formValues.phoneNumber}
        variant="filled"
        type="text"
        name="phoneNumber"
        onChange={handleChange}
      />
      <FormLabel>Email</FormLabel>
      <Input
        mb={4}
        variant="filled"
        value={formValues.email}
        type="text"
        name="email"
        onChange={handleChange}
      />

      <Button
        bg="#8366A5"
        color="#fff"
        h={50}
        fontWeight="medium"
        _hover={{ bg: "#8366A5" }}
        isLoading={loading}
        onClick={handleUpdate}
      >
        Update
      </Button>
    </FormControl>
  );
};

export default EditHouseForm;
