import { Text } from '@chakra-ui/react';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CustomTable } from '../../../../components/customized table/CustomTable';
import GenericModal from '../../../../components/modal/GenericModal';
import { PaymentReceipt } from '../estateDueComponents/PaymentReceipt';
import { getVehiclePayments } from '../services/vehicle';

export const VehicularPayment = () => {
  const userId = useSelector((state) => state.auth.user);
  const [, setLoading] = useState(true);
  const [vehicularPayments, setVehicularPayments] = useState([]);

  useEffect(() => {
    getVehiclePayments(userId, setLoading, setVehicularPayments);
  }, [userId]);

  const vehicularPaymentHeader = [
    {
      Header: 'S/N',
      accessor: 'id',
      Cell: ({ row }) => Number(row.id) + 1, //RDT provides index by default
    },
    {
      Header: 'Type of vehicle',
      accessor: 'subType',
    },
    {
      Header: 'Contact number',
      accessor: 'contactPersonPhone',
    },
    {
      Header: 'Contact person name',
      accessor: 'contactPerson',
    },
    // {
    //   Header: 'Vehicle plate no',
    //   accessor: 'tenants.email',
    {
      Header: 'Amount Paid',
      accessor: 'estateFee',
    },
    {
      Header: 'Payment date',
      accessor: 'createdAt',
      Cell: ({ value }) => <Text as='div'> {dayjs(value).format('DD-MMM-YYYY')}</Text>,
    },
    {
      Header: 'Action',
      accessor: 'endDate',
      Cell: ({ row }) => (
        <GenericModal
          size='lg'
          buttonProps={{
            variant: 'outline',
            size: 'xs',
            color: 'red.300',
            outline: '1px solid #F09091',
          }}
          btnTitle='View receipt'
        >
          <PaymentReceipt data={row.original} />
        </GenericModal>
      ),
    },
  ];

  return <CustomTable COLUMNS={vehicularPaymentHeader} DATA={vehicularPayments} />;
};
