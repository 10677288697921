import http from '../../../../services/api';
import { AUTH_ROUTES } from '../../../../services/routes.constants';

export const getManagerNotifications = async (userId, setNotification) => {
  try {
    const { data } = await http.get(AUTH_ROUTES.MANGER_GET_NOTIFICATIONS(userId));

    setNotification(data);
  } catch (e) {
    setNotification([]);
  }
};
