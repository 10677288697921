import { Button, CloseButton, Divider, Flex, Grid, GridItem, HStack, Stack, Text } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { useRef } from 'react';
import { FiPrinter } from 'react-icons/fi';
import { useReactToPrint } from 'react-to-print';

const advancedFormat = require('dayjs/plugin/advancedFormat');
dayjs.extend(advancedFormat);

export const PaymentReceipt = ({ data, onClose }) => {
  const componentRef = useRef();

  <style type='text/css' media='print'>
    {'\
  @page { size: landscape; }\
'}
  </style>;
  const Top = '10px';
  const Right = 0;
  const Bottom = 0;
  const Left = '20px';

  const getPageMargins = () => {
    return `@page { padding: ${Top} ${Right} ${Bottom} ${Left} !important; }`;
  };

  const handlePrint = useReactToPrint({
    documentTitle: `${data?.userId?.tenantName} ${data?.subType}'s reciept`,
    pageStyle: getPageMargins(),
    content: () => componentRef.current,
  });

  return (
    <>
      <style>{getPageMargins()}</style>
      <Stack>
        <HStack justify='space-between'>
          <Text>Contractor/Artisan Payment Details</Text>
          <CloseButton size='sm' onClick={onClose} bg='gray.200' _focus={{ outline: 'none' }} />
        </HStack>
        <Divider />

        <Grid templateColumns='repeat(2, 1fr)' gap='2rem' color='#1A1A1A' ref={componentRef}>
          <GridItem>
            <Text fontSize='12px'>Type of Contractor/Artisan</Text>
            <Text fontSize='1.2rem' fontWeight='700'>
              {data?.subType}
            </Text>
          </GridItem>
          <GridItem>
            <Text fontSize='12px'>Total Number of People</Text>
            <Text fontSize='1.2rem' fontWeight='700'>
              {data?.numberOfGuests}
            </Text>
          </GridItem>
          <GridItem>
            <Text fontSize='12px'>Tenant phone Number</Text>
            <Text fontSize='1.2rem' fontWeight='700'>
              {data?.userId?.tenantPhone}
            </Text>
          </GridItem>
          <GridItem>
            <Text fontSize='12px'>Tenant Name</Text>
            <Text fontSize='1.2rem' fontWeight='700'>
              {data?.userId?.tenantName}
            </Text>
          </GridItem>
          <GridItem>
            <Text fontSize='12px'>Payment Date</Text>
            <Text fontSize='1.2rem' fontWeight='700'>
              {dayjs(data?.createdAt).format('Do MMM YYYY hh:mma')}
            </Text>
          </GridItem>
          <GridItem>
            <Text fontSize='12px'>Amount paid</Text>
            <Text fontSize='1.2rem' fontWeight='700'>
              {data?.estateFee} NGN
            </Text>
          </GridItem>
          <GridItem>
            <Text fontSize='12px'>Name of Contractor</Text>
            <Text fontSize='1.2rem' fontWeight='700'>
              {data?.contactPerson}
            </Text>
          </GridItem>
          <GridItem>
            <Text fontSize='12px'> Contractor phone</Text>
            <Text fontSize='1.2rem' fontWeight='700'>
              {data?.contactPersonPhone}
            </Text>
          </GridItem>
        </Grid>
        <Flex justify='center' pt='10'>
          <Button w='200px' color={'#FFF'} leftIcon={<FiPrinter />} bg='#614285' onClick={handlePrint}>
            Print
          </Button>
        </Flex>
      </Stack>
    </>
  );
};
