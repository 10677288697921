import http from '../../../../services/api';
import { AUTH_ROUTES } from '../../../../services/routes.constants';
// import { errorNotifier } from "../../../../components/NotificationHandler";

export const getSecurityNotifications = async (setNotification) => {
  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.GET_SECURITY_NOTIFICATIONS);

    setNotification(data.notifications);
  } catch (e) {
    // errorNotifier(e.response?.data?.result?.message);
  }
};
