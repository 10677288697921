import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { FiPrinter } from 'react-icons/fi';
import { nanoid } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

export const GuestListForEvent = ({ openInfo, closeInfo, eventInfo }) => {
  const componentRef = useRef();
  <style type='text/css' media='print'>
    {'\
@page { size: landscape; }\
'}
  </style>;

  const handlePrint = useReactToPrint({
    documentTitle: 'Event reciept',
    // pageStyle: getPageMargins(),
    content: () => componentRef.current,
  });
  return (
    <Modal isOpen={openInfo} onClose={closeInfo} isCentered motionPreset='slideInBottom' size='xl'>
      <ModalOverlay />
      <ModalContent borderRadius='3px' py='5'>
        <ModalHeader pt='0' fontSize='1.2rem'>
          <HStack justify='space-between'>
            <Text fontSize='1.2rem'> Event Information</Text>
            <Button leftIcon={<FiPrinter />} size='xs' color='white' bg='#614285' _focus={{ outline: 'none' }} onClick={handlePrint}>
              Print
            </Button>
          </HStack>
        </ModalHeader>
        <ModalBody bg='gray.50' p='5' pb='4' ref={componentRef}>
          <Stack gap='2rem' fontSize='0.8rem' color='gray.700' fontWeight='600'>
            <Flex justify='space-between' gap='5rem'>
              <Box w='200px'>
                <Text color='gray.500'>Event Title</Text>
                <Text>{eventInfo?.subType}</Text>
              </Box>
              <Box>
                <Text>Event Dates</Text>
                <Flex gap='0.8rem' wrap='wrap'>
                  {eventInfo?.dates?.map((date) => (
                    <Text key={nanoid()} bg='gray.200' px='5px' py='0.5' fontWeight='600' fontSize='12px'>
                      {dayjs(date).format('DD-MMM-YYYY')}
                    </Text>
                  ))}
                </Flex>
              </Box>
            </Flex>
            <Box>
              <Text color='gray.500'>Host Name</Text>
              <Text>{eventInfo?.tenantName}</Text>
            </Box>
            <TableContainer maxH={'200px'}>
              <Table maxH='100px' overflow='auto' size='sm' variant='simple'>
                <Thead>
                  <Tr>
                    <Th>Guest name</Th>
                    <Th>Phone number</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {eventInfo?.guestList?.length ? (
                    eventInfo?.guestList?.map((guest) => (
                      <Tr fontSize='12px'>
                        <Td>{guest?.name}</Td>
                        <Td>{guest?.phone}</Td>
                      </Tr>
                    ))
                  ) : (
                    <Heading>No Guest has been invited yet</Heading>
                  )}
                </Tbody>
              </Table>
            </TableContainer>
          </Stack>
        </ModalBody>

        <ModalFooter>
          <Button size='sm' variant='outline' color='red.400' onClick={closeInfo}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
