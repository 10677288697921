import http, { AUTH_ROUTES } from '../../../../services/api';
import { DATA_ROWS } from '../../../../app/constants';
import { errorNotifier, successNotifier } from '../../../../components/NotificationHandler';

export const getAnnouncement = async (setLoading, userId, setAnnoucement, skip = 0, limit = DATA_ROWS.LIMIT) => {
  setLoading(true);
  const page = skip * limit;

  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.MANAGER_GET_ANNOUNCEMENT(userId, page, limit));

    setAnnoucement(data.announcements);
  } catch (err) {
    setLoading(false);
  } finally {
    setLoading(false);
  }
};

export const editAnnouncement = async (setLoading, payload) => {
  setLoading(true);

  try {
    const { data } = await http.put(AUTH_ROUTES.EDIT_ANNOUNCEMENT(payload.userId, payload.announcementId), payload);
    successNotifier(data.result.message);
  } catch (err) {
    errorNotifier(err.response.data.message);
  } finally {
    setLoading(false);
  }
};

export const deleteAnnouncement = async (userId, announcementId) => {
  try {
    const { data } = await http.delete(AUTH_ROUTES.EDIT_ANNOUNCEMENT(userId, announcementId));
    successNotifier(data.result.message);
  } catch (err) {
    errorNotifier(err.response.data.message);
  }
};

export const createAnnouncement = async (payload, setLoading, onClose) => {
  setLoading(true);
  try {
    const {
      data: { result },
    } = await http.post(AUTH_ROUTES.CREATE_ANNOUNCEMENT, payload);
    successNotifier(result.message);
    onClose();
  } catch (error) {
    errorNotifier(error.response.data.result.message);
  } finally {
    setLoading(false);
  }
};
