import { Box, useMediaQuery } from '@chakra-ui/react';
import { Route, Switch, BrowserRouter as Router, Redirect } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Navigation } from '../components/Nav/Navigation';
import { SideBar } from '../components/Layout/SideBar';
import GuestList from '../pages/Manager-Portal/GuestList';
import Emergency from '../pages/Manager-Portal/Emergency';
import Dashboard from '../pages/Manager-Portal/Dashboard';
import Notification from '../pages/Manager-Portal/Notification';
import { PROTECTED_PATHS } from './constants';
import Street from '../pages/Manager-Portal/Street';
import EstateDue from '../pages/Manager-Portal/EstateDue';
import House from '../pages/Manager-Portal/House';
import Resident from '../pages/Manager-Portal/Resident';
import NewsEvents from '../pages/Manager-Portal/news events';
import EventRequest from '../pages/Manager-Portal/event request';
import ManagerCheckout from '../pages/Manager-Portal/GuestCheckOut';
import { ManagerCheckin } from '../pages/Manager-Portal/GuestCheckIn';
import { PayoutHistory } from '../pages/Manager-Portal/EstateDue/components/PayoutHistory';
import Polls from '../pages/Manager-Portal/polls';

const {
  MANAGER_DASHBOARD,
  MANAGER_EMERGENCY,
  MANAGER_GUEST_CHECK_IN,
  MANAGER_GUEST_CHECK_OUT,
  MANAGER_GUEST_LIST,
  MANAGER_NOTIFICATION,
  MANAGER_STREET,
  MANAGER_ESTATE_DUE,
  MANAGER_HOUSE,
  MANAGER_RESIDENT,
  MANAGER_NEWS_EVENTS,
  MANGER_EVENT_REQUESTS,
  MANAGER_PAYOUT_HISTORY,
  MANAGER_POLLS,
} = PROTECTED_PATHS;

export function AuthenticatedManagerApp({ userType }) {
  const [toggleSide, setToggleSide] = useState(false);
  const [showSidBar, setShowSideBar] = useState(true);
  const [isMobileScreen] = useMediaQuery('(max-width: 600px)');

  useEffect(() => {
    if (isMobileScreen) {
      setToggleSide((initial) => !initial);
    }
  }, [isMobileScreen]);

  const handleToggle = () => {
    setToggleSide((initial) => !initial);
  };

  return (
    <Box className='App' display='flex'>
      <Box className={`app-container`}>
        <Router>
          <Box className='app-sidebar' display={`${toggleSide ? 'none' : 'block'}`}>
            {showSidBar ? <SideBar type='manager' toggle={handleToggle} /> : ''}
          </Box>
          <Box className='app-right' width='100%'>
            <Navigation toggle={handleToggle} sideBarActive={toggleSide} showSidBar={showSidBar} type='manager' userType={userType} />

            <Box width='100%' maxWidth='100%' mt='30px' padding={['5px', '10px', '20px']}>
              <Switch>
                <Route path={MANAGER_ESTATE_DUE} exact component={EstateDue} />

                <Route path={MANAGER_DASHBOARD} exact component={Dashboard} />

                <Route path={MANAGER_GUEST_LIST} exact component={GuestList} />

                <Route path={MANAGER_EMERGENCY} exact component={Emergency} />

                <Route path={MANAGER_RESIDENT} exact component={Resident} />

                <Route path={MANAGER_NOTIFICATION} exact component={Notification} />

                <Route path={MANAGER_STREET} exact component={Street} />
                <Route path={MANAGER_HOUSE} exact component={House} />
                <Route path={MANAGER_NEWS_EVENTS} exact component={NewsEvents} />

                <Route path={MANGER_EVENT_REQUESTS} exact component={EventRequest} />
                <Route path={MANAGER_GUEST_CHECK_IN} exact>
                  <ManagerCheckin setShowSideBar={() => setShowSideBar(false)} />
                </Route>
                <Route path={MANAGER_GUEST_CHECK_OUT} exact>
                  <ManagerCheckout setShowSideBar={() => setShowSideBar(false)} />
                </Route>

                <Route path={MANAGER_PAYOUT_HISTORY} exact component={PayoutHistory} />
                <Route path={MANAGER_POLLS} exact component={Polls} />

                <Redirect from='/*' to={MANAGER_DASHBOARD} />
              </Switch>
            </Box>
          </Box>
        </Router>
      </Box>
    </Box>
  );
}
