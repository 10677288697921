import { Text } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { getBadgeColor } from './GetBadgeColor';

export const guestListTableColumn = [
  {
    Header: 'S/N',
    accessor: 'id',
    Cell: ({ row }) => Number(row.id) + 1,
  },
  {
    Header: 'Guest Name',
    accessor: 'name',
  },
  {
    Header: 'Resident Name',
    accessor: 'tenantName',
  },
  {
    Header: 'Address',
    accessor: 'streets.streetName',
  },
  {
    Header: 'Allow Company',
    accessor: 'allowCompany',
    Cell: ({ value }) => <Text>{value ? 'Yes' : 'No'}</Text>,
  },
  {
    Header: 'Checkin',
    accessor: 'updatedAt',
    Cell: ({ value }) => dayjs(value).format('DD-MMMM-YYYY'),
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: ({ value }) => (
      <Text
        border={`1px solid ${getBadgeColor(value)}`}
        borderRadius='25px'
        textAlign='center'
        fontSize='.8em'
        width='65px'
        px='3px'
        color={getBadgeColor(value.toString())}
      >
        {value}
      </Text>
    ),
  },
];
