import { Button, HStack, Stack, TableContainer, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CustomDrawer } from '../../../components/CustomDrawer';
import { CustomTable } from '../../../components/customized table/CustomTable';
import FullPageLoader from '../../../components/full-page-loader';
import { CreatePoll } from './components/CreatePoll';
import { PollResult } from './components/PollResult';
import { getAllPolls } from './service';

import dayjs from 'dayjs';
const relativeTime = require('dayjs/plugin/relativeTime');
dayjs.extend(relativeTime);

const Polls = () => {
  const userId = useSelector((state) => state.auth.user);
  const [allPolls, setAllPolls] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState({});

  useEffect(() => {
    getAllPolls(userId, setLoading, setAllPolls);
  }, [userId, refresh]);

  const pollsTableHead = [
    {
      Header: 'S/N',
      accessor: 'id',
      Cell: ({ row }) => Number(row.id) + 1,
    },
    {
      Header: 'Poll Title',
      accessor: 'data.title',
    },
    {
      Header: ' Start Date',
      accessor: 'data.startDate',
      Cell: ({ value }) => <Text>{dayjs(value).format('DD-MMM-YYYY HH:mm')}</Text>,
    },
    {
      Header: ' End Date',
      accessor: 'data.endDate',
      Cell: ({ value }) => <Text>{dayjs(value).format('DD-MMM-YYYY HH:mm')}</Text>,
    },
    {
      Header: 'Poll Duration',
      accessor: '',
      Cell: ({ row }) => {
        const startDate = dayjs(row.original.data.startDate);
        const endDate = dayjs(row.original.data.endDate);
        const dateDifference = endDate.diff(startDate);
        return dateDifference ? (
          <Text fontWeight='600' textAlign='center' bg='teal.100' w='80px' borderRadius='5px'>
            {dayjs(endDate).from(startDate, true)}
          </Text>
        ) : (
          <Text textAlign='center' bg='rgba(240, 189, 189, 0.5)' borderRadius='5px' w='80px'>
            Poll Closed
          </Text>
        );
      },
    },
    {
      Header: 'Poll Ends ',
      accessor: 'close',
      Cell: ({ row }) => {
        const today = dayjs(new Date());

        // const startDate = dayjs(row.original.data.startDate);
        const endDate = dayjs(row.original.data.endDate);
        const dateDifference = endDate.diff(today);
        // const startEndDateDiff = endDate.diff(startDate);

        return dateDifference > 0 ? (
          <Text fontWeight='600' textAlign='center' bg='red.100' w='80px' borderRadius='5px'>
            {dayjs(today).to(endDate)}
          </Text>
        ) : (
          <Text textAlign='center' bg='rgba(240, 189, 189, 0.5)' borderRadius='5px' w='80px'>
            Poll Closed
          </Text>
        );
      },
    },

    {
      Header: 'Status',
      accessor: 'data.resultVisibility',
      Cell: ({ value }) => <Text fontWeight={600}>{value ? 'Published' : 'Not Published'}</Text>,
    },

    {
      Header: '',
      accessor: 'k',
      Cell: ({ row }) => (
        <CustomDrawer size='md' btnProps={{ as: Button, size: 'xs' }} btnTitle='result'>
          <PollResult result={row.original} setRefresh={() => setRefresh({})} />
        </CustomDrawer>
      ),
    },
  ];

  return loading ? (
    <FullPageLoader />
  ) : (
    <Stack p='10'>
      <HStack pb='5' justify='space-between' borderBottom='2px solid #ddd'>
        <Text fontWeight='700' fontSize='1.4rem'>
          Polls
        </Text>
        <CustomDrawer size='md' btnTitle='Create Poll' btnProps={{ bg: '#614285', fontSize: '13px', as: Button, color: 'white' }}>
          <CreatePoll setRefresh={() => setRefresh({})} />
        </CustomDrawer>
      </HStack>
      <TableContainer>
        <CustomTable COLUMNS={pollsTableHead} DATA={allPolls} />
      </TableContainer>
    </Stack>
  );
};

export default Polls;
